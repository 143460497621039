import React, { FC } from "react";
import { IconProps } from "../iconProps";

const SoundOff: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="soundoff"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      {/* Generated by IcoMoon.io // Material Icons v3 */}
      <path
        fill="currentColor"
        d="M24 7.969v8.438l-4.219-4.219zM8.531 6l33.469 33.469-2.531 2.531-4.125-4.125q-3.094 2.625-7.313 3.656v-4.125q2.344-0.656 4.5-2.344l-8.531-8.531v13.5l-10.031-10.031h-7.969v-12h9.469l-9.469-9.469zM37.969 24q0-4.781-2.766-8.438t-7.172-4.969v-4.125q6.094 1.313 10.031 6.234t3.938 11.297q0 4.406-2.063 8.344l-3-3.094q1.031-2.531 1.031-5.25zM33 24q0 0.844-0.094 1.219l-4.875-4.875v-4.406q2.063 1.031 3.516 3.375t1.453 4.688z"
      />
    </svg>
  );
};

export default SoundOff;

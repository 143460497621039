import { Properties } from "csstype";
import React, { FC, ReactNode } from "react";
import { mergeStyles } from "../../livelyStyles";

export interface SelectProps {
  className?: string;
  children?: ReactNode;
  classes?: Properties;
}

const SelectNonNative: FC<SelectProps> = ({ className, classes, children }) => {
  const mergedClasses = mergeStyles({ source: classes, target: {} }, { stylesNamespace: "select" });
  return <div className={`${mergedClasses.range} ${className}`}>{children}</div>;
};

export default SelectNonNative;

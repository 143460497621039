import { extractAggregates, Json, LoggerCore } from "@livelyvideo/log-client";
import { makeObservable, observable } from "mobx";
import type { ManifestFormats } from "../../../api/manifest";
import type { PlayerAPI, PlayerEvents } from "../../../api/player";
import {
  BitrateSwitchingEvents,
  BitrateSwitchingFeature,
  Quality,
  SourceScoreLevel,
  TranscodeScoreLevel,
} from "../../../api/player/features/bitrate-switching";
import type { ConsumerEvents, ConsumerFeature } from "../../../api/player/features/consumer";
import { Feature as PlayerFeature, Features as PlayerFeatures } from "../../../api/player/features/feature";
import { VideoElement } from "../../../api/typings/video-element";
import { dumpVideoElement } from "../../utils/debug/play-logs";
import ProxyEventEmitter from "../../utils/events/proxy-event-emitter";

export interface ProxyPlayerEvents extends PlayerEvents, ConsumerEvents, BitrateSwitchingEvents {
  /**
   * @description Is emitted when the currentPlayer is set.
   * @example player.on("currentPlayer", (val) => { // handle current player})
   */
  currentPlayer: PlayerAPI | null;
}

export abstract class ProxyPlayer<Events = unknown>
  extends ProxyEventEmitter<ProxyPlayerEvents & Events>
  implements PlayerAPI, ConsumerFeature, BitrateSwitchingFeature
{
  proxyHostElement: VideoElement | null = null;

  currentPlayer: PlayerAPI | null = null;

  poster: string | null = null;

  driverFailover = true;

  get localAudioMuted(): boolean {
    return this.currentPlayer?.localAudioMuted ?? false;
  }

  set localAudioMuted(val: boolean) {
    if (this.currentPlayer != null) {
      this.currentPlayer.localAudioMuted = val;
    }
  }

  get localVideoPaused(): boolean {
    return this.currentPlayer?.localVideoPaused ?? false;
  }

  set localVideoPaused(val: boolean) {
    if (this.currentPlayer != null) {
      this.currentPlayer.localVideoPaused = val;
    }
  }

  get localAudioVolume(): number {
    return this.currentPlayer?.localAudioVolume ?? 1;
  }

  set localAudioVolume(val: number) {
    if (val > 1 || val < 0) {
      console.warn(
        `Volume level not set. ${val} is not a valid volume value for an HTMLMediaElement. Volume levels must be between 0-1.`,
      );
      return;
    }
    if (this.currentPlayer != null) {
      this.currentPlayer.localAudioVolume = val;
    }
  }

  get forcedMute(): boolean {
    if (this.currentPlayer?.isImplements(PlayerFeature.MUTED_AUTOPLAY)) {
      return this.currentPlayer?.forcedMute ?? false;
    }
    return false;
  }

  set forcedMute(val: boolean) {
    if (this.currentPlayer?.isImplements(PlayerFeature.MUTED_AUTOPLAY)) {
      this.currentPlayer.forcedMute = val;
    }
  }

  get autoPlay(): boolean {
    return this.currentPlayer?.autoPlay != null ? this.currentPlayer.autoPlay : true;
  }

  set autoPlay(val: boolean) {
    if (this.currentPlayer != null) {
      this.currentPlayer.autoPlay = val;
    }
  }

  constructor() {
    super();

    makeObservable(this, {
      proxyHostElement: observable.ref,
      currentPlayer: observable.ref,
      poster: observable,
    });

    // this block should come first
    // because the following auto-runners may triggers some of these events
    this.autorun(() => {
      const { currentPlayer } = this;
      if (currentPlayer != null) {
        currentPlayer.poster = this.poster;

        this.proxy(
          currentPlayer,
          "localAudioMuted",
          "localAudioVolume",
          "hostElementAttached",
          "error",
          "progress",
          "timeupdate",
          "restartDriver",
          "webrtcStats",
          "videoFirstPlay",
          "driverFailover",
          "error",
          "driver",
          "consumerAudioEnabled",
          "consumerVideoEnabled",
        );
      }

      if (currentPlayer?.isImplements(PlayerFeature.MUTED_AUTOPLAY)) {
        this.proxy(currentPlayer, "forcedMute");
      }

      if (currentPlayer?.isImplements(PlayerFeature.BITRATE_SWITCHING)) {
        this.proxy(currentPlayer, "activeLayer", "layers", "currentQuality", "availableQualities");
      }
    });

    this.autorun(() => {
      const { proxyHostElement, currentPlayer, poster } = this;
      if (proxyHostElement != null && currentPlayer != null) {
        currentPlayer.attachTo(proxyHostElement);
      }

      if (currentPlayer != null) {
        currentPlayer.poster = poster;
      }
    });

    this.addInnerDisposer(() => {
      this.currentPlayer?.dispose("proxy player disposing");
    });
  }

  get availableQualities(): Quality[] {
    return this.currentPlayer?.isImplements(PlayerFeature.BITRATE_SWITCHING)
      ? this.currentPlayer.availableQualities
      : [];
  }

  get currentQuality(): Quality | null {
    return this.currentPlayer?.isImplements(PlayerFeature.BITRATE_SWITCHING) ? this.currentPlayer.currentQuality : null;
  }

  setPreferredLevel(level: TranscodeScoreLevel | SourceScoreLevel): void {
    if (this.currentPlayer?.isImplements(PlayerFeature.BITRATE_SWITCHING)) {
      this.currentPlayer.setPreferredLevel(level);
    }
  }

  get consumerAudioEnabled(): boolean {
    return this.currentPlayer?.isImplements(PlayerFeature.CONSUMER) ? this.currentPlayer.consumerAudioEnabled : false;
  }

  get consumerVideoEnabled(): boolean {
    return this.currentPlayer?.isImplements(PlayerFeature.CONSUMER) ? this.currentPlayer.consumerVideoEnabled : false;
  }

  get streamName(): string {
    return this.currentPlayer?.isImplements(PlayerFeature.CONSUMER) ? this.currentPlayer.streamName : "";
  }

  get attached(): boolean {
    return this.currentPlayer?.attached ?? false;
  }

  get format(): keyof ManifestFormats | null {
    return this.currentPlayer?.format ?? null;
  }

  get logger(): LoggerCore | undefined {
    return this.currentPlayer?.logger;
  }

  attachTo(el: VideoElement): void {
    this.proxyHostElement = el;
  }

  isImplements<K extends keyof PlayerFeatures, T extends PlayerFeatures[K]>(feature: K): this is this & T {
    return this.currentPlayer?.isImplements(feature) ?? true;
  }

  async isSupported(): Promise<boolean> {
    return this.currentPlayer?.isSupported() ?? false;
  }

  toJSON(): Json {
    return {
      currentPlayer: this.currentPlayer,
      proxyHostElement: dumpVideoElement(this.proxyHostElement as VideoElement),
      poster: this.poster,
      driverFailover: this.driverFailover,

      aggregates: {
        ...extractAggregates(this.currentPlayer),
        isProxy: true,
      },
    };
  }
}

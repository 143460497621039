import type { Json } from "@livelyvideo/log-client";
import { extractAggregates } from "@livelyvideo/log-node";
import { makeObservable, observable, runInAction } from "mobx";
import type { PeerAPI, Serializable } from "../api";
import { SourceProvider } from "../api";
import { device, Feature } from "../api/adapter";
import type { MediaStream, MediaStreamTrack } from "../api/adapter/features/media-stream";
import { BitrateLayer, Quality } from "../api/player/features/bitrate-switching";
import { LayerNotFoundError, NotSupportedError } from "./errors";
import { fetchManifestQualities } from "./player/helper";
import type { ConsumerLayer, ConsumerParameters } from "./pvc/call/common";
import { VcContext } from "./utils/context/vc-context";
import ProxyEventEmitter from "./utils/events/proxy-event-emitter";

export type MediasoupSourceOptions = {
  replaceTracks: boolean;
};

const defaultMediasoupSourceOptions: MediasoupSourceOptions = {
  replaceTracks: true,
};

interface MediasoupSourceEvents {
  // MobX Events
  currentQuality: Quality | null;
  availableQualities: Quality[];

  audioMuted: boolean;
  videoPaused: boolean;
  activeLayer: ConsumerLayer | null;
  error: Error;
  source: MediaStream;
  disposed: void;
}

export class MediasoupSource
  extends ProxyEventEmitter<MediasoupSourceEvents>
  implements SourceProvider<MediaStream>, Serializable
{
  static readonly displayName = "MediasoupSource";

  private readonly options: MediasoupSourceOptions;

  readonly peer: PeerAPI;

  private readonly stream: MediaStream;

  private readonly consumers: Record<string, ConsumerParameters> = {};

  readonly streamName: string;

  public currentQuality: Quality | null = null;

  public availableQualities: Quality[] = [];

  private readonly ctx: VcContext;

  constructor(ctx: VcContext, peer: PeerAPI, streamName: string, options = defaultMediasoupSourceOptions) {
    super();

    this.ctx = ctx;

    makeObservable(this, {
      currentQuality: observable.ref,
      availableQualities: observable.shallow,
    });

    if (!device.isImplements(Feature.MEDIA_STREAM)) {
      this.throwError(new NotSupportedError("MediaStream not supported", { critical: true }));
    }

    this.options = options;
    this.peer = peer;
    this.streamName = streamName;
    this.stream = new device.MediaStream();

    ctx.logger.attachObject(this);
    ctx.logger.trace("constructor()");
  }

  get source(): MediaStream {
    return this.stream;
  }

  get consumerIds(): string[] {
    return Object.keys(this.consumers);
  }

  addConsumer(consumer: ConsumerParameters, emitSource = true): void {
    if (!consumer.dontAdd) {
      if (consumer.track.kind === "video" && this.stream.getVideoTracks().length > 0) {
        this.stream.getVideoTracks().forEach((t) => this.stream.removeTrack(t));
      }

      if (consumer.track.kind === "audio" && this.stream.getAudioTracks().length > 0) {
        this.stream.getAudioTracks().forEach((t) => this.stream.removeTrack(t));
      }

      if (consumer.track.kind === "video") {
        const qualities = fetchManifestQualities(consumer.layers, null);
        runInAction(() => {
          this.availableQualities = qualities;
          const activeLayer = consumer.activeLayer;

          // if not null then we have to update current quality
          if (activeLayer != null) {
            const qty = this.availableQualities.find((q) => q.layer.id === activeLayer.id);
            if (qty != null) {
              this.currentQuality = qty;
            } else {
              this.ctx.logger.warn("Consumer refers to a layer which doesn't exist", {
                layers: consumer.layers,
                activeLayer: consumer.activeLayer,
              });
            }
          }
        });
      }

      this.stream.addTrack(consumer.track);
    }

    this.consumers[consumer.id] = consumer;
    if (emitSource && !consumer.dontAdd) {
      this.emit("source", this.stream);
    }
  }

  removeConsumer(consumerId: string, emitSource = true): void {
    const consumer = this.consumers[consumerId];
    if (consumer != null) {
      this.stream.removeTrack(consumer.track);
      delete this.consumers[consumerId];
      if (this.stream.getTracks().length > 0) {
        if (emitSource) {
          this.emit("source", this.stream);
        }
      }
    }
  }

  swapConsumers(add: ConsumerParameters[], remove: string[]): void {
    remove.forEach((consumerId) => {
      if (this.consumers[consumerId] != null) {
        this.stream.removeTrack(this.consumers[consumerId].track);
      } else {
        this.ctx.logger.warn("SwapConsumer method failed to remove track consumer does not exist", {
          consumerId,
        });
      }
    });
    add.forEach((track) => {
      if (this.consumers[track.id] != null) {
        this.stream.addTrack(this.consumers[track.id].track);
      } else {
        this.ctx.logger.warn("SwapConsumer method failed to add track consumer does not exist", {
          consumerId: track.id,
        });
      }
    });

    this.emit("source", this.stream);
  }

  pauseConsumer(consumerId: string): void {
    const consumer = this.consumers[consumerId];
    if (consumer == null) {
      return;
    }

    consumer.paused = true;
    if (this.stream.getTracks().some((t) => t.id === consumer.track.id)) {
      if (consumer.kind === "video") {
        this.emit("videoPaused", consumer.paused);
      } else {
        this.emit("audioMuted", consumer.paused);
      }
    }
  }

  resumeConsumer(consumerId: string): void {
    const consumer = this.consumers[consumerId];
    if (consumer == null) {
      return;
    }
    consumer.paused = false;
    if (this.stream.getTracks().some((t) => t.id === consumer.track.id)) {
      if (consumer.kind === "video") {
        this.emit("videoPaused", consumer.paused);
      } else {
        this.emit("audioMuted", consumer.paused);
      }
    }
  }

  consumerLayersChanged(layer: ConsumerLayer): void {
    const qty = this.availableQualities.find((q) => q.layer.id === layer.id);
    if (qty != null) {
      this.currentQuality = qty;
    }
  }

  consumerSourcesChanged(layers: BitrateLayer[]): void {
    const qualities = fetchManifestQualities(layers, null);
    this.availableQualities = qualities;
  }

  setLayer(id: string | number): void {
    const qty = this.availableQualities.find((q) => q.layer.id === id);
    if (qty?.layer.appData?.consumerId == null) {
      this.emitError(new LayerNotFoundError("Layer not found", { layerId: id }));
      return;
    }

    this.peer.setLayer(qty.layer);
  }

  getTracks(): MediaStreamTrack[] {
    return this.stream.getTracks();
  }

  get hasAudio(): boolean {
    return Object.values(this.consumers).some((c) => c.kind === "audio");
  }

  get hasVideo(): boolean {
    return Object.values(this.consumers).some((c) => c.kind === "video");
  }

  get audioMuted(): boolean {
    return Object.values(this.consumers).filter((c) => c.kind === "audio" && c.paused).length > 0;
  }

  get videoPaused(): boolean {
    return Object.values(this.consumers).filter((c) => c.kind === "video" && c.paused).length > 0;
  }

  toJSON(): Json {
    return {
      hasAudio: this.hasAudio,
      hasVideo: this.hasVideo,
      audioMuted: this.audioMuted,
      videoPaused: this.videoPaused,
      options: this.options,

      aggregates: {
        ...extractAggregates(this.peer, "support"),
        support: this.ctx.support.hash,
        streamName: this.streamName,
      },
    };
  }
}

import type { IObservableArray } from "mobx";
import type Mpegts from "mpegts.js";
import type { ManifestFormats, ManifestJson, PlayerEvents } from "../../api";
import { SourceProvider } from "../../api";
import { device, Feature } from "../../api/adapter";
import {
  BitrateLayer,
  BitrateSwitchingFeature,
  Quality,
  TranscodeScoreLevel,
} from "../../api/player/features/bitrate-switching";
import { Feature as PlayerFeature } from "../../api/player/features/feature";
import { DriverNotSupportedError, PlayingIssueError } from "../errors";
import { VcContext } from "../utils/context/vc-context";
import { CorePlayer } from "./core";
import { fetchManifestQualities } from "./helper";

export interface FlvHttpPlayerEvents extends PlayerEvents {
  activeLayer: BitrateLayer | null;
  layers: BitrateLayer[];
  availableQualities: Quality[];
  currentQuality: Quality;
}

export type FlvHttpPlayerOptions = {
  autoPlay?: boolean;
  muted?: boolean;
};

export class FlvHttpPlayer
  extends CorePlayer<FlvHttpPlayerOptions, ManifestJson, FlvHttpPlayerEvents>
  implements BitrateSwitchingFeature
{
  static readonly displayName = "FlvHttpPlayer";

  private player: Mpegts.Player | null = null;

  static async isSupported(): Promise<boolean> {
    if (device.isImplements(Feature.MPEGTS)) {
      await device.loadMpegtsScript();
      return device.mpegts?.isSupported() ?? false;
    }
    return false;
  }

  async isSupported(): Promise<boolean> {
    return FlvHttpPlayer.isSupported();
  }

  static get format(): keyof ManifestFormats {
    return "flv-http";
  }

  get format(): keyof ManifestFormats {
    return FlvHttpPlayer.format;
  }

  constructor(ctx: VcContext, provider: SourceProvider<ManifestJson>, options: FlvHttpPlayerOptions) {
    super(ctx, provider, options);

    this.autorun(() => {
      const layer = this.currentQuality?.layer;
      if (layer != null && typeof layer.id === "string" && layer.id !== "") {
        this.requestUrl(layer.id);
      }
    });

    this.on("hostElementAttached", this.handleElAttached);

    this.addInnerDisposer(() => {
      this.player?.destroy();
    });
  }

  async ready(): Promise<void> {
    if (device.isImplements(Feature.MPEGTS)) {
      await device.loadMpegtsScript();
    }
    await super.ready();
  }

  protected handleSource(manifest: ManifestJson | null): void {
    const format = manifest?.formats["flv-http"];

    if (format == null) {
      this.emitError(new DriverNotSupportedError("manifest doesn't contains 'flv-http' format", { manifest }));
      return;
    }

    const qualities = this.availableQualities as IObservableArray;
    qualities.replace(fetchManifestQualities(format.encodings, format.origin?.location ?? null));
    if (this.currentQuality?.layer == null) {
      this.setPreferredLevel(TranscodeScoreLevel.Highest);
    }
  }

  private requestUrl(url: string): void {
    if (!device.isImplements(Feature.MPEGTS)) {
      this.throwError(new PlayingIssueError("mpegts library is not loaded", { player: this }));
    }

    if (this.player != null) {
      this.player.destroy();
    }

    this.player = device.mpegts.createPlayer({
      type: "flv",
      isLive: true,
      url,
    });

    if (this.hostEl != null) {
      this.handleElAttached({ el: this.hostEl });
    }
  }

  private handleElAttached(ev: PlayerEvents["hostElementAttached"]): void {
    if (this.player != null) {
      this.player.attachMediaElement(ev.el);
      this.player.load();
      this.ctx.logger.debug("host element is attached and loaded");

      if (this.options.autoPlay) {
        this.ctx.logger.debug("auto play");
        try {
          this.player.play();
        } catch (err) {
          const inner = err instanceof Error ? err : null;
          this.emitError(
            new PlayingIssueError("autoplay call failed after handleElAttached()", {
              inner,
              player: this,
            }),
          );
        }
      }
    }
  }

  protected get implementedFeatures(): PlayerFeature[] {
    return [PlayerFeature.BITRATE_SWITCHING, PlayerFeature.MUTED_AUTOPLAY];
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-types */

/**
 * Returns object from getter on first access to any prop
 */
export function lazy<T extends object>(getter: () => T): T {
  class LazyHandler implements ProxyHandler<T> {
    get(target: T, p: string | symbol): any {
      Object.assign(target, getter());
      delete this.constructor.prototype.get;
      return (target as any)[p];
    }
  }

  return new Proxy({} as T, new LazyHandler());
}

// it's safe to use any there because it won't leave this function
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as stacktraceJs from "stacktrace-js";
import { Disposable } from "../../api/common";
import { DisposedObjectError } from "../errors";
import { formatStacktrace } from "./debug";

function getAllDescriptors<T extends object>(obj: T): Record<string, TypedPropertyDescriptor<any>> {
  let descriptors: Record<string, TypedPropertyDescriptor<any>> = {};
  let curr = obj;
  while (curr != null && curr !== Object.prototype) {
    descriptors = { ...Object.getOwnPropertyDescriptors(curr), ...descriptors };
    curr = Object.getPrototypeOf(curr);
  }
  return descriptors;
}

const lastDisposeCall = new WeakMap<Disposable, StackTrace.StackFrame[]>();

function wrapDisposeCheck(target: any, method: any): any {
  return function disposeCheck(...args: unknown[]): unknown {
    if (typeof target === "object" && target.isDisposed) {
      const stFrames = lastDisposeCall.get(target);
      const stacktrace = stFrames == null ? "" : formatStacktrace(stFrames);

      const err = new DisposedObjectError("call on the disposed object", {
        className: target.constructor.displayName ?? target.constructor.name,
        method: method.name,
      });

      if (typeof target.ctx === "object" && target.ctx.logger === "object" && target.ctx.logger.error === "function") {
        target.ctx.logger.error(err.message, { err, target: target.name, method: method.name, stacktrace });
      } else {
        throw err;
      }
    }
    return method.apply(target, args);
  };
}

function wrapTraceCollect(target: any, method: any) {
  return function traceCollect(...args: unknown[]): unknown {
    stacktraceJs.get().then((st) => {
      lastDisposeCall.set(target, st);
    });
    return method.apply(target, args);
  };
}

export function makeBounded<T extends object>(target: T, exclude: string[] = [], debug = false): void {
  if (target == null || typeof target !== "object") {
    throw new Error("bindMethods works only on objects");
  }

  const allDescriptors = getAllDescriptors(target);

  const keys = Object.keys(allDescriptors).filter((k) => !exclude.includes(k));
  for (const key of keys) {
    const ownDesc = Object.getOwnPropertyDescriptor(target, key);
    const protoDesc = allDescriptors[key];
    const method = protoDesc.value;
    if (typeof method === "function" && key !== "constructor" && (ownDesc == null || ownDesc.configurable)) {
      let wrapper = method.bind(target);
      if (debug) {
        if (key === "dispose") {
          wrapper = wrapTraceCollect(target, method);
        } else {
          wrapper = wrapDisposeCheck(target, method);
        }
      }

      Object.defineProperty(target, key, {
        value: wrapper,
        configurable: true,
        enumerable: false,
        writable: false,
      });
    }
  }
}

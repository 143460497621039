import React, { FC } from "react";
import { IconProps } from "./iconProps";

const Expand: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="expand"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M30 75.9609V37.4531C30 33.3229 33.3437 30 37.5 30H76.25C78.3125 30 80 31.677 80 33.7266V46.1484C80 48.198 78.3125 49.875 76.25 49.875H50V75.9609C50 78.0105 48.3125 79.6875 46.25 79.6875H33.75C31.6875 79.6875 30 78.0105 30 75.9609ZM120 33.7266V46.1484C120 48.198 121.688 49.875 123.75 49.875H150V75.9609C150 78.0105 151.688 79.6875 153.75 79.6875H166.25C168.312 79.6875 170 78.0105 170 75.9609V37.4531C170 33.3229 166.656 30 162.5 30H123.75C121.688 30 120 31.677 120 33.7266ZM166.25 119.438H153.75C151.688 119.438 150 121.114 150 123.164V149.25H123.75C121.688 149.25 120 150.927 120 152.977V165.398C120 167.448 121.688 169.125 123.75 169.125H162.5C166.656 169.125 170 165.802 170 161.672V123.164C170 121.114 168.312 119.438 166.25 119.438ZM80 165.398V152.977C80 150.927 78.3125 149.25 76.25 149.25H50V123.164C50 121.114 48.3125 119.438 46.25 119.438H33.75C31.6875 119.438 30 121.114 30 123.164V161.672C30 165.802 33.3437 169.125 37.5 169.125H76.25C78.3125 169.125 80 167.448 80 165.398Z"
      />
    </svg>
  );
};

export default Expand;

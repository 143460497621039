import React, { FC } from "react";
import { IconProps } from "./iconProps";

const Play: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="play"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M146.378 90.3108L64.7827 41.5393C58.1531 37.5785 48 41.4221 48 51.2186V148.738C48 157.527 57.4345 162.823 64.7827 158.417L146.378 109.669C153.657 105.334 153.68 94.6465 146.378 90.3108V90.3108Z"
      />
    </svg>
  );
};

export default Play;

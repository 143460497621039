import React, { FC } from "react";
import { IconProps } from "./iconProps";

const Broadcast: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="broadcast"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      fill="none"
      {...rest}
    >
      <path
        d="M159.958 42.1414C159.308 41.4718 158.532 40.9379 157.674 40.5709C156.816 40.2038 155.894 40.0108 154.961 40.0032H154.91C153.044 40.0043 151.255 40.7457 149.936 42.0646L149.228 42.772C147.929 44.0781 147.193 45.8415 147.178 47.684C147.164 49.5265 147.872 51.3012 149.151 52.6274C161.448 65.3316 168.323 82.3194 168.323 100C168.323 117.681 161.448 134.668 149.151 147.373C147.87 148.701 147.161 150.479 147.177 152.324C147.193 154.17 147.933 155.935 149.238 157.241L149.948 157.948C151.266 159.263 153.052 160.001 154.913 160H154.974C155.905 159.991 156.825 159.798 157.681 159.431C158.538 159.064 159.312 158.53 159.961 157.862C174.984 142.348 183.384 121.599 183.384 100.003C183.384 78.4074 174.984 57.6584 159.961 42.1446L159.958 42.1414Z"
        fill="currentColor"
      />
      <path
        d="M32.0611 100C32.0265 82.313 38.9058 65.3128 51.231 52.6274C52.5121 51.2999 53.2211 49.5226 53.2056 47.6778C53.19 45.833 52.4511 44.068 51.1478 42.7623L50.4372 42.0549C49.1182 40.74 47.332 40.0011 45.4694 40H45.4118C44.4798 40.0076 43.5586 40.2005 42.7019 40.5676C41.8452 40.9347 41.0701 41.4686 40.4217 42.1382C25.3994 57.6518 17 78.4002 17 99.9952C17 121.59 25.3994 142.339 40.4217 157.852C41.071 158.521 41.8467 159.055 42.7039 159.422C43.5611 159.789 44.4826 159.982 45.415 159.99H45.4855C46.4089 159.991 47.3234 159.809 48.1764 159.455C49.0294 159.101 49.8042 158.583 50.4564 157.929L51.167 157.218C52.4684 155.913 53.2061 154.149 53.2217 152.306C53.2372 150.463 52.5294 148.687 51.2502 147.36C38.9187 134.682 32.0322 117.686 32.0611 100V100Z"
        fill="currentColor"
      />
      <path
        d="M136.774 69.1214C136.123 68.4506 135.345 67.9161 134.486 67.549C133.626 67.1819 132.702 66.9896 131.768 66.9832H131.723C130.796 66.9842 129.878 67.1681 129.022 67.5246C128.166 67.8811 127.389 68.4031 126.736 69.0605L126.39 69.4062C125.095 70.7132 124.362 72.4753 124.35 74.3155C124.337 76.1556 125.045 77.9276 126.323 79.2521C131.707 84.8169 134.717 92.2568 134.717 100C134.717 107.743 131.707 115.183 126.323 120.748C125.045 122.072 124.336 123.844 124.348 125.684C124.36 127.524 125.092 129.286 126.387 130.594L126.733 130.939C127.386 131.598 128.162 132.12 129.018 132.477C129.874 132.833 130.792 133.017 131.72 133.017H131.764C132.699 133.01 133.623 132.818 134.482 132.451C135.342 132.084 136.12 131.549 136.771 130.879C144.789 122.6 149.273 111.526 149.273 100C149.273 88.4742 144.789 77.4004 136.771 69.1214H136.774Z"
        fill="currentColor"
      />
      <path
        d="M73.9763 69.3934L73.6306 69.0478C72.9781 68.3944 72.2033 67.876 71.3503 67.5223C70.4973 67.1686 69.583 66.9865 68.6597 66.9864H68.5988C67.6663 66.9936 66.7445 67.1863 65.8872 67.5534C65.0299 67.9205 64.2543 68.4546 63.6055 69.1246C55.5841 77.4055 51.0989 88.4823 51.0989 100.011C51.0989 111.54 55.5841 122.617 63.6055 130.898C64.2548 131.567 65.0305 132.101 65.8877 132.468C66.7449 132.835 67.6664 133.028 68.5988 133.036H68.6533C69.5767 133.036 70.4912 132.855 71.3442 132.501C72.1972 132.147 72.972 131.628 73.6242 130.975L73.9699 130.629C75.2723 129.323 76.0106 127.559 76.0261 125.715C76.0417 123.871 75.3333 122.094 74.0531 120.767C68.6682 115.203 65.6576 107.763 65.6576 100.019C65.6576 92.2758 68.6682 84.8357 74.0531 79.2713C75.339 77.944 76.0516 76.1645 76.0372 74.3166C76.0228 72.4687 75.2826 70.7005 73.9763 69.3934Z"
        fill="currentColor"
      />
      <path
        d="M100.191 114.97C108.604 114.97 115.424 108.15 115.424 99.7376C115.424 91.3247 108.604 84.5047 100.191 84.5047C91.7783 84.5047 84.9583 91.3247 84.9583 99.7376C84.9583 108.15 91.7783 114.97 100.191 114.97Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Broadcast;

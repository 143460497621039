import React, { FC } from "react";
import { IconProps } from "./iconProps";

const SoundOff: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="volume-off"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M77.1962 42.2018L49.3934 69.999H17.4999C13.3562 69.999 10 73.3553 10 77.4991V122.499C10 126.64 13.3562 130 17.4999 130H49.3934L77.1962 157.797C81.8931 162.494 89.9992 159.194 89.9992 152.494V47.505C89.9992 40.7987 81.8868 37.5112 77.1962 42.2018ZM154.261 99.9993L168.523 85.7366C170.492 83.7679 170.492 80.5741 168.523 78.6053L161.392 71.474C159.424 69.5052 156.23 69.5052 154.261 71.474L139.999 85.7366L125.736 71.474C123.768 69.5052 120.574 69.5052 118.605 71.474L111.474 78.6053C109.505 80.5741 109.505 83.7679 111.474 85.7366L125.736 99.9993L111.477 114.259C109.508 116.228 109.508 119.421 111.477 121.39L118.608 128.521C120.577 130.49 123.771 130.49 125.739 128.521L139.999 114.262L154.261 128.525C156.23 130.493 159.424 130.493 161.392 128.525L168.523 121.393C170.492 119.424 170.492 116.231 168.523 114.262L154.261 99.9993Z"
      />
    </svg>
  );
};

export default SoundOff;

import type { IEventEmitter } from "@livelyvideo/events-typed";
import { ObservableEventEmitter } from "./event-emitter";

// Use a call to proxy() to have event listener removal handled for you
// i.e. this.proxy(target, "someEvent") should work similarly to this.emit("eventName")
export default class ProxyEventEmitter<M> extends ObservableEventEmitter<M> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachedProxy: WeakMap<IEventEmitter<any>, string[]> = new WeakMap<IEventEmitter<any>, string[]>();

  proxy<K extends keyof M>(target: IEventEmitter<Pick<M, K>>, ...eventNames: K[]): void {
    for (const event of eventNames) {
      this.internalProxy(target, event);
    }
  }

  private internalProxy<K extends keyof M>(target: IEventEmitter<Pick<M, K>>, event: K): void {
    // Arguments not type checked here, any are accepted. Actual events type checking should be handled in implementation code
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handler = (arg: any): void => {
      this.emit(event, arg);
    };

    const events = this.attachedProxy.get(target) ?? [];
    if (events.includes(event as string)) {
      return;
    }

    target.on(event, handler);
    events.push(event as string);
    this.attachedProxy.set(target, events);

    this.addInnerDisposer(target.off.bind(target, event, handler));
  }
}

import { Properties } from "csstype";
import React, { ReactElement } from "react";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon, {IconStyleProps} from "../../../../../ui-lib/Icons";
import { RootStyles } from "../../../../../ui-lib/typings/css";

interface PauseButtonClasses extends RootStyles {
  icon?: IconStyleProps;
}

interface PauseButtonProps extends Partial<ButtonIconProps> {
  classes?: PauseButtonClasses;
}

const PauseButton = ({
  active,
  classes = {
    root: {},
    icon: {},
  },
  label = "Pause Recording Button",
  icon: ProvidedIcon,
  ...rest
}: PauseButtonProps): ReactElement => {
  const proppedIcon = <Icon iconName="recorderpause" classes={classes?.icon} />;

  return (
    <ButtonIcon
      active={active}
      classes={classes}
      label={label}
      icon={ProvidedIcon || proppedIcon}
      onClick={rest.onClick}
      data-selenium="pause-button"
      {...rest}
    >
      Click to Pause Recording
    </ButtonIcon>
  );
};

export default PauseButton;

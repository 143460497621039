import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../../store/encoder";
import { LivelyEncoderUiContext } from "../../../../context";
import Checkbox, { CheckboxProps, CheckboxStyles } from "../../../../ui-lib/Inputs/Checkbox";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

interface EchoCancellationClasses {
  echoCancellationCheckbox?: CheckboxStyles;
}

type EchoCancellationCheckboxProps = Omit<CheckboxProps, "classes"> & { classes?: EchoCancellationClasses };

const ModularEchoCancellationCheckbox = observer(({ classes, ...props }: Partial<EchoCancellationCheckboxProps>) => {
  /**
   * Component Name (for error msg)
   */
  const componentName = "<EchoCancellationCheckbox/>";

  /**
   * Access LivelyEncoderUiContext & destructure API state
   */
  const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

  /**
   * Assess conditions for an undefined store.
   *
   * @returns {boolean}
   * */
  const hasUndefinedStore: () => boolean = () =>
    !!(
      !ctx?.mediaStreamController ||
      ctx?.mediaStreamController.echoCancellation === undefined ||
      ctx?.mediaStreamController.supportsEchoCancellation === undefined
    );

  /**
   * Throw error (and trigger ErrorBoundary) if store is undefined.
   * */
  useUndefinedStoreError(hasUndefinedStore, componentName);

  const toggleEchoCancellation = (): void => {
    if (ctx?.mediaStreamController != null) {
      ctx.mediaStreamController.echoCancellation = !ctx.mediaStreamController.echoCancellation;
    }
  };

  /**
   * Wrap onChange function in global Error handler (to trigger ErrorBoundary).
   * */
  const handleInputChange = useUIEventError(toggleEchoCancellation, componentName);

  return (
    <Checkbox
      {...props}
      classes={classes?.echoCancellationCheckbox}
      label="Echo Cancellation"
      disabled={ctx?.mediaStreamController == null || !ctx?.mediaStreamController?.supportsEchoCancellation()}
      checked={ctx?.mediaStreamController?.echoCancellation === true}
      onChange={handleInputChange}
      title={
        !ctx?.mediaStreamController?.supportsEchoCancellation()
          ? "Your browser does not support echo cancellation"
          : undefined
      }
    />
  );
});

const EchoCancellationCheckboxWithErrorBoundary: FC<Partial<EchoCancellationCheckboxProps>> = ({
  classes,
  ...props
}) => {
  return (
    <ErrorBoundary
      render={() => (
        <Checkbox
          {...props}
          disabled
          classes={classes?.echoCancellationCheckbox}
          label="Echo Cancellation Unavailable"
        />
      )}
    >
      <ModularEchoCancellationCheckbox classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default EchoCancellationCheckboxWithErrorBoundary;

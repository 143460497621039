import { LoggerCore } from "@livelyvideo/log-client";
import { PACKAGE_NAME } from "../../utils/common";
import { context } from "./context/context";
import { VcContext } from "./context/vc-context";

interface NamedClass {
  readonly displayName: string;
}

export function extendContext(ctx: VcContext, klass: NamedClass): VcContext {
  return context(
    {
      logger: new LoggerCore(PACKAGE_NAME).extend(ctx.logger).appendChain(klass),
      chain: `${ctx.chain}:${klass.displayName}`,
    },
    ctx,
  );
}

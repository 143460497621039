import { Properties } from "csstype";
import { StylesObject } from "../../../ui-lib/typings/css";

type StyleProps = {
  root: Properties;
  recordButton: StylesObject;
  pauseButton: StylesObject;
  startButton: StylesObject;
  saveButton: StylesObject;
  deleteButton: StylesObject;
};

const styles: StyleProps = {
  root: {
    display: "flex",
  },
  recordButton: {
    root: {
      margin: 5,
      color: "#e45b59 !important",
    },
  },
  pauseButton: {
    root: {
      margin: 5,
      color: "#007fff !important",
    },
  },
  startButton: {
    root: {
      margin: 5,
      color: "#007fff !important",
    },
  },
  saveButton: {
    root: {
      margin: 5,
      color: "#007fff !important",
    },
  },
  deleteButton: {
    root: {
      margin: 5,
      color: "#e45b59 !important",
    },
  },
};

export default styles;

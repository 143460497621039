import React, { FC } from "react";
import { IconProps } from "../iconProps";

  const Pause: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
      fill="currentColor"
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="recorderpause"
      focusable="false"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <rect x="6" y="4" width="2" height="16"></rect>
      <rect x="16" y="4" width="2" height="16"></rect>
    </svg>
  );
};

export default Pause;

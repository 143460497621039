import { Properties } from "csstype";
import type { types } from "@livelyvideo/video-client-core";
import React, { forwardRef, KeyboardEventHandler, MouseEventHandler, ReactChildren, useContext } from "react";
import { EncoderUiState } from "../../../store/encoder";
import { PlayerUiState } from "../../../store/player";
import { LivelyEncoderUiContext, LivelyPlayerUiContext } from "../../context";
import { mergeStyles } from "../../livelyStyles";
import { RootStyles } from "../typings/css";
import { videoWrapperStyles } from "./styles";

interface VideoWrapperClasses extends RootStyles {
  playerOverlayButton?: RootStyles;
}

export interface VideoWrapperProps {
  onClick?: () => MouseEventHandler<HTMLButtonElement> | KeyboardEventHandler<HTMLButtonElement>;
  variant?: string;
  children?: ReactChildren;
  classes?: VideoWrapperClasses;
  style?: Properties;
  pillarBox?: boolean;
  backgroundAudio?: boolean;
  mirror?: boolean;
  isFullScreen?: boolean;
  zeroBitrate?: types.VideoClientEvents["zeroBitrate"];
}

const VideoWrapper = forwardRef<HTMLDivElement, VideoWrapperProps>(
  ({ onClick, variant, children, isFullScreen, classes, style, pillarBox = false, mirror }, ref) => {
    /**
     * These are needed to access the videoElement from the player/encoder UiContext.
     */
    const playerCtx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);
    const encoderCtx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

    /**
     * Determines the styling needed based on pillarBox/isFullScreen props.
     */
    const styling = videoWrapperStyles(pillarBox, isFullScreen, mirror);

    const mergedClasses = mergeStyles({ source: classes, target: styling }, { stylesNamespace: "videoWrapper" });

    /**
     * If we have a videoElement class key we will add them to the videoElement.
     */
    if (mergedClasses.videoElement) {
      if (playerCtx?.videoElement && playerCtx?.videoElement instanceof HTMLVideoElement) {
        playerCtx?.videoElement?.classList.add(mergedClasses.videoElement);
      }
      if (encoderCtx?.videoElement && encoderCtx?.videoElement instanceof HTMLVideoElement) {
        encoderCtx?.videoElement?.classList.add(mergedClasses.videoElement);
      }
    }

    const handleMouseOver = (): void => {
      if (playerCtx && !playerCtx.mobileDevice) {
        playerCtx.videoMouseOver = true;
      } else if (encoderCtx && !encoderCtx.mobileDevice) {
        encoderCtx.videoMouseOver = true;
      }
    };

    const handleMouseOut = (): void => {
      if (playerCtx && !playerCtx.mobileDevice) {
        playerCtx.videoMouseOver = false;
      } else if (encoderCtx && !encoderCtx.mobileDevice) {
        encoderCtx.videoMouseOver = false;
      }
    };

    return (
      <div
        onMouseEnter={() => handleMouseOver()}
        onMouseLeave={() => handleMouseOut()}
        className={`
      ${mergedClasses.root ? mergedClasses.root : ""}
      ${variant != null ? `lv-${variant}` : ""}
    `}
        data-selenium="video-element"
        onClick={onClick}
        onKeyDown={undefined}
        style={style}
        role="button"
        tabIndex={0}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

VideoWrapper.displayName = "VideoWrapper";

export default VideoWrapper;

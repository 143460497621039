import { WebDevice } from "../../internal/adapters/web-device";
import { DeviceAPI } from "../device";

export { Feature, Features } from "./features/feature";
export * from "./features/types";

// that's intentionally mutable
// eslint-disable-next-line import/no-mutable-exports
export let device: DeviceAPI = new WebDevice();

let resolvePromise: (value: DeviceAPI | PromiseLike<DeviceAPI>) => void;
export const onceDeviceReady: Promise<DeviceAPI> = new Promise((r) => {
  resolvePromise = r;
});

export function implement(_device: DeviceAPI): void {
  device = _device;
  resolvePromise(device);
}

import { makeStyles } from "@material-ui/styles";
import { livelyStylesNamespace } from "../store/utils";
import { RootStyles, StylesObject } from "./ui-lib/typings/css";

interface Namespace {
  stylesNamespace?: string;
}

interface ClassesObject {
  source?: StylesObject;
  target?: StylesObject;
}

const mergeClasses = (arrayOfObjectsToMerge: StylesObject[]): StylesObject => {
  const mergedStyles: { [key: string]: string } = {};
  const componentStyles = Object.entries(arrayOfObjectsToMerge[0]);
  const providedStyles = Object.entries(arrayOfObjectsToMerge[1]);

  componentStyles.forEach(([componentStyleKey, componentStyleValue]: [string, string]) => {
    const style = providedStyles.find(([providedStyleKey]) => componentStyleKey === providedStyleKey);
    if (style) {
      const mergedStyle = `${componentStyleValue} ${style[1]}`;
      mergedStyles[componentStyleKey] = mergedStyle;
    } else {
      mergedStyles[componentStyleKey] = componentStyleValue;
    }
  });
  return mergedStyles;
};

// I think this functionality needs to be revisted. This
// will always return "object" bc Object.keys() returns an array.
const typeOfClasses = (classes: Record<any, any>): string => {
  if (typeof Object.keys(classes) === "string") {
    return "string";
  }
  return "object";
};

const isObject = (item: StylesObject | string): boolean => {
  return item != null && typeof item === "object" && !Array.isArray(item);
};

const mergeStylesObjects = (target: StylesObject, source: StylesObject): StylesObject => {
  const output = { ...target };

  Object.keys(source).forEach((key) => {
    if (isObject(source[key])) {
      if (!(key in target)) Object.assign(output, { [key]: source[key] });
      else {
        output[key] = mergeStylesObjects(target[key], source[key]);
      }
    } else {
      Object.assign(output, { [key]: source[key] });
    }
  });

  return output;
};

// This is the entrypoint
// This is now where we figure out which utility we call depending on classname or style object
// Takes an object of strings or object of objects

const mergeStyles = ({ target = {}, source = {} }: ClassesObject, namespace: Namespace): StylesObject => {
  const { stylesNamespace } = namespace;
  // if source is an empty object
  if (Object.keys(source).length === 0) {
    if (typeOfClasses(target) === "string") {
      return target;
    }

    const newClasses = makeStyles(target, {
      classNamePrefix: `${livelyStylesNamespace}-${stylesNamespace}`,
      name: livelyStylesNamespace,
    })();

    return newClasses;
  }

  // if target is an empty object
  if (Object.keys(target).length === 0) {
    if (typeOfClasses(source) === "string") {
      return source;
    }

    const newClasses = makeStyles(source, {
      classNamePrefix: `${livelyStylesNamespace}-${stylesNamespace}`,
      name: livelyStylesNamespace,
    })();

    return newClasses;
  }

  // if both source and target are classes
  if (typeOfClasses(target) === "string" && typeOfClasses(source) === "string") {
    // merge classes
    const newClasses = mergeClasses([target, source]);

    return newClasses;
  }

  // if source and target are style objects
  if (typeOfClasses(source) === "object" && typeOfClasses(target) === "object") {
    // Merge classes
    const styles = mergeStylesObjects(target, source);

    // Convert to classes
    const newClasses = makeStyles(styles, {
      classNamePrefix: `${livelyStylesNamespace}-${stylesNamespace}`,
      name: livelyStylesNamespace,
    })();

    return newClasses;
  }

  // if target is classes and source is styles object
  if (typeOfClasses(target) === "string" && typeOfClasses(source) === "object") {
    // Convert source to classes

    const convertedSource = makeStyles(source, {
      classNamePrefix: `${livelyStylesNamespace}-${stylesNamespace}`,
      name: livelyStylesNamespace,
    })();

    // Merge classes
    const newClasses = mergeClasses([target, convertedSource]);

    return newClasses;
  }

  // if source is classes and target is styles object
  if (typeOfClasses(source) === "string" && typeOfClasses(target) === "object") {
    // convert target to classes
    const convertedTarget = makeStyles(target, {
      classNamePrefix: `${livelyStylesNamespace}-${stylesNamespace}`,
      name: livelyStylesNamespace,
    })();

    // Merge classes
    const newClasses = mergeClasses([convertedTarget, source]);

    return newClasses;
  }

  return {};
};
export type { StylesObject, RootStyles };
export { makeStyles, mergeStyles, mergeStylesObjects };

import { Properties } from "csstype";
import React, { FC, ReactNode } from "react";
import { mergeStyles, mergeStylesObjects } from "../../livelyStyles";
import CloseButton from "../Buttons/Close";
import { EventFunction } from "../typings/clickevents";
import styles, { SidebarStyles } from "./styles";

export interface SlidingShelfProps {
  classes?: SidebarStyles;
  onCloseButtonClick?: EventFunction;
  open?: boolean;
  children?: ReactNode;
  variant?: string;
  height?: number;
  style?: Properties;
}

const SlidingShelf: FC<SlidingShelfProps> = ({
  classes = { root: {} },
  onCloseButtonClick,
  open,
  children,
  variant = "light",
  height,
  style,
  ...rest
}: SlidingShelfProps) => {
  const mergedClasses = mergeStyles({ source: classes, target: styles }, { stylesNamespace: "slidingShelf" });
  const mergedStyles = mergeStylesObjects(classes, styles);

  return (
    <div className={`${mergedClasses.root} ${variant === "dark" && "dark-shelf"} ${open && "open"}`} {...rest}>
      <CloseButton onClick={onCloseButtonClick} open={open} classes={{ root: mergedStyles.closeButton }} />
      {children}
    </div>
  );
};

export default SlidingShelf;

import { RootStyles } from "../../typings/css";

const styles = (isMobileDevice: boolean): RootStyles => {
  const setStyles = {
    root: {
      display: "flex!important",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "10rem",
      color: "#f5f5f5",
      cursor: "pointer",
      height: "40px",
      width: "40px",
      marginLeft: isMobileDevice ? 5 : "none",
      marginRight: isMobileDevice ? 5 : "none",
      outline: "none",
      transitionDuration: ".15s",
      transitionProperty: "color, background-color, border",
      transitionTimingFunction: "linear",

      // Disabled button appearance
      "&:disabled": {
        color: "#ff6565",
        opacity: 0.5,
        cursor: "not-allowed",
      },

      "&.custom": {
        backgroundColor: "transparent",
      },

      // Active button appearance states

      "&.lv-button--on": {
        color: "#f5f5f5",
      },

      "&.lv-button--off": {
        color: "#ff6565",
      },

      "&.lv-button--active": {
        backgroundColor: "#ff6565",
        color: "#f5f5f5",
      },

      "&.lv-button--get-sound": {
        position: "absolute",
        zIndex: 130,
        left: 0,
      },

      // Button flex position helper
      "&.lv-push-left": {
        marginLeft: "auto",
      },

      "&.lv-push-right": {
        marginRight: "auto",
      },
    },
  };
  return setStyles;
};

export default styles;

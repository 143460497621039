interface TimingStat {
  ms?: number;
  name?: string;
  driver?: string;
  abr?: string; // assigned border region
  aor?: string; // assigned origin region
  atr?: string; // assigned transcode region
  rep?: boolean; // indicate if already replicated to selected border
}

/**
 * @class to track stats
 */
class Stats {
  private eventCounter = 0;

  private timings: { [key: number]: TimingStat } = {};

  /**
   * @param {string} name Name of timing stat to track
   * @return {number} id of stat to track
   */
  start(name: string): number {
    this.eventCounter += 1;
    const id = this.eventCounter;
    this.timings[id] = {
      ms: Date.now(),
      name,
    };
    return id;
  }

  /**
   * @param {number} id Id of stat top stop tracking
   * @param {object} [inputMetadata] Metadata to send along with the stat
   * @return {TimingStat}
   */
  stop(id: number, inputMetadata: TimingStat): TimingStat {
    const ms = this.timings[id]?.ms;
    if (ms == null) {
      return {};
    }

    const metadata: TimingStat = { ...inputMetadata } ?? {};
    metadata.ms = Date.now() - ms;

    // logger.timing(this.timings[id].name, metadata);
    // TODO add logger
    delete this.timings[id];
    return metadata;
  }
}

export { TimingStat, Stats };

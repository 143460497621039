import { observer } from "mobx-react-lite";
import React, { FC, ReactElement, useContext, useEffect } from "react";
import { EncoderUiState, PlayerUiState } from "../../../../store";
import { LivelyEncoderUiContext, LivelyPlayerUiContext } from "../../../context";
import MediaWrapper, { MediaWrapperProps } from "../../../ui-lib/MediaWrapper";
import { ErrorBoundary, useUndefinedStoreError } from "../../ErrorBoundary";

interface VideoWrapper extends HTMLDivElement {
  webkitRequestFullScreen: () => void;
  mozRequestFullScreen: () => void;
}

type ModularMediaContainerProps = Omit<MediaWrapperProps, "ref">;

const ModularMediaContainer = observer(({ children, classes, ...props }: Partial<ModularMediaContainerProps>) => {
  const ctx =
    useContext<EncoderUiState | null>(LivelyEncoderUiContext) != null
      ? useContext<EncoderUiState | null>(LivelyEncoderUiContext)
      : useContext<PlayerUiState | null>(LivelyPlayerUiContext);

  const hasUndefinedStore: () => boolean = () =>
    ctx?.isFullscreen === undefined || ctx?.newWindow === undefined || ctx?.videoWrapperElement === undefined;

  useUndefinedStoreError(hasUndefinedStore, "<MediaContainer/>");

  const videoWrapperRef: React.Ref<VideoWrapper> = React.createRef();

  useEffect(() => {
    if (videoWrapperRef.current && ctx) {
      ctx.videoWrapperElement = videoWrapperRef.current;
    }
  }, [videoWrapperRef, ctx?.videoWrapperElement, ctx]);

  const mediaWrapperComponent: ReactElement = (
    <MediaWrapper ref={videoWrapperRef} classes={classes} isFullscreen={ctx?.isFullscreen} {...props}>
      {children}
    </MediaWrapper>
  );

  // return !uiState.launchNewWindow
  //   ? mediaWrapperComponent
  //   : createPortal(mediaWrapperComponent, uiState.newWindowElement);
  return mediaWrapperComponent;
});

const MediaContainerWithErrorBoundary: FC<Partial<ModularMediaContainerProps>> = ({ classes, ...props }) => {
  return (
    <ErrorBoundary render={() => <MediaWrapper classes={classes} {...props} />}>
      <ModularMediaContainer classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default MediaContainerWithErrorBoundary;

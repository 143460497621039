import type { Json } from "@livelyvideo/log-node";
import { extractAggregates } from "@livelyvideo/log-node";
import { computed, makeObservable, observable } from "mobx";
import type { CallAPI } from "../api/call";
import type { PeerAPI, PeerEvents } from "../api/peer";
import { Features } from "../api/peer/features/feature";
import { BitrateLayer } from "../api/player/features/bitrate-switching";
import type { Call } from "./call";
import { LayerNotFoundError } from "./errors";
import { MediasoupSource } from "./mediasoup-source";
import { CorePlayerOptions } from "./player/core";
import { MediasoupPlayer } from "./player/mediasoup";
import type { WebrtcPlayerOptions } from "./player/webrtc";
import type { Call as InternalCall } from "./pvc/call/call";
import type { CallEvents } from "./pvc/call/call.events";
import type { PeerParameters } from "./pvc/call/common";
import { onceCanceled } from "./utils/context/context";
import type { VcContext } from "./utils/context/vc-context";
import { ObservableEventEmitter } from "./utils/events/event-emitter";
import { extendContext } from "./utils/logger";

export class Peer extends ObservableEventEmitter<PeerEvents> implements PeerAPI {
  static readonly displayName = "Peer";

  readonly call: CallAPI;

  private readonly peerParams: PeerParameters;

  readonly remoteSources = new Map<string, { player: MediasoupPlayer; provider: MediasoupSource }>();

  private readonly pvcCall: InternalCall;

  private readonly playerOptions: WebrtcPlayerOptions;

  private readonly ctx: VcContext;

  constructor(
    ctx: VcContext,
    call: Call,
    pvcCall: InternalCall,
    peerParams: PeerParameters,
    playerOptions?: WebrtcPlayerOptions,
  ) {
    super();

    makeObservable(this, {
      remoteSources: observable,

      streams: computed,
      players: computed,
    });

    this.ctx = ctx;
    this.call = call;
    this.pvcCall = pvcCall;
    this.playerOptions = playerOptions ?? {};
    this.pvcCall.on("CALL_ADD_CONSUMER", this.addConsumer);
    this.pvcCall.on("CALL_REMOVE_CONSUMER", this.removeConsumer);
    this.pvcCall.on("CALL_SWAP_CONSUMERS", this.swapConsumers);
    this.pvcCall.on("CALL_SET_CONSUMER_PAUSED", this.pauseConsumer);
    this.pvcCall.on("CALL_SET_CONSUMER_RESUMED", this.resumeConsumer);
    this.pvcCall.on("CALL_CONSUMER_LAYER_CHANGED", this.layerChanged);
    this.pvcCall.on("CALL_CONSUMER_SOURCES", this.sourcesChanged);
    onceCanceled(ctx).then((reason) => this.dispose(`Peer Context Cancelled: ${reason}`));

    this.peerParams = peerParams;

    ctx.logger.attachObject(this);
    ctx.logger.trace("constructor()");

    this.addInnerDisposer(this.close);
  }

  get streams(): Array<MediasoupSource> {
    return Array.from(this.remoteSources.values()).map((p) => p.provider);
  }

  get players(): Array<MediasoupPlayer> {
    return Array.from(this.remoteSources.values()).map((p) => p.player);
  }

  get peerId(): string {
    return this.peerParams.peerId ?? "";
  }

  get userId(): string {
    return this.peerParams.userId ?? "";
  }

  get appData(): Record<string, unknown> {
    return this.peerParams.appData ?? {};
  }

  private addConsumer({ peerId, streamName, consumer }: CallEvents["CALL_ADD_CONSUMER"]): void {
    if (peerId !== this.peerParams.peerId) {
      return;
    }

    this.ctx.logger.debug("addConsumer()", { streamName, consumerId: consumer.id });

    const rs = this.remoteSources.get(streamName);
    if (rs == null) {
      const provider = new MediasoupSource(extendContext(this.ctx, MediasoupSource), this, streamName);

      const playerOptions: CorePlayerOptions = {
        ...{ autoPlay: this?.ctx?.videoClient?.options?.autoPlay },
        ...this.playerOptions,
      };

      const player = new MediasoupPlayer(extendContext(this.ctx, MediasoupPlayer), provider, playerOptions);

      this.emit("videoConsumer");
      provider.addConsumer(consumer);
      this.remoteSources.set(streamName, { player, provider });
      this.emit("playerAdded", { player, streamName, peer: this });
      this.emit("streamAdded", { stream: provider, streamName, peer: this });
    } else {
      rs.provider.addConsumer(consumer);
    }
  }

  private removeConsumer({ peerId, streamName, consumerId }: CallEvents["CALL_REMOVE_CONSUMER"]): void {
    if (peerId !== this.peerParams.peerId) {
      return;
    }

    this.ctx.logger.trace("removeConsumer()", { peerId, streamName, consumerId });

    const rs = this.remoteSources.get(streamName);
    if (rs != null) {
      rs.provider.removeConsumer(consumerId);
      if (rs.provider.getTracks().length === 0) {
        this.emit("playerRemoved", { player: rs.player, streamName, peer: this });
        this.emit("streamRemoved", { stream: rs.provider, streamName, peer: this });
        this.remoteSources.delete(streamName);
      }
    } else {
      this.ctx.logger.warn("Unable to remove consumer stream not found", { peerId, streamName, consumerId });
    }
  }

  private swapConsumers({ peerId, streamName, add, remove }: CallEvents["CALL_SWAP_CONSUMERS"]): void {
    if (peerId !== this.peerParams.peerId) {
      return;
    }

    this.ctx.logger.trace("swapConsumer()", { peerId, streamName, add: add.map((c) => c.id), remove });

    const rs = this.remoteSources.get(streamName);
    if (rs != null) {
      rs.provider.swapConsumers(add, remove);
    } else {
      this.ctx.logger.warn("Unable to swap consumer stream not found", { peerId, streamName });
    }
  }

  private pauseConsumer({ peerId, streamName, consumerId }: CallEvents["CALL_SET_CONSUMER_PAUSED"]): void {
    if (peerId !== this.peerParams.peerId) {
      return;
    }

    this.ctx.logger.debug("CALL_SET_CONSUMER_PAUSED", { streamName, consumerId });

    const rs = this.remoteSources.get(streamName);
    if (rs != null) {
      rs.provider.pauseConsumer(consumerId);
    } else {
      this.ctx.logger.warn("Unable to pause consumer stream not found", { peerId, streamName, consumerId });
    }
  }

  private resumeConsumer({ peerId, streamName, consumerId }: CallEvents["CALL_SET_CONSUMER_RESUMED"]): void {
    if (peerId !== this.peerParams.peerId) {
      return;
    }
    this.ctx.logger.debug("CALL_SET_CONSUMER_RESUMED", { streamName, consumerId });

    const rs = this.remoteSources.get(streamName);
    if (rs != null) {
      rs.provider.resumeConsumer(consumerId);
    } else {
      this.ctx.logger.warn("Unable to resumeConsumer stream not found", { peerId, streamName, consumerId });
    }
  }

  private layerChanged(ev: CallEvents["CALL_CONSUMER_LAYER_CHANGED"]): void {
    if (ev.peerId !== this.peerParams.peerId) {
      return;
    }
    this.ctx.logger.debug("CALL_CONSUMER_LAYER_CHANGED", { ev });

    const rs = this.remoteSources.get(ev.streamName);
    if (rs != null) {
      rs.provider.consumerLayersChanged(ev);
    } else {
      this.ctx.logger.warn("Unable to change layer stream not found", { streamName: ev.streamName, ev });
    }
  }

  private sourcesChanged(ev: CallEvents["CALL_CONSUMER_SOURCES"]): void {
    if (ev.peerId !== this.peerParams.peerId) {
      return;
    }

    this.ctx.logger.debug("CALL_CONSUMER_SOURCES", { ev });

    const rs = this.remoteSources.get(ev.streamName);
    if (rs != null) {
      rs.provider.consumerSourcesChanged(ev.layers);
    } else {
      this.ctx.logger.warn("Unable to change source stream not found", {
        remoteSources: Object.fromEntries(this.remoteSources.entries()),
        streamName: ev.streamName,
        ev,
      });
    }
  }

  close(): void {
    this.pvcCall.off("CALL_ADD_CONSUMER", this.addConsumer);
    this.pvcCall.off("CALL_REMOVE_CONSUMER", this.removeConsumer);
    this.pvcCall.off("CALL_SET_CONSUMER_PAUSED", this.pauseConsumer);
    this.pvcCall.off("CALL_SET_CONSUMER_RESUMED", this.resumeConsumer);
    this.pvcCall.off("CALL_CONSUMER_LAYER_CHANGED", this.layerChanged);

    for (const [streamName, rs] of this.remoteSources.entries()) {
      rs.provider.consumerIds.forEach((id) => {
        this.removeConsumer({ peerId: this.peerId, streamName, consumerId: id });
      });
    }
  }

  /**
   * @internal
   */
  setLayer(layer: BitrateLayer): void {
    const consumerId = layer.appData?.consumerId;
    if (consumerId == null || typeof consumerId !== "string") {
      this.throwError(new LayerNotFoundError("consumerId is null", { layerId: layer.id }));
    }

    this.ctx.logger.info("select new layer", { layer });
    this.pvcCall.setPreferredEncoding(consumerId, layer.id);
  }

  /**
   * @deprecated should be removed in the feature
   */
  isImplements<K extends keyof Features, T extends Features[K]>(feature: K): this is this & T {
    return true;
  }

  toJSON(): Json {
    return {
      call: this.call,

      aggregates: {
        ...extractAggregates(this.call, "support"),
        support: this.ctx.support.hash,
        peerId: this.peerId,
      },
    };
  }
}

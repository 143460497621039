import React, { FC, useState } from "react";
import { useUID } from "react-uid";
import { mergeStyles } from "../../livelyStyles";
import { EventFunction } from "../typings/clickevents";
import styles, { InputStyles } from "./styles";

export type CheckboxStyles = Omit<InputStyles, "range" | "radio">;

export interface CheckboxProps {
  classes?: CheckboxStyles;
  checked?: boolean;
  classNames?: string;
  disabled?: boolean;
  id?: string;
  label?: string | number;
  tag?: string;
  onChange?: EventFunction; // () => ChangeEventHandler<HTMLElement>;
  title?: string;
}

const Checkbox: FC<CheckboxProps> = ({ classes = {}, label, checked, id, title, disabled, ...rest }) => {
  const [state, setState] = useState(checked);

  const mergedClasses = mergeStyles({ source: classes, target: styles }, { stylesNamespace: "checkbox" });

  const handleChange = (): void => {
    if (rest.onChange !== undefined) {
      rest.onChange();
    }
    setState(checked);
  };

  const uid = useUID();

  return (
    <div className={`${mergedClasses.root} ${mergedClasses.fields} ${mergedClasses.checkbox}`}>
      <label
        htmlFor={uid}
        className={disabled ? `${mergedClasses.label} ${mergedClasses.labelDisabled}` : mergedClasses.label}
        title={title}
      >
        <input
          type="checkbox"
          className={`${mergedClasses.input}`}
          id={uid}
          data-selenium={rest?.tag ?? "checkbox-input"}
          onChange={handleChange}
          checked={state}
          title={title}
          disabled={disabled}
          {...rest}
        />
        {label}
      </label>
    </div>
  );
};

export default Checkbox;

import { RootStyles } from "../typings/css";

const styles: RootStyles = {
  root: {
    height: "inherit",
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },
};

export default styles;

import { LoggerCore } from "@livelyvideo/log-client/lib";
import { AudioContextFeature } from "../api/adapter/features/audio-context";
import { MediaDeviceFeature } from "../api/adapter/features/media-device";
import {
  BlobEvent,
  FileSystemFileHandle,
  FileSystemWritableFileStream,
  MediaRecorder,
  MediaRecorderFeature,
  MediaRecorderOptions,
  SaveFilePickerOptions,
} from "../api/adapter/features/media-recorder";
import { MediaStream, MediaStreamFeature, MediaStreamTrack } from "../api/adapter/features/media-stream";
import { DeviceAPI } from "../api/device";
import { device, Feature } from "../api/adapter";
import { MediaRecorderError, NotSupportedError, VideoClientError } from "../internal/errors";
import { isIosDevice } from "../internal/utils/browser-support";
import { ObservableEventEmitter } from "../internal/utils/events/event-emitter";
import packageJson from "../package-json";
import { PACKAGE_NAME } from "../utils/common";

export interface RecorderOptions {
  mimetype?: string; // https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/mimeType
  frameRate?: number;
  displaySurface?: "application" | "browser" | "monitor" | "window" | "all";
  cursor?: "always" | "motion" | "never";
  audioBitsPerSecond?: number;
  videoBitsPerSecond?: number;
  startRecordingWhenClicked?: boolean;
  height?: number;
  logger?: LoggerCore;
}

export interface RecorderEvents {
  error: VideoClientError;
  pause: void;
  close: void;
  stop: void;
  start: void;
  "browser-stop": void;
  "save-canceled": void;
  resume: void;
  data: Blob;
  save: void;
  unlock: void;
  lock: void;
  disposed: void;
  stream: MediaStream | null;
}

export type Result = {
  status: "success" | "error";
  message?: string;
};

export class Recorder extends ObservableEventEmitter<RecorderEvents> {
  static readonly displayName = "Recorder";

  recorder: MediaRecorder | null = null;

  locked = false;

  options: RecorderOptions;

  destroyed = false;

  localFilePath: FileSystemFileHandle | null = null;

  writable: FileSystemWritableFileStream | null = null;

  stream: MediaStream | null;

  totalStorageAvailable = 0;

  maxRecordingLength = 0;

  dataWritten = false;

  closing = false;

  saving = false;

  storageAlertSent = false;

  userClosedSave = false;

  dataAggregate: Array<unknown> = [];

  private readonly device: DeviceAPI &
    MediaRecorderFeature &
    MediaStreamFeature &
    MediaDeviceFeature &
    AudioContextFeature;

  private readonly logger: LoggerCore;

  constructor(stream: MediaStream | null, options: RecorderOptions) {
    super();

    if (!device.isImplements(Feature.MEDIA_RECORDER)) {
      this.throwError(new NotSupportedError("MediaRecorder is not supported", {}));
    }

    if (!device.isImplements(Feature.MEDIA_STREAM) || !device.isImplements(Feature.MEDIA_DEVICE)) {
      this.throwError(new NotSupportedError("MediaStream is not supported", {}));
    }

    if (!device.isImplements(Feature.AUDIO_CONTEXT)) {
      this.throwError(new NotSupportedError("AudioContext is not supported", {}));
    }

    this.device = device;
    this.logger =
      options.logger ??
      new LoggerCore(PACKAGE_NAME)
        .setLoggerMeta("client", "VDC")
        .setLoggerMeta("release", packageJson.version)
        .appendChain(Recorder);

    this.on("error", (err) => {
      VideoClientError.log(err, this.logger);
    });

    // Mediastream
    this.stream = stream ?? null;
    this.options = options ?? {};

    // Default options
    this.options.mimetype = this.options.mimetype ?? (isIosDevice() ? "video/mp4" : "video/webm;codecs=VP8");
    this.options.startRecordingWhenClicked = this.options.startRecordingWhenClicked ?? true;
    const audioBitsPerSecond = this.options.audioBitsPerSecond ?? 128_000;
    this.options.audioBitsPerSecond = audioBitsPerSecond;
    const videoBitsPerSecond = this.options.videoBitsPerSecond ?? 5_000_000;
    this.options.videoBitsPerSecond = videoBitsPerSecond;
    this.options.cursor = this.options.cursor ?? "motion";
    this.options.displaySurface = this.options.displaySurface ?? "all";
    this.options.frameRate = this.options.frameRate ?? 24;
    this.options.height = 1920;

    if (!device.MediaRecorder.isTypeSupported(this.options.mimetype)) {
      this.throwError(new MediaRecorderError("mimeType is not supported", { mimeType: this.options.mimetype }));
    }

    this.device.storageEstimate?.().then(({ usage, quota }) => {
      const usageInMib = Math.round(usage / (1024 * 1024));
      const quotaInMib = Math.round(quota / (1024 * 1024));
      const availableMbs = quotaInMib - usageInMib;
      this.totalStorageAvailable = availableMbs;
      // Calculate megabytes per second usage of recorder based on recording options
      const mbpsPerSecond = (audioBitsPerSecond + videoBitsPerSecond) / 8 / 1_000_000;
      // Calculate max recording length in minutes. Subtract 1 minute for buffer.
      this.maxRecordingLength = availableMbs / (mbpsPerSecond * 60) - 1;
    });
  }

  static isSupported(mimetype: string): boolean {
    return device.isImplements(Feature.MEDIA_RECORDER) && device.MediaRecorder.isTypeSupported(mimetype);
  }

  /**
   * private function designed to setup a generic recorder with its events
   */
  newMediaRecorder(stream: MediaStream, options: MediaRecorderOptions): MediaRecorder {
    const mr = new this.device.MediaRecorder(stream, options);
    mr.addEventListener("error", (err) => {
      this.emitError(new MediaRecorderError("media recorder error", {}));
    });

    const vt = stream.getVideoTracks();
    if (vt.length === 0 || !vt[0].enabled || vt[0].readyState === "ended") {
      this.throwError(new MediaRecorderError("no active video tracks", {}));
    }

    // Handles when user clicks chrome "stop sharing"
    vt[0].addEventListener("ended", () => {
      this.emit("browser-stop");
    });

    mr.addEventListener("pause", this.handlePause);
    mr.addEventListener("resume", this.handleResume);
    mr.addEventListener("start", this.handleStart);
    mr.addEventListener("stop", this.handleStop);
    mr.addEventListener("dataavailable", this.handleNewData);

    return mr;
  }

  handlePause(): void {
    this.emit("pause");
  }

  handleResume(): void {
    this.emit("resume");
  }

  handleStart(): void {
    this.emit("start");
  }

  handleStop(): void {
    this.emit("stop");
  }

  async handleNewData(ev: BlobEvent): Promise<void> {
    if (this.closing) {
      return;
    }

    if (this.saving) {
      const options: SaveFilePickerOptions = {
        suggestedName: `recording.${Date.now()}`,
        types: [
          {
            description: "Video Recording",
            accept: {
              "video/x-matroska": [".webm"],
            },
            "-use_wallclock_as_timestamps": true,
          },
        ],
      };
      try {
        this.localFilePath = await this.device.showSaveFilePicker(options);
        this.writable = await this.localFilePath.createWritable();
      } catch (err) {
        const inner = err instanceof Error ? err : null;
        this.emitError(new MediaRecorderError("an error occurred on save record", { inner }));
        this.saving = false;
        this.dataAggregate.push(ev.data);
        this.emit("save-canceled");
        return;
      }

      this.emit("data", ev.data);
      let blob = null;

      if (this.dataAggregate.length > 0) {
        this.dataAggregate.push(ev.data);
        blob = new Blob(this.dataAggregate);
      } else {
        blob = new Blob([ev.data]);
      }

      try {
        await this.writable?.write(blob);
        this.dataWritten = true;
        this.writable?.close();
        this.dataAggregate = [];
        await this.close();
      } catch (err) {
        const inner = err instanceof Error ? err : null;
        this.emitError(new MediaRecorderError("failed to write to file", { inner }));
      }

      return;
    }

    if (!this.dataWritten && !this.storageAlertSent) {
      this.storageAlertSent = true;
      this.pause();
      this.dataAggregate.push(ev.data);
      this.device.confirmMessage(
        "Your device is nearing its storage capacity. Please return to the app to save your recording.",
      );
      await this.record();
    }
  }

  /**
   * private helpers to lock device retrieval and local stream setup
   */
  lock(): void {
    this.locked = true;
    this.emit("lock");
  }

  unlock(): void {
    this.locked = false;
    this.emit("unlock");
  }

  /**
   * @method apply reload the recorder to reproduce a new keyframe, do this by retrieving devices again
   * @param {object} options audio & video bits per second
   * */
  apply(options: { audioBitsPerSecond?: string; videoBitsPerSecond?: string; stream?: string }): void {
    if (this.stream == null) {
      return;
    }

    if (this.locked) {
      this.logger.debug("recorder apply locked");
      device.setTimeout(() => {
        this.apply(options);
      }, 50);
      return;
    }

    this.lock();

    this.logger.info("applying recorder options", {
      options,
    });
  }

  /**
   * @property the current state of the stream: [inactive, recording, paused]
   */
  get state(): "inactive" | "recording" | "paused" {
    return this.recorder?.state ?? "inactive";
  }

  /**
   * @property the current recorder options
   */
  get recorderOptions(): RecorderOptions {
    return {
      audioBitsPerSecond: this.options.audioBitsPerSecond,
      videoBitsPerSecond: this.options.videoBitsPerSecond,
      mimetype: this.options.mimetype,
      startRecordingWhenClicked: this.options.startRecordingWhenClicked,
      frameRate: this.options.frameRate,
      displaySurface: this.options.displaySurface,
      cursor: this.options.cursor,
      height: this.options.height,
    };
  }

  async setupRecorder(): Promise<Result> {
    let stream: MediaStream;
    if (this.stream == null) {
      stream = await this.createStream();
    } else {
      stream = this.stream;
    }

    this.dataWritten = false;
    this.closing = false;
    this.saving = false;

    if (this.recorder == null) {
      this.recorder = await this.newMediaRecorder(stream, this.recorderOptions);
    }

    if (this.options.startRecordingWhenClicked) {
      await this.record();
    }

    return {
      status: "success",
    };
  }

  /**
   * @method record starts or resumes recording, emits an error if there is no active stream
   */
  async record(): Promise<Result> {
    if (this.stream == null) {
      this.emitError(new MediaRecorderError("attempting to record without a local stream", {}));
      return {
        status: "error",
        message: "No stream provided or created",
      };
    }

    if (this.stream.getTracks().length === 0) {
      this.emit("stream", null);
      this.emitError(
        new MediaRecorderError("attempting to start to record with a local stream that has no tracks", {}),
      );
      return {
        status: "error",
        message: "No video tracks from the stream found",
      };
    }

    if (this.state === "inactive") {
      // Start recorder with timeInterval of maxRecordingLength and convert to milliseconds by multiplying by 60000
      this.recorder?.start(this.maxRecordingLength * 60000);
    } else if (this.state === "paused") {
      this.recorder?.resume();
    }

    return {
      status: "success",
    };
  }

  /**
   * @method pause pauses a recording, emits an error if a recording is not already happening
   */
  async save(): Promise<Result> {
    if (this.recorder == null) {
      this.emitError(new MediaRecorderError("attempting to pause recorder with no recorder", {}));
      return {
        status: "error",
        message: "No recorder found",
      };
    }

    this.saving = true;

    this.pause();

    await this.recorder.requestData();

    return {
      status: "success",
    };
  }

  /**
   * @method pause pauses a recording, emits an error if a recording is not already happening
   */
  pause(): Result {
    if (this.recorder == null) {
      this.emitError(new MediaRecorderError("attempting to pause recorder with no recorder", {}));
      return {
        status: "error",
        message: "No recorder found",
      };
    }

    this.recorder.pause();
    return {
      status: "success",
    };
  }

  /**
   * @method close closes the recorder, emits an error if a recording is not already happening
   */
  async close(): Promise<Result> {
    if (this.recorder == null) {
      this.emitError(new MediaRecorderError("attempting to stop recorder with no recorder", {}));
      return {
        status: "error",
        message: "No recorder found",
      };
    }

    if (this.recorder.state !== "inactive") {
      this.closing = true;

      try {
        // Close all tracks on streams
        const tracks = this.stream?.getTracks();
        tracks?.forEach((track) => {
          track.stop();
        });

        // Stop the recorder
        this.recorder.stop();

        // Set state to be a new recorder
        this.recorder = null;
        this.destroyed = true;
        this.stream = null;
        this.localFilePath = null;
        this.writable = null;

        // this.removeAllListeners();
      } catch (err) {
        const inner = err instanceof Error ? err : null;
        this.emitError(new MediaRecorderError("failed to close", { inner }));
        return {
          status: "error",
          message: "Failed to close",
        };
      }
    } else {
      // return this.emit("error", "recorder already closed");
    }
    return {
      status: "success",
    };
  }

  async createStream(): Promise<MediaStream> {
    const mediaTrackConstraints = {
      video: {
        frameRate: this.options.frameRate,
        height: this.options.height,
        resizeMode: "crop-and-scale",
        cursor: this.options.cursor,
        displaySurface: this.options.displaySurface,
        logicalSurface: true,
      },
      audio: {
        autoGainControl: false,
        echoCancellation: false,
        googAutoGainControl: false,
        noiseSuppression: false,
      },
    };

    const videoMediaStream = await this.device.mediaDevices.getDisplayMedia(mediaTrackConstraints);
    const micAudioStream = await this.device.mediaDevices.getUserMedia({ audio: true });
    const audioTracks = videoMediaStream.getAudioTracks();
    const videoStream = videoMediaStream.getVideoTracks();
    const audioCtx = new this.device.AudioContext();

    let micAudioTrack;
    let tabAudioTrack;
    let dst;
    const mixedStream: MediaStream = new this.device.MediaStream();
    let tabAudioStream: MediaStream;

    if (audioTracks.length > 0) {
      tabAudioTrack = videoMediaStream.getAudioTracks()[0];
      dst = audioCtx.createMediaStreamDestination();
      tabAudioStream = new this.device.MediaStream([tabAudioTrack]);
      audioCtx.createMediaStreamSource(tabAudioStream).connect(dst);
      audioCtx.createMediaStreamSource(micAudioStream).connect(dst);

      mixedStream.addTrack(dst.stream.getTracks()[0]);
      mixedStream.addTrack(videoStream[0]);
    } else {
      micAudioTrack = micAudioStream.getAudioTracks();
      mixedStream.addTrack(micAudioTrack[0]);
      mixedStream.addTrack(videoStream[0]);
    }

    this.stream = mixedStream;
    return mixedStream;
  }

  /**
   * @method addAudioTrack Adds an audio track to the stream
   */
  addAudioTrack(audioTrack: MediaStreamTrack): void {
    this.stream?.addTrack(audioTrack);
  }

  /**
   * @method removeAudioTrack removes an audio track from the stream
   */
  removeAudioTrack(audioTrack: { id: string }): void {
    const audioTracks = this.stream?.getAudioTracks();

    audioTracks?.forEach((track) => {
      if (track.id === audioTrack.id) {
        this.stream?.removeTrack(track);
      }
    });
  }
}

import { action, makeObservable, observable, runInAction } from "mobx";
import BaseUiState from "../utils/ui-state";

interface RecorderUiStateEvents {
  // MobX Events
  recordingReady: RecorderUiState["recordingReady"];
  recording: RecorderUiState["recording"];
}

export default class RecorderUiState extends BaseUiState<RecorderUiStateEvents> {
  static readonly displayName = "RecorderUiState";

  recorder: Record<any, any> | null;

  resumeDisabled: boolean;

  constructor() {
    super();

    makeObservable(this, {
      // observables
      recordingReady: observable,
      recording: observable,

      // actions
      setRecorder: action,
      removeRecorder: action,
      setupRecorder: action,
      record: action,
      pauseRecording: action,
      browserStopEvent: action,
      closeRecording: action,
      saveRecording: action,
      resetRecorder: action,
      setRecordingReady: action,
    });

    this.recorder = null;
    this.recordingReady = false;
    this.recording = false;
    this.resumeDisabled = false;
  }

  /**
   * Set recorder.
   * @description Sets the recorder to a new recorder instantiation.
   * @example <caption>Example usage of setRecorder.</caption>
   * setRecorder(recorder);
   * Updates uiState.recorder = recorder;
   */
  setRecorder(recorder: Record<any, any> | null): void {
    this.recorder = recorder;
  }

  /**
   * Remove recorder.
   * @description Removes the recorder from context.
   * @example <caption>Example usage of removeRecorder.</caption>
   * removeRecorder();
   * Updates uiState.recorder = null;
   */
  removeRecorder(): void {
    this.recorder = null;
  }

  /**
   * Indicates whether the recorder is ready to start recording
   */
  recordingReady: boolean | null = false;

  /**
   * Indicates whether the video is being recorded or not.
   */
  recording: boolean | null = false;

  /**
   * Sets up new recorder context
   */
  async setupRecorder(startRecordingWhenClicked = true): Promise<void> {
    const res = await this.recorder?.setupRecorder();

    if (res.status === "error") {
      runInAction(() => {
        this.recording = false;
        this.recordingReady = false;
      });
    }

    if (startRecordingWhenClicked) {
      runInAction(() => {
        this.recording = true;
        this.recordingReady = true;
      });
    } else {
      runInAction(() => {
        this.recordingReady = true;
      });
    }
  }

  /**
   * Updates recorder context for recording status
   */
  async record(): Promise<void> {
    const res = await this.recorder?.record();

    if (res.status === "error") {
      runInAction(() => {
        this.recording = false;
      });
    }

    runInAction(() => {
      this.recording = true;
    });
  }

  /**
   * Pauses recording
   */
  pauseRecording(): void {
    runInAction(() => {
      this.recording = false;
    });
    this.recorder?.pause();
  }

  /**
   * Pauses recording
   */
  browserStopEvent(): void {
    runInAction(() => {
      this.recording = false;
      this.resumeDisabled = true;
    });
    this.recorder?.pause();
  }

  /**
   * Stops recording
   */
  async closeRecording(): Promise<void> {
    if (this.recorder?.stream) {
      await this.recorder?.close();
    }

    runInAction(() => {
      this.recording = false;
      this.recordingReady = false;
      this.recorder = null;
    });
  }

  /**
   * Stops recording
   */
  async saveRecording(): Promise<void> {
    runInAction(() => {
      this.recording = false;
    });

    await this.recorder?.save();

    runInAction(() => {
      this.recordingReady = false;
    });
  }

  /**
   * Resets recording context
   */
  async resetRecorder(): Promise<void> {
    runInAction(() => {
      this.recording = false;
      this.recordingReady = false;
      this.recorder = null;
    });
  }

  /**
   * Set recording ready
   */
  async setRecordingReady(): Promise<void> {
    runInAction(() => {
      this.recordingReady = true;
    });
  }
}

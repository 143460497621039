import LoggerCore from "@livelyvideo/log-client";
import type { types } from "@livelyvideo/video-client-core";
import { Properties } from "csstype";
import { observer } from "mobx-react-lite";
import React, { FC, ReactElement, useContext } from "react";
import { EncoderUiState } from "../../../../../../store/encoder";
import { LivelyCallContext, LivelyEncoderUiContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon, {IconStyleProps} from "../../../../../ui-lib/Icons";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface BroadcastButtonClasses extends RootStyles {
  icon?: IconStyleProps;
}

interface BroadcastButtonProps extends Partial<ButtonIconProps> {
  broadcastOptions: types.BroadcastOptions;
  classes?: BroadcastButtonClasses;
  onZeroBitrate?: (ev: types.BroadcastEvents["zeroBitrate"]) => void;
}

const ModularBroadcastButton = observer(
  ({
    icon: ProvidedIcon,
    onClick,
    screenReaderText,
    broadcastOptions,
    onZeroBitrate,
    active,
    classes = {
      root: {},
      icon: {},
    },
    label = "Start broadcast",
    ...props
  }: BroadcastButtonProps): ReactElement => {
    /**
     * Component Name (for error msg)
     */
    const componentName = "<BroadcastButton/>";

    const { streamName } = broadcastOptions;

    /**
     * Access LivelyEncoderUiContext & destructure API state
     */
    const encoderCtx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);
    const call = useContext<types.BaseCall | null>(LivelyCallContext);

    // Assess conditions for an undefined store.
    const hasUndefinedStore = (): boolean => encoderCtx?.mediaStreamController === undefined || call === undefined;
    // Throw error (and trigger ErrorBoundary) if store is undefined.
    useUndefinedStoreError(hasUndefinedStore, componentName);

    const broadcasting: boolean =
      (call?.broadcasts?.has(streamName) && call?.broadcasts?.get(streamName)?.state === "active") ?? false;

    const activeState = active ?? broadcasting;

    const toggleBroadcast = async (): Promise<void> => {
      if (broadcasting) {
        call?.broadcasts?.get(streamName)?.dispose("stream disposed via BroadcastButton");
        if (onZeroBitrate != null) {
          call?.on("zeroBitrate", onZeroBitrate);
        }
      } else if (encoderCtx?.mediaStreamController) {
        if (onZeroBitrate != null) {
          call?.off("zeroBitrate", onZeroBitrate);
        }
        call?.broadcast(encoderCtx.mediaStreamController, broadcastOptions);
      }
    };

    /**
     * handleClick function wrapped in global Error handler (to trigger ErrorBoundary).
     * */
    const handleClick = onClick ?? useUIEventError(toggleBroadcast, componentName);
    const icon = ProvidedIcon ?? <Icon iconName="broadcast" classes={classes?.icon} />;

    return (
      <ButtonIcon
        active={activeState}
        activeClass="lv-button--active"
        classes={classes}
        inactiveClass={null}
        data-selenium="broadcast-stream-button"
        icon={icon}
        onClick={handleClick}
        disabled={encoderCtx?.mediaStreamController == null || !encoderCtx?.mediaStreamController.hasActiveStream()}
        label={label}
        {...props}
      >
        {screenReaderText ?? `Click to ${activeState ? "Stop" : "Start"} Broadcast`}
      </ButtonIcon>
    );
  },
);

/**
 *
 * @visibleName Broadcast Button
 */
const BroadcastButtonWithErrorBoundary: FC<BroadcastButtonProps> = ({
  icon: ProvidedIcon,
  label = "Toggle broadcast",
  screenReaderText,
  broadcastOptions,
  classes,
  ...props
}) => {
  const icon = <Icon iconName="broadcast" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          data-selenium="broadcast-stream-button"
          disabled
          classes={classes}
          icon={ProvidedIcon ?? icon}
          label={label}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to start Broadcast"}
        </ButtonIcon>
      )}
    >
      <ModularBroadcastButton
        icon={ProvidedIcon}
        classes={classes}
        label={label}
        screenReaderText={screenReaderText}
        broadcastOptions={broadcastOptions}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default BroadcastButtonWithErrorBoundary;

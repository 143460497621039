import React, { FC } from "react";
import { IconProps } from "./iconProps";

const Clock: FC<IconProps> = ({ className }) => {
  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="clock"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
    >
      <path
        fill="currentColor"
        d="M99 3.09375C46.0195 3.09375 3.09375 46.0195 3.09375 99C3.09375 151.98 46.0195 194.906 99 194.906C151.98 194.906 194.906 151.98 194.906 99C194.906 46.0195 151.98 3.09375 99 3.09375ZM99 176.344C56.2676 176.344 21.6562 141.732 21.6562 99C21.6562 56.2676 56.2676 21.6562 99 21.6562C141.732 21.6562 176.344 56.2676 176.344 99C176.344 141.732 141.732 176.344 99 176.344ZM122.899 135.97L90.0668 112.11C88.868 111.22 88.1719 109.828 88.1719 108.359V44.8594C88.1719 42.307 90.2602 40.2188 92.8125 40.2188H105.188C107.74 40.2188 109.828 42.307 109.828 44.8594V99.6574L135.661 118.452C137.749 119.96 138.175 122.861 136.666 124.949L129.396 134.965C127.888 137.014 124.987 137.479 122.899 135.97Z"
      />
    </svg>
  );
};

export default Clock;

import { observer } from "mobx-react-lite";
import React, { FC, ReactNode, useContext } from "react";
import { EncoderUiState } from "../../../store/encoder";
import { PlayerUiState } from "../../../store/player";
import { LivelyEncoderUiContext, LivelyPlayerUiContext } from "../../context/Context";
import { mergeStyles } from "../../livelyStyles";
// Styles
import styles, { StyleProps } from "./styles";

/*
 * Component can be passed two props that add specific classes to manage the layout of the control bar.
 * The Variant Prop accepts player, encoder, or mini as string options.
 * The Variant is set to player by default.
 */

export interface ControlBarProps {
  children?: ReactNode;
  classes?: StyleProps;
  variant: "player" | "encoder" | "mini";
}

const ControlBar: FC<ControlBarProps> = observer(({ children, classes = {}, variant, ...rest }) => {
  const ctx =
    useContext<EncoderUiState | null>(LivelyEncoderUiContext) != null
      ? useContext<EncoderUiState | null>(LivelyEncoderUiContext)
      : useContext<PlayerUiState | null>(LivelyPlayerUiContext);

  const mergedClasses = mergeStyles({ source: classes, target: styles }, { stylesNamespace: "controlBar" });

  // Hold container className based on variant
  const containerClasses: { player: string; encoder: string; mini: string } = {
    player: mergedClasses["controls--player"],
    encoder: mergedClasses["controls--encoder"],
    mini: mergedClasses["controls--mini"],
  };

  const handleMouseOver = (): void => {
    if (ctx && !ctx.mobileDevice) {
      ctx.controlMouseOver = true;
    }
  };

  const handleMouseOut = (): void => {
    if (ctx && !ctx.mobileDevice) {
      ctx.controlMouseOver = false;
    }
  };

  return (
    <div
      onMouseOver={() => handleMouseOver()}
      onFocus={() => handleMouseOver()}
      onBlur={() => handleMouseOut()}
      onMouseOut={() => handleMouseOut()}
      style={{ bottom: ctx?.videoMouseOver || ctx?.controlMouseOver ? 0 : -75 }}
      className={`${mergedClasses.root} ${containerClasses[variant]}`}
    >
      <div className={mergedClasses.controls}>{children}</div>
    </div>
  );
});

export default ControlBar;

import { Properties } from "csstype";

type StyleProps = {
  root: Properties;
};

const styles: StyleProps = {
  root: {
    border: "none",
    borderRadius: "30px",
    backgroundColor: "#4293b6",
    color: "white",
    cursor: "pointer",
    fontSize: "14px",
    // Apply this so implementers may use containers and layouts to determine button size, as well as overrides
    height: "auto",
    padding: "10px 20px",
    // Apply this so implementers may use containers and layouts to determine button size, as well as overrides
    width: "100%",
  },
};

export default styles;

import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import DurationLabel, { DurationLabelProps } from "../../../ui-lib/DurationLabel";
import { ErrorBoundary } from "../../ErrorBoundary";

const ModularDurationLabel = observer(({ ...props }: Partial<DurationLabelProps>) => {
  // Needs to be implemented in API
  // const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

  // const hasUndefinedStore: () => boolean = () =>

  //   !!(ctx === undefined || ctx.duration === undefined || ctx.duration.display === undefined);

  // useUndefinedStoreError(hasUndefinedStore, "<DurationLabel/>");

  // if (ctx?.duration?.display) {
  //   return <DurationLabel {...props}>{ctx.duration.display}</DurationLabel>;
  // }
  return <></>;
});

const DurationLabelWithErrorBoundary: FC<Partial<DurationLabelProps>> = ({ ...props }) => {
  return (
    <ErrorBoundary render={() => <DurationLabel {...props}>Duration Unavailable</DurationLabel>}>
      <ModularDurationLabel {...props} />
    </ErrorBoundary>
  );
};

export default DurationLabelWithErrorBoundary;

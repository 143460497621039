import { observer } from "mobx-react-lite";
import React, { ReactElement, useState, useEffect } from "react";
import type { errors } from "@livelyvideo/video-client-core";
import AlertBase, { AlertBaseProps } from "../../../ui-lib/Alerts/AlertBase";
import { ErrorBoundary } from "../../ErrorBoundary";

type ErrorAlertProps = AlertBaseProps & {
    error?: errors.VideoClientError | null;
};

const errorAlertClasses = {
  root: {
    backgroundColor: "#f54248",
    color: "#00000"
  }
}

const ModularErrorAlert = observer(
  ({ error, children, classes, ...props }: Partial<ErrorAlertProps>): React.ReactElement => {
    const [active, setActive] = useState<boolean>(false)

    useEffect(()=> {
      if (error != null) {
        setActive(true);
      }
    },[error])

    if (error == null) {
        return <></>;
    }
     
    const DefaultMessage = () => <p style={{margin:0}}> Error: {error.code} </p>;

    return   (
    <AlertBase classes={classes ? classes : errorAlertClasses} {...props} active={active} setActive={setActive}>
       {children ? children :  <DefaultMessage/>}
    </AlertBase>   
    );

    });

export default ModularErrorAlert;

import { Properties } from "csstype";
import React, { ComponentType, FC } from "react";
import { mergeStyles } from "../../livelyStyles";
import BroadcastIcon from "./Broadcast";
import CameraIcon from "./Camera";
import CameraOffIcon from "./CameraOff";
import ClockIcon from "./Clock";
import Close from "./Close";
import CollapseIcon from "./Collapse";
import ConfigurationIcon from "./Configuration";
import ExpandIcon from "./Expand";
import { IconProps } from "./iconProps";
import MicrophoneIcon from "./Microphone";
import MicrophoneOffIcon from "./MicrophoneOff";
import PauseIcon from "./Pause";
import PlayIcon from "./Play";
import LivelyPlayIcon from "./Player/LivelyPlayIcon";
import RecorderPauseIcon from "./Recorder/Pause";
import RecorderStartIcon from "./Recorder/Start";
import RecordIcon from "./Recorder/Record";
import RecorderDeleteIcon from "./Recorder/Delete";
import RecorderSaveIcon from "./Recorder/Save";
import ScreenCaptureIcon from "./ScreenCapture";
import SettingsIcon from "./Settings";
import SoundIcon from "./Sound";
import SoundOffIcon from "./SoundOff";
import styles, {IconStyleProps} from "./styles";

interface IconComponentProps extends IconProps {
  classes?: IconStyleProps;
  iconName:
    | "broadcast"
    | "camera"
    | "cameraoff"
    | "clock"
    | "close"
    | "configuration"
    | "collapse"
    | "expand"
    | "livelyplay"
    | "microphone"
    | "microphoneoff"
    | "play"
    | "pause"
    | "record"
    | "recorderstart"
    | "recorderpause"
    | "recorderdelete"
    | "recordersave"
    | "screencapture"
    | "settings"
    | "sound"
    | "soundoff";
}

const Icon: ComponentType<IconComponentProps> = ({ classes = {}, title, iconName }) => {
  const ComponentMap: {
    broadcast: FC<IconProps>;
    camera: FC<IconProps>;
    cameraoff: FC<IconProps>;
    clock: FC<IconProps>;
    close: FC<IconProps>;
    configuration: FC<IconProps>;
    collapse: FC<IconProps>;
    expand: FC<IconProps>;
    livelyplay: FC<IconProps>;
    microphone: FC<IconProps>;
    microphoneoff: FC<IconProps>;
    play: FC<IconProps>;
    record: FC<IconProps>;
    recorderstart: FC<IconProps>;
    recorderpause: FC<IconProps>;
    recorderdelete: FC<IconProps>;
    recordersave: FC<IconProps>;
    pause: FC<IconProps>;
    screencapture: FC<IconProps>;
    settings: FC<IconProps>;
    sound: FC<IconProps>;
    soundoff: FC<IconProps>;
  } = {
    broadcast: BroadcastIcon,
    camera: CameraIcon,
    cameraoff: CameraOffIcon,
    clock: ClockIcon,
    close: Close,
    configuration: ConfigurationIcon,
    collapse: CollapseIcon,
    expand: ExpandIcon,
    livelyplay: LivelyPlayIcon,
    microphone: MicrophoneIcon,
    microphoneoff: MicrophoneOffIcon,
    play: PlayIcon,
    recorderstart: RecorderStartIcon,
    recorderpause: RecorderPauseIcon,
    recorderdelete: RecorderDeleteIcon,
    recordersave: RecorderSaveIcon,
    pause: PauseIcon,
    screencapture: ScreenCaptureIcon,
    settings: SettingsIcon,
    sound: SoundIcon,
    soundoff: SoundOffIcon,
    record: RecordIcon,
  };

  const iconClass = iconName.toLowerCase();
  const IconType = ComponentMap[iconName];

  /*
   * This workaround for screencapture styles exists because we have differing icon sizes but for
   * our default styles, we don't want to expose this workaround by creating a new class just for screencapture.
   * This lets us handle the special case while keeping the class structure for implementors as "classes.icon".
   * This is specific to screencapture styles because it's the only icon shaped strangely. If we get an updated
   * asset, we can remove this.
   */
  let screencaptureStyles;

  if (iconClass === "screencapture") {
    screencaptureStyles = { ...styles, icon: { width: "15px " } };
  }

  const mergedClasses = mergeStyles(
    { source: classes, target: screencaptureStyles ? screencaptureStyles : styles },
    { stylesNamespace: "icons" },
  );

  return (
    <span className={`${mergedClasses.root}`}>
      <IconType title={title} className={`${mergedClasses.icon} ${iconClass}`} />
    </span>
  );
};

export default Icon;
export {IconStyleProps};

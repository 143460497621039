import { LoggerCore } from "@livelyvideo/log-client";
import { isIosDevice, isMobileDevice, ObservableEventEmitter, types } from "@livelyvideo/video-client-core";
import { action, makeObservable, observable, runInAction } from "mobx";
import currentPackage from "../../package-json";
import type { EncoderUiState } from "../encoder";
import type { PlayerUiState } from "../player";

interface SetupOptions {
  videoElement?: HTMLVideoElement;
}

interface Document extends HTMLDocument {
  mozCancelFullScreen?: () => void;
  webkitCancelFullScreen?: () => void;
}

interface VideoWrapper extends HTMLDivElement {
  webkitRequestFullScreen: () => void;
  mozRequestFullScreen: () => void;
}

interface WebkitVideoElement extends types.VideoElement {
  webkitEnterFullscreen?: () => void;
  webkitCancelFullScreen?: () => void;
}

export function isWebkitVideoElement(el: WebkitVideoElement): el is WebkitVideoElement {
  let isWebkit = false;
  if (typeof el?.webkitEnterFullscreen === "function") {
    isWebkit = true;
  } else if (typeof el?.webkitCancelFullScreen === "function") {
    isWebkit = true;
  }
  return isWebkit;
}

export interface BaseUiStateEvents {
  /**
   * @description Is emitted when the HTMLVideoElement is set.
   * @example playerUiState.on("videoElement", (el) => { if(el) { // do something} });
   * encoderUiState.on("videoElement", (el) => { if(el) { // do something} })
   */
  videoElement: types.VideoElement | null;

  videoPoster: HTMLElement | null;

  newWindow: null;

  newWindowElement: types.VideoElement | null;

  portal: boolean;

  portalElement: HTMLDivElement | null;

  viewSettings: boolean;

  viewVideoCallSlider: boolean;

  controlMouseOver: boolean;

  videoMouseOver: boolean;
  /**
   * @description Is emitted when the HTMLDivElement that the HTMLVideoElement attaches to is set.
   * @example playerUiState.on("videoWrapperElement", (el) => { if(el) { // do something} });
   * encoderUiState.on("videoWrapperElement", (el) => { if(el) { // do something} })
   */
  videoWrapperElement: HTMLDivElement | null;
  /**
   * @description Is emitted when the player video is/is not in fullscreen mode.
   * @example playerUiState.on("isFullscreen", (bool) => { if(bool) { // use fullscreen styles} });
   * encoderUiState.on("isFullscreen", (bool) => { if(bool) { // use fullscreen styles} })
   */
  isFullscreen: boolean;
  // videoPoster: types.VideoElement | null;
  // newWindow: Window | null;
  // newWindowElement: HTMLDivElement | null;
  // portal: boolean;
  // portalElement: HTMLDivElement | null;
  /**
   * @description Is emitted when screenshare component is supported.
   * @example playerUiState.on("screenShareSupported", (bool) => { if(bool) { // render screenshare button} })
   */
  screenShareSupported: boolean;
  /**
   * @description Is emitted when volumeRange component is supported.
   * @example playerUiState.on("volumeRangeSupported", (bool) => { if(bool) { // render volumeRange component} })
   */
  volumeRangeSupported: boolean;
}

export default class UiState<T = unknown> extends ObservableEventEmitter<BaseUiStateEvents & T> {
  constructor(options?: SetupOptions) {
    super();

    makeObservable(this, {
      // observers
      videoElement: observable.ref,
      videoPoster: observable.ref,
      videoWrapperElement: observable.ref,
      isFullscreen: observable,
      newWindow: observable,
      newWindowElement: observable.ref,
      portal: observable,
      portalElement: observable.ref,
      viewSettings: observable,
      viewVideoCallSlider: observable,
      controlMouseOver: observable,
      videoMouseOver: observable,

      // actions
      toggleFullscreen: action,
    });
  }

  /**
   *  Video element.
   *
   */
  videoElement: types.VideoElement | null = null;

  /**
   * Encoder video poster element.
   *
   */
  videoPoster: HTMLElement | null = null;

  /**
   * Video Container wrapper element.
   *
   */
  videoWrapperElement: VideoWrapper | null = null;

  /**
   * Indicates whether application is fullscreen.
   */
  isFullscreen = false;

  /**
   * Indicates whether the application has been opened in a new window.
   */
  newWindow = null;

  /**
   * New window DOM element
   */
  newWindowElement: types.VideoElement | null = null;

  /**
   * Indicates whether the application has launched in a React portal.
   */
  portal = false;

  /**
   * React Portal DOM element
   */
  // portalElement: document.createElement('div');
  portalElement: HTMLDivElement | null = null;

  /**
   * Indicates whether the encoder settings are visible.
   */
  viewSettings = false;

  /**
   * Indicates whether the call settings are visible.
   */
  viewVideoCallSlider = false;

  /**
   * Watches for mouse over on VideoWrapper
   */
  videoMouseOver = isMobileDevice();

  /**
   * Watches for mouse over on ControlBar
   */
  controlMouseOver = isMobileDevice();

  /**
   * Indicates if we are using a mobile device
   */
  readonly mobileDevice = isMobileDevice();

  /**
   * Indicates if we are using a mobile device
   */
  readonly iosDevice = isIosDevice();

  /**
   * Indicates if screenShare is supported
   */
  readonly screenShareSupported = !isMobileDevice();

  /**
   * Indicates if screenShare is supported
   */
  readonly volumeRangeSupported = !isIosDevice();

  /**
   * Checks to see if we have an even listener already defined so we don't spawn a new one everytime fullScreen is toggled.
   */
  fullScreenEventListener = false;

  toggleFullScreenHelper(): void {
    if (!this.fullScreenEventListener) {
      this.fullScreenEventListener = true;
    }
    if (document.fullscreenElement && !this.isFullscreen) {
      this.isFullscreen = true;
    } else if (!document.fullscreenElement && this.isFullscreen) {
      this.isFullscreen = false;
    }
    if (!document.fullscreenElement) {
      this.fullScreenEventListener = false;
      document.removeEventListener("fullscreenchange", this.toggleFullScreenHelper);
    }
  }

  /**
   * Toggle `isFullscreen`
   * @async
   * @description Toggles the encoder to/from fullscreen and sets uiState.isFullscreen
   * to true/false.
   * @returns Promise
   * @example <caption>Example usage of toggleFullscreen.</caption>
   * toggleFullscreen();
   * // Sets encoder to/from fullscreen and updates uiState.isFullscreen = true|false;
   */
  async toggleFullscreen(): Promise<void> {
    const document: Document = window.document;
    if (!this.fullScreenEventListener) {
      document.addEventListener("fullscreenchange", this.toggleFullScreenHelper);
    }
    if (!this.isFullscreen && document.fullscreenElement == null && this.videoWrapperElement != null) {
      if (navigator.userAgent.indexOf("Chrome") !== -1) {
        await this.videoWrapperElement?.requestFullscreen();
      } else if (navigator.userAgent.indexOf("Safari") !== -1 && !this.iosDevice) {
        this.videoWrapperElement.webkitRequestFullScreen();
      } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        this.videoWrapperElement.mozRequestFullScreen();
      } else if (isIosDevice()) {
        if (this.videoElement && isWebkitVideoElement(this.videoElement) && this.videoElement?.webkitEnterFullscreen) {
          this.videoElement?.webkitEnterFullscreen();
        }

        this.videoElement?.addEventListener("webkitendfullscreen", this.handleiOSFullscreen);
      }

      runInAction(() => {
        this.isFullscreen = true;
      });
    } else {
      if (navigator.userAgent.indexOf("Chrome") !== -1) {
        await document.exitFullscreen();
      } else if (navigator.userAgent.indexOf("Safari") !== -1) {
        document.webkitCancelFullScreen?.();
        this.isFullscreen = false;
      } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        document.mozCancelFullScreen?.();
      }

      runInAction(() => {
        this.isFullscreen = false;
      });
    }
  }

  handleiOSFullscreen(): void {
    if (this.videoElement && isWebkitVideoElement(this.videoElement) && this.videoElement?.webkitCancelFullScreen) {
      this.videoElement?.webkitCancelFullScreen?.();
    }
    this.isFullscreen = false;
    setTimeout(() => {
      this.videoElement?.play();
    }, 500);
    this.videoElement?.removeEventListener("webkitendfullscreen", this.handleiOSFullscreen);
  }

  /**
   * @description Creates a newLogger while keeping the chaining of Meta naming.
   * @returns LoggerCore
   * @deprecated
   */
  newLogger(ctx: PlayerUiState | EncoderUiState, newInstance: string, newContext: any): void {
    console.warn("PlayerUiState.newLogger()/EncoderUiState.newLogger() is deprecated. Pass logger instance into PlayerUiState/EncoderUiState constructor options.")
  }

  openNewWindow(): void {
    // if (this.newWindow == null) {
    //   // if (this.launchNewWindow === true && this.newWindow === null) {
    //   this.newWindow = window.open("", "", "width=600,height=400,left=200,top=200");
    //   const styles = document.querySelectorAll(`[data-meta*='${livelyStylesNamespace}']`);
    //   styles.forEach((e) => {
    //     const node = e.cloneNode(true);
    //     this.newWindow?.document.head.appendChild(node);
    //   });
    //   if (this.newWindowElement != null) {
    //     this.newWindow?.document.body.appendChild(this.newWindowElement);
    //   }
    // }
  }

  destroyNewWindow(): void {
    // if (this.newWindow != null) {
    //   this.newWindow.close();
    //   this.newWindow = null;
    //   this.launchNewWindow = false;
    // }
  }
}

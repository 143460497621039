export type State = "online" | "offline";

export type Manifest = ManifestUri | ManifestJson;

export type ManifestUri = string;

export type ManifestJson = {
  /**
   * URL to the manifest
   */
  self?: string;
  ping?: string;
  type?: string;
  formats: ManifestFormats;
  abr?: string; // assigned border region
  aor?: string; // assigned origin region
  atr?: string; // assigned transcode region
  rep?: boolean; // indicate if already replicated to selected border
  previewImg?: string;
};

export type ManifestFormats = {
  "mp4-rtmp"?: FormatMp4Rtmp;
  "mp4-hls"?: FormatMp4Hls;
  "mp4-ws"?: FormatMp4Ws;
  "flv-http"?: FormatFlvHttp;
  webrtc?: FormatWebRtc;
  jpeg?: FormatJpeg;
};

export type FormatState = {
  type?: string;
  origin?: unknown;
  encodings: Encoding[];
  audioCodec?: string;
  videoCodec?: string;
  manifest?: string;
  auto: boolean;
  driver: string;
  state: State;
};

export type FormatMp4Rtmp = {
  origin?: Partial<Encoding> & { origin?: boolean };
  videoCodec: string;
  audioCodec: string;
  encodings: Encoding[];
};

export type FormatMp4Hls = {
  manifest: string;
  origin?: Partial<Encoding> & { origin?: boolean };
  videoCodec: string;
  audioCodec: string;
  encodings: Encoding[];
};

export type FormatMp4Ws = {
  origin?: Partial<Encoding> & { origin?: boolean };
  videoCodec: string;
  audioCodec: string;
  encodings: Encoding[];
};

export type FormatFlvHttp = {
  origin?: Partial<Encoding> & { origin?: boolean };
  videoCodec: string;
  audioCodec: string;
  encodings: Encoding[];
};

export type FormatWebRtc = {
  origin: {
    origin?: boolean;
    location: string;
    callId: string;
    peerId: string;
    streamNames: string[];
    token: string;
    rsrc?: string;
    publicKey: string;
    uri?: string;
    httpUri?: string;
    region: string;
    version?: string;
    turn: {
      servers: Array<{
        urls: string[];
      }>;
    };
  };
};

export type FormatJpeg = {
  origin?: Partial<Encoding> & { origin?: boolean };
  encodings: Encoding[];
};

export type Encoding = {
  location: string;
  audioPts?: number;
  videoPts?: number;
  videoWidth: number;
  videoHeight: number;
  audioKbps?: number;
  videoKbps?: number;
  audioCodec?: string;
  videoCodec?: string;
  fps?: number;
  channels?: string[];
  collected?: number;
  kbps?: number;
};

export function isManifest(object: unknown): object is Manifest {
  if (object == null) {
    return false;
  }

  if (typeof object === "string") {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const url = new URL(object);
      return true;
    } catch (err) {
      return false;
    }
  }

  if (typeof object === "object") {
    return "formats" in (object ?? {});
  }

  return false;
}

export function isWebrtcFormat(val: unknown): val is FormatWebRtc {
  const obj = val as FormatWebRtc;
  return typeof obj === "object" && typeof obj.origin === "object" && typeof obj.origin.callId === "string";
}

export function isGenericFormat(val: unknown): val is FormatMp4Hls {
  const obj = val as FormatMp4Hls;
  return typeof obj === "object" && Array.isArray(obj.encodings);
}

export function isValidFormat(val: unknown): boolean {
  return isWebrtcFormat(val) || isGenericFormat(val);
}

import { LoggerCore } from "@livelyvideo/log-client";
import { device } from "../../api/adapter";
import { MediaStream } from "../../api/adapter/features/media-stream";
import { Encoding } from "../../api/manifest";
import {
  BitrateLayer,
  Quality,
  SourceScoreLevel,
  TranscodeScoreLevel,
} from "../../api/player/features/bitrate-switching";
import { VideoElement } from "../../api/typings/video-element";
import { dumpVideoElement } from "../utils/debug/play-logs";

// a workaround for mediasoup3 bug when
// ms3 creates consumer in an invalid state
// if remote track was disabled at start
function fixMS3ServerBug(el: VideoElement): void {
  const src = el.srcObject;
  if (typeof src === "object" && src != null && "getTracks" in src) {
    const stream = el.srcObject as MediaStream;
    stream.getTracks().forEach((tr) => {
      // if muted and enabled are true then it means
      // this track is enabled but it didn't get any frames yet
      // so if we didn't get any frames for 5000 ms that means
      // it was disabled in remote producer
      if (tr.muted && tr.enabled) {
        // so disable it (as it should be at start)
        // and trigger loading again
        tr.enabled = false;
      }
    });
  }

  if (el.load != null) {
    el.load();
  } else {
    el.play();
  }
}

export function playOncePossible(el: VideoElement, logger: LoggerCore): Promise<void> {
  logger.debug("playOncePossible()", { aggregates: dumpVideoElement(el) });

  if (!el.paused && !el.ended) {
    return Promise.resolve();
  }

  if (el.readyState > 2) {
    return el.play();
  }

  const fixMS3Timeout = device.setTimeout(fixMS3ServerBug.bind(null, el), 5000);

  return new Promise<void>((resolve, reject) => {
    logger.debug("playOncePossible() returned Promise", { aggregates: dumpVideoElement(el) });
    const onReady = (): void => {
      if (el.readyState > 2) {
        device.clearTimeout(fixMS3Timeout);
        el.removeEventListener("loadeddata", onReady);
        el.play()
          .catch(reject)
          .then(() => {
            if (!el.paused) {
              resolve();
              return;
            }

            // if it's still paused then we'll check
            // this status for ~1sec every 50ms
            let attempts = 20;
            const n = device.setInterval(() => {
              if (!el.paused) {
                device.clearInterval(n);
                resolve();
              }
              if (--attempts === 0) {
                device.clearInterval(n);
                reject(new Error("Video element remains paused for 1s after play() call"));
              }
            }, 50);
          });
      }
    };

    el.addEventListener("loadeddata", onReady);
  });
}

export function isBitrateLayer(obj: Encoding | BitrateLayer): obj is BitrateLayer {
  return "id" in obj && "bitrate" in obj && "isSource" in obj;
}

export function sortEncodings(encodings: (Encoding | BitrateLayer)[]): (Encoding | BitrateLayer)[] {
  return [...encodings].sort((a, b) => {
    const aBitrate = isBitrateLayer(a) ? a.bitrate ?? a.maxBitrate : (a.audioKbps ?? 0) + (a.videoKbps ?? 0);
    const bBitrate = isBitrateLayer(b) ? b.bitrate ?? b.maxBitrate : (b.audioKbps ?? 0) + (b.videoKbps ?? 0);

    // that means we don't know bitrate so assume
    // it's the source with the highest bitrate
    if (aBitrate === 0) {
      return -1;
    }

    return bBitrate - aBitrate;
  });
}

export function encodingToLayer(encoding: Encoding | BitrateLayer): BitrateLayer {
  if (isBitrateLayer(encoding)) {
    return {
      id: encoding.id,
      bitrate: encoding.bitrate ?? encoding.maxBitrate,
      isSource: encoding.isSource ?? false,
      appData: encoding.appData ?? {},
    };
  }

  const layer: BitrateLayer = {
    id: encoding.location,
    bitrate: (encoding.audioKbps ?? 0) + (encoding.videoKbps ?? 0),
    isSource: false,
    appData: {},
  };

  if (layer.appData != null && encoding.videoWidth && encoding.videoHeight) {
    layer.appData.videoWidth = encoding.videoWidth;
    layer.appData.videoHeight = encoding.videoHeight;
  }

  return layer;
}

export function fetchManifestQualities(encodings: (Encoding | BitrateLayer)[], origin: string | null): Quality[] {
  const enc = sortEncodings(encodings);
  const qty: Quality[] = [];
  let sourceLow = false;
  let sourceMed = false;

  const numEncodings = enc.length > 7 ? 7 : enc.length;

  let amount = numEncodings;

  if (origin != null) {
    qty.push({
      level: SourceScoreLevel.High,
      layer: { bitrate: 0, isSource: true, id: origin },
    });
  } else {
    let sourceLayers = [];
    for (let i = 0; i < numEncodings; i++) {
      if (encodingToLayer(enc[i]).isSource) {
        sourceLayers.push(encodingToLayer(enc[i]));
      }
    }
    if (sourceLayers.length > 1) {
      sourceLayers.sort((a, b) => (a.bitrate > b.bitrate) ? 1 : -1)
      for (let i = 0; i < sourceLayers.length; i++){
        if (!sourceLow) {
          qty.push({ level: SourceScoreLevel.Low, layer: sourceLayers[i] });
          sourceLow = true;
          amount -= 1;
        } else if (!sourceMed) {
          qty.push({ level: SourceScoreLevel.Medium, layer: sourceLayers[i] });
          sourceMed = true;
          amount -= amount;
        } else {
          qty.push({ level: SourceScoreLevel.High, layer: sourceLayers[i] });
          amount -= amount;
        }
      }
    } else if (sourceLayers.length > 0) {
       qty.push({ level: SourceScoreLevel.Low, layer: encodingToLayer(sourceLayers[0]) });
    }
  }

  switch (amount) {
    case 0:
      break;
    case 1:
      qty.push({ level: TranscodeScoreLevel.Medium, layer: encodingToLayer(enc[0]) });
      break;
    case 2:
      qty.push({ level: TranscodeScoreLevel.Low, layer: encodingToLayer(enc[1]) });
      qty.push({ level: TranscodeScoreLevel.High, layer: encodingToLayer(enc[0]) });
      break;
    case 3:
      qty.push({ level: TranscodeScoreLevel.Low, layer: encodingToLayer(enc[2]) });
      qty.push({ level: TranscodeScoreLevel.Medium, layer: encodingToLayer(enc[1]) });
      qty.push({ level: TranscodeScoreLevel.High, layer: encodingToLayer(enc[0]) });
      break;
    case 4:
      qty.push({ level: TranscodeScoreLevel.Low, layer: encodingToLayer(enc[3]) });
      qty.push({ level: TranscodeScoreLevel.MediumLow, layer: encodingToLayer(enc[2]) });
      qty.push({ level: TranscodeScoreLevel.MediumHigh, layer: encodingToLayer(enc[1]) });
      qty.push({ level: TranscodeScoreLevel.High, layer: encodingToLayer(enc[0]) });
      break;
    case 5:
      qty.push({ level: TranscodeScoreLevel.Low, layer: encodingToLayer(enc[4]) });
      qty.push({ level: TranscodeScoreLevel.MediumLow, layer: encodingToLayer(enc[3]) });
      qty.push({ level: TranscodeScoreLevel.Medium, layer: encodingToLayer(enc[2]) });
      qty.push({ level: TranscodeScoreLevel.MediumHigh, layer: encodingToLayer(enc[1]) });
      qty.push({ level: TranscodeScoreLevel.High, layer: encodingToLayer(enc[0]) });
      break;
    case 6:
      qty.push({ level: TranscodeScoreLevel.Lowest, layer: encodingToLayer(enc[5]) });
      qty.push({ level: TranscodeScoreLevel.Low, layer: encodingToLayer(enc[4]) });
      qty.push({ level: TranscodeScoreLevel.MediumLow, layer: encodingToLayer(enc[3]) });
      qty.push({ level: TranscodeScoreLevel.MediumHigh, layer: encodingToLayer(enc[2]) });
      qty.push({ level: TranscodeScoreLevel.High, layer: encodingToLayer(enc[1]) });
      qty.push({ level: TranscodeScoreLevel.Highest, layer: encodingToLayer(enc[0]) });
      break;
    default:
      qty.push({ level: TranscodeScoreLevel.Lowest, layer: encodingToLayer(enc[6]) });
      qty.push({ level: TranscodeScoreLevel.Low, layer: encodingToLayer(enc[5]) });
      qty.push({ level: TranscodeScoreLevel.MediumLow, layer: encodingToLayer(enc[4]) });
      qty.push({ level: TranscodeScoreLevel.Medium, layer: encodingToLayer(enc[3]) });
      qty.push({ level: TranscodeScoreLevel.MediumHigh, layer: encodingToLayer(enc[2]) });
      qty.push({ level: TranscodeScoreLevel.High, layer: encodingToLayer(enc[1]) });
      qty.push({ level: TranscodeScoreLevel.Highest, layer: encodingToLayer(enc[0]) });
      break;
  }

  return qty;
}

export function findClosestQuality(
  score: TranscodeScoreLevel | SourceScoreLevel,
  qualities: Quality[],
): Quality | null {
  const mapQualities: Record<string, Quality> = Object.fromEntries(qualities.map((q) => [q.level, q]));

  if (score === SourceScoreLevel.High) {
    if (mapQualities[score] != null) {
      return mapQualities[score];
    }

    score = SourceScoreLevel.High;
  }
  if (score === SourceScoreLevel.Medium) {
    if (mapQualities[score] != null) {
      return mapQualities[score];
    }

    score = SourceScoreLevel.Medium;
  }
  if (score === SourceScoreLevel.Low) {
    if (mapQualities[score] != null) {
      return mapQualities[score];
    }

    score = SourceScoreLevel.Low;
  }
  let qty: Quality | null = null;
  switch (score) {
    case TranscodeScoreLevel.Lowest:
      if (qty == null) {
        qty = mapQualities[score];
        score = TranscodeScoreLevel.Low;
      }
      break;
    case TranscodeScoreLevel.Low:
      if (qty == null) {
        qty = mapQualities[score];
        score = TranscodeScoreLevel.MediumLow;
      }
      break;
    case TranscodeScoreLevel.MediumLow:
      if (qty == null) {
        qty = mapQualities[score];
        score = TranscodeScoreLevel.Medium;
      }
      break;
    case TranscodeScoreLevel.Medium:
      if (qty == null) {
        qty = mapQualities[score];
        score = TranscodeScoreLevel.MediumHigh;
      }
      break;
    case TranscodeScoreLevel.MediumHigh:
      if (qty == null) {
        qty = mapQualities[score];
        score = TranscodeScoreLevel.High;
      }
      break;
    case TranscodeScoreLevel.High:
      if (qty == null) {
        qty = mapQualities[score];
        score = TranscodeScoreLevel.Highest;
      }
      break;
    default:
      break;
  }

  return qty;
}

export const ERRORS = {
  BAD_INPUT: "bad-input",
  DRIVER_NOT_SUPPORTED: "driver-not-supported",
  ELEMENT_REQUIRED: "element-required",
  EMBED_SWF_FAILED: "embedding-flash-swf-failed",
  GET_USER_MEDIA_FAILED: "get-user-media-failed",
  HTTP_SERVER_UNEXPECTED_RESPONSE: "http-server-unexpected-response",
  HTTP_SERVER_UNAUTHORIZED: "http-server-unauthorized",
  HTTP_SERVER_FORBIDDEN: "http-server-forbidden",
  HTTP_SERVER_INTERNAL_ERROR: "http-server-internal-error",
  HTTP_SERVER_NOT_FOUND: "http-server-not-found",
  MANIFEST: "http-response",
  INVALID_CONTROLS: "invalid-controls-parent",
  INVALID_MEDIA_URL: "invalid-media-url",
  INVALID_POPOUT_URL: "invalid-popout-url",
  INVALID_EL: "invalid-element",
  WS_NETWORK_ERROR: "websocket-network-error",
  NETWORK_ERROR: "network-error",
  NO_DRIVERS: "no-valid-drivers",
  PLAYBACK_ERROR: "playback-error",
  UNKNOWN_DRIVER: "unknown-driver",
  UNKNOWN_ERROR: "unknown-error",
  UNRECOGNIZED_DRIVER: "unrecognized-driver",
  USER_REQUIRED: "user-required",
  INVALD_BITRATE: "invalid-bitrate",
  HLSJS_NOT_LOADED: "hlsjs-not-loaded",
};

export function isFatalError(err: unknown): boolean {
  if (err && typeof err === "object" && "fatal" in err) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (err as any).fatal === true;
  }
  return false;
}

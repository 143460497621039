/* eslint-disable no-useless-constructor */

import { EventEmitter } from "@livelyvideo/events-typed";
import { Disposable } from "../../../api";

export type CollectableMap = {
  websocket: WebSocket;
};

type Meta = Record<string, string | number | boolean>;

export type CollectableClasses = keyof CollectableMap;

export type InstanceCollectorOptions = {
  classes: Array<CollectableClasses>;
};

interface CollectorMap {
  clear(): void;
  delete(key: CollectableClasses): boolean;
  forEach<K extends CollectableClasses>(
    callbackfn: (value: Set<CollectableMap[K]>, key: K, map: Map<K, Set<CollectableMap[K]>>) => void,
    thisArg?: any,
  ): void;
  get<K extends CollectableClasses>(key: K): Set<CollectableMap[K]>;
  has(key: CollectableClasses): boolean;
  set<K extends CollectableClasses>(key: K, value: Set<CollectableMap[K]>): this;
  readonly size: number;
}

export class InstanceCollector extends EventEmitter<{ disposed: void }> implements Disposable {
  private static readonly instances = new Set<InstanceCollector>();

  readonly objects: CollectorMap = new Map();

  readonly metas = new WeakMap<object, Meta>();

  isDisposed = false;

  constructor(private readonly options: InstanceCollectorOptions) {
    super();

    InstanceCollector.instances.add(this);
    for (const type of options.classes) {
      this.objects.set(type, new Set());
    }
  }

  private doesCollect(type: CollectableClasses): boolean {
    return this.options.classes.includes(type);
  }

  private insertObject<T extends CollectableClasses>(type: T, object: CollectableMap[T], meta?: Meta): void {
    this.objects.get(type).add(object);
    if (meta != null) {
      this.metas.set(object, meta);
    }

    if (object instanceof EventEmitter) {
      object.once("disposed", () => this.objects.get(type).delete(object));
    }
  }

  private removeObject<T extends CollectableClasses>(type: T, object: CollectableMap[T]): void {
    this.objects.get(type)?.delete(object);
    this.metas.delete(object);
  }

  static reportNewInstance<T extends CollectableClasses>(type: T, object: CollectableMap[T], meta?: Meta): void {
    for (const collector of InstanceCollector.instances.values()) {
      if (collector.doesCollect(type)) {
        collector.insertObject(type, object, meta);
      }
    }
  }

  static disposeInstance<T extends CollectableClasses>(type: T, object: CollectableMap[T]): void {
    for (const collector of InstanceCollector.instances.values()) {
      collector.removeObject(type, object);
    }
  }

  getObjects<T extends CollectableClasses>(type: T): Array<[CollectableMap[T], Meta]> {
    if (!this.objects.has(type)) {
      return [];
    }

    return Array.from(this.objects.get(type).values()).map((v) => {
      return [v, this.metas.get(v) ?? {}];
    });
  }

  dispose(reason?: string): void {
    this.isDisposed = true;
    InstanceCollector.instances.delete(this);
    this.emit("disposed");
  }
}

if (typeof global === "object" && global != null) {
  (global as Record<string, unknown>)._VDC_InstanceCollector = InstanceCollector;
}

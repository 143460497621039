import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { PlayerUiState } from "../../../../../store";
import { LivelyPlayerUiContext } from "../../../../context";
import Range, { RangeProps } from "../../../../ui-lib/Inputs/Range";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

const ModularVolumeRange = observer(({ classes, ...props }: Partial<RangeProps>) => {
  const componentName = "<VolumeRange/>";

  const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);

  const rangeRef = React.createRef<HTMLInputElement>();

  const hasUndefinedStore: () => boolean = () =>
    ctx?.player === undefined || ctx?.player?.localAudioVolume === undefined;

  useUndefinedStoreError(hasUndefinedStore, componentName);

  const modifyVolume: () => void = () => {
    if (rangeRef?.current?.value != null) {
      if (ctx?.player != null) {
        ctx.player.localAudioMuted = false;
        ctx.player.localAudioVolume = Number(rangeRef.current.value);
      }
    }
  };

  const handleInputChange = useUIEventError(modifyVolume, componentName);
  // I had to flip the boolean on the disabled button because it was making the button not work (originally !player?.remoteAudioEnabled)//

  return ctx?.volumeRangeSupported ? (
    <Range
      {...props}
      classes={classes}
      ref={rangeRef}
      onInput={handleInputChange}
      onChange={handleInputChange}
      value={ctx?.player.localAudioVolume}
      // disabled={player && !player.remoteAudioEnabled}
    />
  ) : null;
});

const VolumeRangeWithErrorBoundary: FC<Partial<RangeProps>> = ({ classes, ...props }) => {
  return (
    <ErrorBoundary render={() => <Range classes={classes} {...props} disabled />}>
      <ModularVolumeRange classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default VolumeRangeWithErrorBoundary;

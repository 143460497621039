import type { IEventEmitter } from "@livelyvideo/events-typed";
import { Json } from "@livelyvideo/log-node";

export enum TranscodeScoreLevel {
  Lowest = "transcode:lowest",
  Low = "transcode:low",
  MediumLow = "transcode:medium-low",
  Medium = "transcode:medium",
  MediumHigh = "transcode:medium-high",
  High = "transcode:high",
  Highest = "transcode:highest",
}

export enum SourceScoreLevel {
  Low = "source:low",
  Medium = "source:medium",
  High = "source:high",
}

export type BitrateLayer = {
  readonly isSource: boolean;
  readonly bitrate: number;
  readonly appData?: Record<string, Json>;

  /**
   * @deprecated Use `bitrate` instead
   */
  readonly maxBitrate?: number;

  readonly id: string | number;
};

export type Quality = {
  level: TranscodeScoreLevel | SourceScoreLevel;
  layer: BitrateLayer;
};

export interface BitrateSwitchingEvents {
  /**
   * @description Is emitted when the array of availableQualities is set/changes.
   * @example player.on("availableQualities", (arr) => { // display available qualities})
   */
  availableQualities: Quality[];
  /**
   * @description Is emitted when the currentQuality is updated.
   * @example player.on("currentQuality", (val) => { // handle quality update})
   */
  currentQuality: Quality | null;

  /**
   * @deprecated Use `currentQuality` instead
   */
  activeLayer: BitrateLayer | null;

  /**
   * @deprecated Use `availableQualities` instead
   */
  layers: BitrateLayer[];
}

export interface BitrateSwitchingFeature<E = unknown> extends IEventEmitter<BitrateSwitchingEvents & E> {
  readonly availableQualities: Quality[];
  readonly currentQuality: Quality | null;

  setPreferredLevel(level: TranscodeScoreLevel | SourceScoreLevel): void;
}

import { isMediaStream, VideoElement } from "../../../api/typings/video-element";

type AggregateLogs = {
  playDebugHasSrcObject?: boolean;
  playDebugHasAudioTrack?: boolean;
  playDebugHasVideoTrack?: boolean;
  playDebugMuted?: boolean;
  playDebugPaused?: boolean;
  playDebugHeight?: number;
  playDebugWidth?: number;
  playDebugAutoplay?: boolean;
  playDebugReadyState?: number;
};

export function dumpVideoElement(videoEl?: VideoElement): AggregateLogs {
  if (videoEl == null) {
    return {};
  }

  const videoLogs: AggregateLogs = {
    playDebugHasSrcObject: videoEl.srcObject != null,
    playDebugMuted: videoEl.muted,
    playDebugPaused: videoEl.paused,
    playDebugHeight: videoEl.videoHeight,
    playDebugWidth: videoEl.videoWidth,
    playDebugAutoplay: videoEl.autoplay,
    playDebugReadyState: videoEl.readyState,
  };

  if (isMediaStream(videoEl.srcObject)) {
    videoLogs.playDebugHasAudioTrack = videoEl.srcObject?.getAudioTracks().length > 0;
    videoLogs.playDebugHasVideoTrack = videoEl.srcObject?.getVideoTracks().length > 0;
  }

  return videoLogs;
}

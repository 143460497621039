import { Auth } from "../../../api";

export default (auth: Auth): Promise<string> =>
  new Promise((resolve, reject) => {
    auth.request((err, token) => {
      if (err != null || token == null) {
        reject(err);
        return;
      }
      resolve(token);
    });
  });

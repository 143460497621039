/* eslint-disable dot-notation */
import { IEventEmitter } from "@livelyvideo/events-typed";

export const LEVELS = [
  "trace",
  "debug",
  "network",
  "timing",
  "local",
  "info",
  "warn",
  "notice",
  "deprecated",
  "error",
  "fatal",
] as const;

export const GlobalMetaKeys = ["source", "logId", "userAgent", "referrer", "loggerVer"] as const;
export const PackageMetaKeys = ["package", "component", "service", "release", "chain", "client", "contextId", "instanceId", "commitHash"] as const;

export type Level = typeof LEVELS[number];

export interface ClientBody {
  context: ClientContext;
  logs: ClientLog[];
  sendTime: string;
}

export interface ClientContext {
  globalContext?: ClientGlobalContext;
  packageContexts?: ClientPackageContext;
}

export interface ClientGlobalContext {
  aggregates?: Aggregates;
  meta: GlobalMeta;
}

export interface ClientPackageContext {
  aggregates?: Aggregates;
  meta?: PackageMeta;
}

export interface ClientMessageContext {
  aggregates?: Aggregates;
}

export type ClientLog = LogData & {
  message: string;
  level: Level;
  time: string;
};

export type GlobalMeta = {
  [x in typeof GlobalMetaKeys[number]]: string;
};
export type PackageMeta = {
  [x in typeof PackageMetaKeys[number]]?: string;
};
export type Meta = {
  [x in typeof GlobalMetaKeys[number] | typeof PackageMetaKeys[number]]?: string;
};

export type Aggregates = Record<string, string | number | boolean>;

export type Json = string | number | boolean | null | undefined | Json[] | { [key: string]: Json } | { toJSON(): Json };

export type RawLogData = {
  aggregates?: Aggregates;
  [key: string]: Json;
};

export type LogData = RawLogData | Serializable;

export interface Loggable {
  message: string;
  level: Level;
  aggregates?: Aggregates;
  meta?: Meta;
  time: string;
}

export type Logger = Record<Level, LogFunction>;

export type LogFunction = (msg: string | Error, logObject?: LogData) => void;
export type PrintFunction = (msg: string | Error, logObject?: LogData, agg?: Aggregates) => void;

export interface Disposable extends IEventEmitter<{ disposed: void }> {
  readonly isDisposed: boolean;

  dispose(reason?: string): void;
}

export interface Serializable {
  toJSON(): Json;
}

export function isSerializableObject(val: unknown): val is Serializable {
  return (
    val != null &&
    typeof val === "object" &&
    !Array.isArray(val) &&
    "toJSON" in val &&
    typeof val["toJSON"] === "function"
  );
}

export function isPlainRecord(val: unknown): val is Record<string, number | boolean | string> {
  if (val == null || typeof val !== "object" || Array.isArray(val) || isSerializableObject(val)) {
    return false;
  }

  return !Object.values(val).some((v) => v != null && !["number", "string", "boolean"].includes(typeof v));
}

export function extractAggregates(val: Serializable | null | undefined, ...exclude: string[]): Aggregates {
  if (!isSerializableObject(val)) {
    return {};
  }

  const obj = val.toJSON();
  if (obj == null || typeof obj !== "object" || Array.isArray(obj) || isSerializableObject(obj)) {
    return {};
  }

  const aggregates = obj["aggregates"];
  if (!isPlainRecord(aggregates)) {
    return {};
  }

  for (const key of exclude) {
    delete aggregates[key];
  }

  return aggregates;
}

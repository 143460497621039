import React, { FC } from "react";
import { IconProps } from "../iconProps";

const FullscreenExit: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="fullscreenexit"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      {/* Generated by IcoMoon.io // Material Icons v3 */}
      <path
        fill="currentColor"
        d="M31.969 16.031h6v3.938h-9.938v-9.938h3.938v6zM28.031 37.969v-9.938h9.938v3.938h-6v6h-3.938zM16.031 16.031v-6h3.938v9.938h-9.938v-3.938h6zM10.031 31.969v-3.938h9.938v9.938h-3.938v-6h-6z"
      />
    </svg>
  );
};

export default FullscreenExit;

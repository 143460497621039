import { RootStyles, StylesObject } from "../typings/css";

interface PlayerOverlayButtonStyles extends RootStyles {
  rootDisabled: StylesObject;
  livelyPlayIcon?: StylesObject;
}

const styles: PlayerOverlayButtonStyles = {
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.33)",
    display: "flex",
    position: "absolute",
    top: 0,
    bottom: 0,
    width: "100%",
    zIndex: "120",
    "&:before": {
      backgroundSize: "25%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      content: "''",
      cursor: "pointer",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  rootDisabled: {
    "&::before": {
      cursor: "default",
    },
  },
  livelyPlayIcon: {
    icon: {
      width: "30%",
      color: "white"
    },
  }
};

export default styles;

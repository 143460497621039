import React, { FC } from "react";
import { IconProps } from "../iconProps";

const LaunchOpen: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="launchopen"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      {/* Generated by IcoMoon.io // Material Icons v3 */}
      <path
        fill="currentColor"
        d="M28.031 6h13.969v13.969h-4.031v-7.125l-19.594 19.594-2.813-2.813 19.594-19.594h-7.125v-4.031zM37.969 37.969v-13.969h4.031v13.969q0 1.594-1.219 2.813t-2.813 1.219h-27.938q-1.688 0-2.859-1.172t-1.172-2.859v-27.938q0-1.688 1.172-2.859t2.859-1.172h13.969v4.031h-13.969v27.938h27.938z"
      />
    </svg>
  );
};

export default LaunchOpen;

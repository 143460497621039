import { Properties, PropertiesHyphen } from "csstype";
import { RootStyles, StylesObject } from "../typings/css";

export interface StyleProps extends RootStyles {
  controls?: StylesObject;
  "controls--player"?: StylesObject;
  "controls--encoder"?: StylesObject;
  "controls--mini"?: StylesObject;
  "video::-webkit-media-controls"?: Properties | PropertiesHyphen;
  "video::-moz-media-controls"?: Properties | PropertiesHyphen;
}

const styles: StyleProps = {
  root: {
    width: "100%",
    zIndex: 130,
    position: "absolute",
    transition: "bottom 0.4s ease-in-out",
  },
  controls: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
      "linear-gradient(rgba(0,0,0,0), rgba(0, 0, 0, 0.05) 5%, rgba(0, 0, 0, 0.10) 10%, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.20) 25%, rgba(0,0,0,0.40) 50%, rgba(0, 0, 0, 0.60) 75%, rgba(0,0,0,0.80))",
    opacity: 1,
    padding: "1.25rem 0 0.5rem 0",
    transition: "opacity 300ms ease-out, bottom 0.3s ease-in-out",

    // Allow buttons that are children of the lv-controls div to not be hidden by the encoder video
    "& button": {
      display: "block",
    },
    "&.lv-controls--hidden": {
      opacity: 0,
      transitionDelay: "500ms",
    },
  },
  "controls--mini": {},
  // @todo: remove this in future.
  // Currently this is a back up in case a video element has it's controls.
  "video::-webkit-media-controls": {
    display: "none!important",
    "-webkit-appearance": "none",
  },

  "video::-moz-media-controls": {
    display: "none!important",
    "-webkit-appearance": "none",
  },
};

export default styles;

import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../../../store";
import { LivelyEncoderUiContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon, { IconStyleProps } from "../../../../../ui-lib/Icons";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface CameraButtonClasses extends RootStyles {
  icon?: IconStyleProps;
}

interface CameraButtonProps extends Partial<ButtonIconProps> {
  classes?: CameraButtonClasses;
}

const ModularCameraButton = observer(
  ({
    icon: ProvidedIcon,
    onClick,
    active,
    classes = {
      root: {},
      icon: {},
    },
    screenReaderText,
    label = "Toggle camera",
    ...props
  }: CameraButtonProps) => {
    /**
     * Component Name (for error msg)
     */
    const componentName = "<CameraButton/>";

    /**
     * Access LivelyEncoderUiContext & destructure API state
     */
    const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);
    /**
     * Assess conditions for an undefined store.
     * @returns {boolean}
     * */
    const hasUndefinedStore: () => boolean = () => ctx?.mediaStreamController == null;

    /**
     * Throw error (and trigger ErrorBoundary) if store is undefined.
     * */
    useUndefinedStoreError(hasUndefinedStore, componentName);

    const toggleCamera = (): void => {
      if (ctx?.mediaStreamController?.videoDisabled != null) {
        ctx.mediaStreamController.videoDisabled = !ctx?.mediaStreamController?.videoDisabled;
      }
    };

    /**
     * handleClick function wrapped in global Error handler (to trigger ErrorBoundary).
     * */
    const handleClick = onClick ?? useUIEventError(toggleCamera, componentName);

    const activeState = active ?? !ctx?.mediaStreamController?.videoDisabled;

    const icon = activeState ? (
      <Icon iconName="camera" classes={classes?.icon} />
    ) : (
      <Icon iconName="cameraoff" classes={classes?.icon} />
    );

    return (
      <ButtonIcon
        active={activeState}
        data-selenium="camera-toggle-button"
        icon={ProvidedIcon ?? icon}
        onClick={handleClick}
        classes={classes}
        label={label}
        disabled={ctx?.mediaStreamController?.videoDeviceId != null && ctx.mediaStreamController?.videoDeviceId == null}
        {...props}
      >
        {screenReaderText ?? `Click to turn Camera Stream ${activeState ? "Off" : "On"}`}
      </ButtonIcon>
    );
  },
);

/**
 *
 * @visibleName Camera Button
 */
const CameraButtonWithErrorBoundary: FC<Partial<ButtonIconProps>> = ({
  icon: ProvidedIcon,
  classes,
  label = "Toggle camera",
  screenReaderText,
  ...props
}: CameraButtonProps) => {
  const icon = ProvidedIcon ?? <Icon iconName="camera" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          data-selenium="camera-toggle-button"
          disabled
          classes={classes}
          icon={icon}
          label={label}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to turn Camera Stream On"}
        </ButtonIcon>
      )}
    >
      <ModularCameraButton
        classes={classes}
        icon={ProvidedIcon}
        label={label}
        screenReaderText={screenReaderText}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default CameraButtonWithErrorBoundary;

import React, { FC } from "react";
import { IconProps } from "../iconProps";

const StreamDelay: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="delaystream"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      {/* Generated by IcoMoon.io // Material Icons v3 */}
      <path
        fill="currentColor"
        d="M24 31.969q-1.594 0-2.813-1.172t-1.219-2.766q0-1.688 1.219-2.906l16.969-11.25-11.344 16.969q-1.125 1.125-2.813 1.125zM40.781 17.156q1.313 2.063 2.25 5.25t0.938 5.625q0 5.531-2.625 9.938-1.219 2.063-3.469 2.063h-27.75q-2.25 0-3.469-2.063-2.625-4.406-2.625-9.938 0-8.25 5.859-14.109t14.203-5.859q2.438 0 5.578 0.938t5.203 2.25l-3.75 2.438q-3.375-1.688-7.125-1.688-6.563 0-11.297 4.688t-4.734 11.344q0 4.313 2.156 7.969h27.75q2.156-3.656 2.156-7.969 0-3.844-1.688-7.219z"
      />
    </svg>
  );
};

export default StreamDelay;

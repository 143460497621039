import React, { FC } from "react";
import { IconProps } from "./iconProps";

const MicrophoneOff: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="microphone-off"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <title>{rest.title}</title>
      <path
        fill="currentColor"
        d="M181.397 151.912L140.483 120.585C143.748 114.417 145.664 107.451 145.664 99.9996V87.6701C145.664 85.3995 143.807 83.5603 141.515 83.5603H137.367C135.075 83.5603 133.218 85.3995 133.218 87.6701V99.9996C133.218 104.603 132.192 108.938 130.439 112.894L123.555 107.623C124.359 105.209 124.924 102.686 124.924 100.002V58.9014C124.924 45.2825 113.78 34.2426 100.033 34.2426C86.2863 34.2426 75.1425 45.2825 75.1425 58.9014V70.5528L28.8509 35.1082C27.0411 33.716 24.4328 34.0371 23.0275 35.83L17.9353 42.3209C16.53 44.1112 16.8541 46.6927 18.6639 48.0875L171.213 164.891C173.023 166.286 175.628 165.962 177.036 164.169L182.129 157.678C183.531 155.888 183.207 153.306 181.397 151.912ZM120.773 153.427H106.253V144.753C109.276 144.342 112.178 143.587 114.983 142.618L101.991 132.67C100.251 132.773 98.5138 132.894 96.7145 132.721C82.2338 131.322 71.1133 120.23 67.8879 106.56L54.3976 96.2263V97.9858C54.3976 121.011 70.9837 141.537 93.8079 144.655V153.427H79.2883C76.9963 153.427 75.1399 155.266 75.1399 157.537V161.647C75.1399 163.917 76.9963 165.757 79.2883 165.757H120.773C123.065 165.757 124.921 163.917 124.921 161.647V157.537C124.921 155.266 123.065 153.427 120.773 153.427Z"
      />
    </svg>
  );
};

export default MicrophoneOff;

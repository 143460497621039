import { RootStyles } from "../typings/css";

const styles: RootStyles = {
  root: {
    alignContent: "center",
    backgroundColor: "transparent",
    color: "#f5f5f5",
    border: "none",
    cursor: "none",
    display: "flex",
    flex: "initial",
    height: "2rem", // 32px
    justifyContent: "center",
    marginLeft: ".5rem",
    marginRight: ".5rem",
    padding: "0",
    position: "absolute",
    transitionDuration: ".15s",
    transitionProperty: "color, background-color, border",
    transitionTimingFunction: "linear",
    width: "2rem",
  },
};

export default styles;

import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect } from "react";
import { PlayerUiState } from "../../../../store/player";
import { LivelyPlayerUiContext } from "../../../context";
import VideoWrapper, { VideoWrapperProps } from "../../../ui-lib/VideoWrapper";
import { ErrorBoundary, useUndefinedStoreError } from "../../ErrorBoundary";

const ModularVideo = observer(({ classes, ...props }: Partial<VideoWrapperProps>) => {
  const componentName = "<PlayerVideo/>";
  /**
   * @todo: Not sure how we want to handle styles for this.
   */

  const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);

  const hasUndefinedStore: () => boolean = () => ctx === undefined || ctx?.videoElement === undefined;

  useUndefinedStoreError(hasUndefinedStore, componentName);

  const videoRef: React.Ref<HTMLDivElement> = React.createRef();
  /**
   * Get <video> element and MediaStream from server,
   * append <video> element to VideoWrapper component.
   */
  useEffect(() => {
    if (ctx?.videoElement && videoRef.current && ctx?.videoElement instanceof HTMLVideoElement) {
      if (!videoRef.current.firstChild) {
        videoRef.current.appendChild(ctx.videoElement);
      }
    }
  }, [ctx?.videoElement, videoRef]);

  // @TODO Needs to be added back in when this feature is fixed
  /*
  useEffect(
    () =>
      autorun(() => {
        const p = ctx?.player;
        const el = ctx?.videoElement;
        if (p == null || !(el instanceof HTMLVideoElement)) {
          return;
        }

        if (p.isImplements(player.Feature.CONSUMER)) {
          const logCtx = {
            streamName: p.streamName,
            consumerVideoEnabled: p.consumerVideoEnabled,
          };

          if (p.consumerVideoEnabled) {
            ctx?.logger?.debug("Video element is visible", logCtx);
            el.style.visibility = "";
          } else {

            //ctx?.logger?.debug("Video element is hidden", logCtx);
            //el.style.visibility = "hidden";
          }

        } else {
          el.style.visibility = "";
        }

      }),
    [ctx?.logger, ctx?.player, ctx?.videoElement, ctx?.player.format],
  );
  */
  return <VideoWrapper isFullScreen={ctx?.isFullscreen} classes={classes} ref={videoRef} {...props} />;
});

const PlayerVideoWithErrorBoundary: FC<Partial<VideoWrapperProps>> = ({ classes, ...props }) => {
  return (
    /**
     * @todo: Temporary fallback. Update for a real fallback.
     */
    <ErrorBoundary
      render={() => (
        <div style={{ height: "600px", width: "100%" }}>
          <p>Video is not working</p>
        </div>
      )}
    >
      <ModularVideo classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default PlayerVideoWithErrorBoundary;

import React, { FC } from "react";
import { IconProps } from "../iconProps";

const Play: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="play"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      {/* Generated by IcoMoon.io // Material Icons v3 */}
      <path fill="currentColor" d="M16.031 10.031l21.938 13.969-21.938 13.969v-27.938z" />
    </svg>
  );
};

export default Play;

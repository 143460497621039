export interface Message {
  code: number;
  type: string;
  level: string;
  message: string;
  explanation?: string;
  troubleshoot?: string;
  videoTrack?: string;
}

export class MessageList {
  static readonly displayName = "MessageList";

  private readonly _messages: { [key: number]: Message };

  constructor() {
    this._messages = {};
  }

  addMessage(msg: Message): void {
    this._messages[msg.code] = msg;
  }

  removeMessage(msg: Message): void {
    delete this._messages[msg.code];
  }

  get messages(): Message[] {
    return Object.keys(this._messages)
      .map((key) => this._messages[parseInt(key, 10)])
      .sort((a, b) => a.code - b.code);
  }
}

const offlineMessages = new MessageList();
offlineMessages.addMessage({
  code: 1001,
  type: "network",
  level: "error",
  message: "Internet disconnected",
  explanation: "Check your internet connection and reconnect to the network",
});

const disconnectedMessages = new MessageList();
disconnectedMessages.addMessage({
  code: 1002,
  type: "network",
  level: "error",
  message: "Call disconnected",
  explanation: "Please wait while the call reconnects",
});

const TROUBLESHOOTING = {
  NO_WEBCAM: {
    code: 1003,
    type: "webcam",
    level: "warn",
    message: "No webcam found",
    explanation: "Make sure your webcam is connected and your browser has permission to use it",
  },
  NO_MIC: {
    code: 1004,
    type: "microphone",
    level: "warn",
    message: "No microphone found",
    explanation: "Make sure your microphone is connected and your browser has permission to use it",
  },
  NETWORK_FIREWALLED: {
    code: 1005,
    type: "network",
    level: "error",
    message: "Connection blocked by firewall",
    explanation: "Disable firewall",
  },
  UNSTABLE_NETWORK: {
    code: 1006,
    type: "network",
    level: "warn",
    message: "Unstable connection",
    explanation: "If on wifi, try connecting to a stronger network",
  },
  WEBRTC_RECV_DISCONNECTED: {
    code: 1007,
    type: "network",
    level: "error",
    message: "Unable to receive stream",
    explanation: "If on wifi, try connecting to a stronger network",
  },
  WEBRTC_SEND_DISCONNECTED: {
    code: 1008,
    type: "network",
    level: "warn",
    message: "Unable to send stream",
    explanation: "If on wifi, try connecting to a stronger network",
  },
  ONE_TO_ONE_PEER_DISCONNECTED: {
    code: 1009,
    type: "network",
    level: "error",
    message: "Peer disconnected",
    explanation: "Please wait for the other user to come back online",
  },
};

const PEER_MESSAGES = {
  UNSTABLE_PEER: {
    code: 2001,
    type: "network",
    level: "error",
    message: "This user's connection is unstable",
  },
};

export { TROUBLESHOOTING, PEER_MESSAGES, offlineMessages, disconnectedMessages };

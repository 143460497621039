import React, { FC } from "react";
import { IconProps } from "./iconProps";

const CameraOff: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="camera-slash"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M179.427 151.748L165.505 140.855C169.403 140.497 172.897 137.344 172.897 132.885V67.0154C172.897 60.4798 165.53 56.6609 160.139 60.4029L132.395 79.7792V114.944L124.295 108.613V62.9915C124.295 56.2252 118.878 50.7404 112.196 50.7404H50.3556L30.51 35.2086C28.7381 33.8246 26.2068 34.1322 24.8146 35.9263L19.8532 42.385C18.4863 44.1791 18.7901 46.7421 20.562 48.1261L29.8013 55.3538L124.295 129.322L169.479 164.691C171.251 166.075 173.782 165.768 175.175 163.974L180.136 157.489C181.528 155.721 181.199 153.132 179.427 151.748ZM27.0928 136.908C27.0928 143.675 32.5098 149.16 39.1925 149.16H112.196C115.031 149.16 117.613 148.134 119.688 146.468L27.0928 73.9868V136.908Z"
      />
    </svg>
  );
};

export default CameraOff;

import { spy } from "mobx";
import type { StackFrame } from "stacktrace-js";
import { device, Feature } from "../../api/adapter";

const testLibPath = /.*(bootstrap.*|node_modules.*|disposable\.ts)$/;

function replacePath(path: string): string {
  const parts = path.split("/");
  let first: string | null = null;

  while (first !== "src" && parts.length > 1) {
    first = parts.shift() ?? null;
  }

  if (first === "src") {
    parts.unshift(first);
  }

  return `/${parts.join("/")}`;
}

/**
 * Removes from a stacktrace internal frames like React calls
 */
export function filterStacktrace(stacktrace: StackFrame[]): StackFrame[] {
  return stacktrace
    .filter((f) => !testLibPath.test(f.fileName ?? ""))
    .map((f) => ({ ...f, fileName: replacePath(f.fileName ?? "<unknown>") }));
}

/**
 * Formats a stacktrace to a readable text string
 */
export function formatStacktrace(stacktrace: StackFrame[], filenames = true): string {
  if (filenames) {
    return filterStacktrace(stacktrace)
      .map((f) => `\tat ${f.functionName} (${f.fileName}:${f.lineNumber})`)
      .join("\n");
  }
  return stacktrace.map((f) => `\tat ${f.functionName}`).join("\n");
}

const exclude = ["name", "type", "spyReportStart", "debugObjectName", "observableKind"];
export function startMobxDebugging(): void {
  const mobxDebug = device.isImplements(Feature.LOCAL_STORAGE) ? device.localStorage.getItem("mobxDebug") : "";
  if (mobxDebug === "true" || mobxDebug === "flat") {
    const useGroups = mobxDebug !== "flat";

    const oldDisposer = device.globals.get("mobxSpyDisposer");
    if (typeof oldDisposer === "function") {
      oldDisposer();
    }
    const mobxSpyDisposer = spy((event: any) => {
      let type: string = event.type;
      let name: string = event.name ?? "";

      switch (type) {
        case "action":
          if (typeof event.object === "object") {
            name = `${event.object.constructor.name}.${name}`;
          }
          break;
        case "add":
        case "delete":
        case "create":
        case "remove":
        case "splice":
        case "update":
          name = (event.name == null ? event.debugObjectName : `${event.debugObjectName}.${event.name}`) ?? "";
          break;
        default:
      }

      if (name.startsWith("event:")) {
        type = `event ${type}`;
        name = name.slice(6);
      }

      if (event.observableKind) {
        name = `${event.observableKind} ${name}`;
      }

      const other = Object.entries(event).filter(([k, v]) => !exclude.includes(k));

      if (event.spyReportStart) {
        if (useGroups) {
          console.groupCollapsed(type, name);
          console.groupCollapsed("stacktrace");
          console.trace();
          console.groupEnd();
        } else {
          console.log(`%c${event.type}`, "font-weight: bold", name, Object.fromEntries(other));
        }
      } else if (event.type === "report-end") {
        if (useGroups) {
          console.groupEnd();
        }
        return;
      } else {
        console.log(`%c${event.type}`, "font-weight: bold", name);
      }

      if (useGroups && other.length > 0) {
        console.log(Object.fromEntries(other));
      }
    });

    device.globals.set("mobxSpyDisposer", mobxSpyDisposer);
  }
}

import { player, SourceScoreLevel, TranscodeScoreLevel } from "@livelyvideo/video-client-core";
import { Properties } from "csstype";
import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useState } from "react";
import { PlayerUiState } from "../../../../../store";
import { LivelyPlayerUiContext } from "../../../../context";
import { mergeStyles } from "../../../../livelyStyles";
import PillToggle from "../../../../ui-lib/Inputs/PillToggle";
import SelectNonNative, { SelectProps } from "../../../../ui-lib/Inputs/SelectNonNative";
import { ErrorBoundary, useUndefinedStoreError } from "../../../ErrorBoundary";
import styles from "./styles";

interface QualitySettingsClasses {
  root?: Properties;
  option?: Properties;
  activeOption?: Properties;
  disabledOption?: Properties;
}

type QualitySettingsProps = Omit<SelectProps, "classes"> & {
  classes?: QualitySettingsClasses;
  showBitrate?: boolean;
  disableToggle?: boolean;
  disableSelect?: boolean;
  active?: boolean;
};

const ModularQualitySettings = observer(
  ({ classes = {}, disableToggle, disableSelect, showBitrate, ...props }: Partial<QualitySettingsProps>) => {
    const componentName = "<QualitySettings/>";

    /**
     * Access LivelyPlayerUiContext & v2 to grab necessary player properties.
     */
    const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);

    /**
     * Assess conditions for an undefined store.
     * @returns {boolean}
     * */
    const hasUndefinedStore: () => boolean = (): boolean => !ctx?.player;

    /**
     * Throw error (and trigger ErrorBoundary) if store is undefined.
     * */
    useUndefinedStoreError(hasUndefinedStore, componentName);

    /**
     * Local state to handle the selected value.
     * */
    const [selectValue, setSelectValue] = useState<number | null>(null);
    /**
     * Local state to check if webRTC exists and what index in availablePlayers it is.
     */
    const [webRtcIndex, setWebRtcIndex] = useState<number | null>(null);
    /**
     * Local state to check if webRTC is the only available driver.
     */
    const [onlyWebRtc, setOnlyWebRtc] = useState<boolean>(false);
    /**
     * Local state to see if lowLatency is set or not
     */
    const [latency, setLatency] = useState<boolean>(false);
    /**
     * Local state to hold the available bitrate layers.
     */
    //Simple boolean that gets set to true when our component has mounted.
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);

    //Handles mounting so we can trigger the animation for this to appear.
    useEffect(() => {
      let timeout: ReturnType<typeof setTimeout>;
      if (props.active !== undefined) {
        if (props.active === true) {
          setIsShowing(true);
          timeout = setTimeout(() => {
            setIsActive(true);
          }, 600);
        } else if (props.active === false && isShowing === true) {
          setIsShowing(false);
          timeout = setTimeout(() => {
            setIsActive(false);
          }, 600);
        } else {
          setIsActive(false);
          setIsShowing(false);
        }
      }
      return () => {
        clearTimeout(timeout);
      };
    }, [props?.active]);

    const [availableBitrates, setAvailableBitrates] = useState<
      { name: string; score: TranscodeScoreLevel | SourceScoreLevel | undefined; bitrate: number | undefined }[]
    >([
      {
        name: "Source",
        score: undefined,
        bitrate: undefined,
      },
      {
        name: "High",
        score: undefined,
        bitrate: undefined,
      },
      {
        name: "Medium",
        score: undefined,
        bitrate: undefined,
      },
      {
        name: "Low",
        score: undefined,
        bitrate: undefined,
      },
    ]);

    useEffect(() => {
      /**
       * Checks to see if we are using a manifest player
       * If we are using a manifest player we check to see if webrtc exists, if it does not set an index for webrtc
       * If only webRTC exists and no other drivers we se onlyWebRtc to true.
       */
      if (ctx?.player?.isImplements(player.Feature.PLAYER_SELECTOR)) {
        let webRtcExists = false;
        if (ctx?.player.format === "webrtc") {
          setLatency(true);
        }
        const currentPlayer = ctx?.player?.availablePlayers;

        currentPlayer.map((object, index) => {
          if (object.id === "webrtc") {
            webRtcExists = true;
            setWebRtcIndex(index);
          }
          if (index === 0 && currentPlayer[1] != null && object.id === "webrtc") {
            setOnlyWebRtc(true);
            setLatency(true);
          }
          return null;
        });
        if (!webRtcExists) {
          setWebRtcIndex(null);
          setOnlyWebRtc(false);
          setLatency(false);
        }
      }
      /**
       * Checks to see if we are using a player with the bitrate switching properties
       * If we are we grab the bitrate layers and populate the local state with the available ones
       * If the properties for bitrate switching are available but no bitrates are available we return an empty array
       */
      if (ctx?.player?.isImplements(player.Feature.BITRATE_SWITCHING)) {
        availableBitrateHelper();
      }
    }, [ctx?.availableQualities, ctx?.availablePlayers, ctx?.player]);

    const availableBitrateHelper = (): void => {
      if (ctx?.player?.isImplements(player.Feature.BITRATE_SWITCHING)) {

        let source: SourceScoreLevel | undefined;
        if (ctx?.player.availableQualities.some((q) => q.level === SourceScoreLevel.High)) {
          source = SourceScoreLevel.High;
        } else if (ctx?.player.availableQualities.some((q) => q.level === SourceScoreLevel.Medium)) {
          source = SourceScoreLevel.Medium;
        } else if (ctx?.player.availableQualities.some((q) => q.level === SourceScoreLevel.Low)) {
          source = SourceScoreLevel.Low;
        }
        let low: TranscodeScoreLevel | undefined;
        if (ctx?.player.availableQualities.some((q) => q.level === TranscodeScoreLevel.Low)) {
          low = TranscodeScoreLevel.Low;
        } else if (ctx?.player.availableQualities.some((q) => q.level === TranscodeScoreLevel.Lowest)) {
          low = TranscodeScoreLevel.Lowest;
        } else {
          low = undefined;
        }
        let medium: TranscodeScoreLevel | undefined;
        if (ctx?.player.availableQualities.some((q) => q.level === TranscodeScoreLevel.Medium)) {
          medium = TranscodeScoreLevel.Medium;
        } else if (ctx?.player.availableQualities.some((q) => q.level === TranscodeScoreLevel.MediumHigh)) {
          medium = TranscodeScoreLevel.MediumHigh;
        } else if (ctx?.player.availableQualities.some((q) => q.level === TranscodeScoreLevel.MediumLow)) {
          medium = TranscodeScoreLevel.MediumLow;
        } else {
          medium = undefined;
        }
        let high: TranscodeScoreLevel | undefined;
        if (ctx?.player.availableQualities.some((q) => q.level === TranscodeScoreLevel.High)) {
          high = TranscodeScoreLevel.High;
        } else if (ctx?.player.availableQualities.some((q) => q.level === TranscodeScoreLevel.Highest)) {
          high = TranscodeScoreLevel.Highest;
        } else {
          high = undefined;
        }

        const lowBitrate =
          low &&
          Math.floor((ctx?.player.availableQualities.filter((q) => q.level === low)[0].layer.bitrate / 1000000) * 100) /
            100;
        const mediumBitrate =
          medium &&
          Math.floor(
            (ctx?.player.availableQualities.filter((q) => q.level === medium)[0].layer.bitrate / 1000000) * 100,
          ) / 100;
        const highBitrate =
          high &&
          Math.floor(
            (ctx?.player.availableQualities.filter((q) => q.level === high)[0].layer.bitrate / 1000000) * 100,
          ) / 100;
        const sourceBitrate =
          source &&
          Math.floor(
            (ctx?.player.availableQualities.filter((q) => q.level === source)[0].layer.bitrate / 1000000) * 100,
          ) / 100;

        setAvailableBitrates([
          {
            name: "Source",
            score: source,
            bitrate: sourceBitrate,
          },
          {
            name: "High",
            score: high,
            bitrate: highBitrate,
          },
          {
            name: "Medium",
            score: medium,
            bitrate: mediumBitrate,
          },
          {
            name: "Low",
            score: low,
            bitrate: lowBitrate,
          },
        ]);
      }
    };

    /**
     * Setting both the local state and the Bitrate API selection for bitrate on change.
     * */
    const selectQuality: (score: TranscodeScoreLevel | SourceScoreLevel, index: number) => void = (score, index) => {
      if (!disableSelect) {
        if (ctx?.player?.isImplements(player.Feature.BITRATE_SWITCHING)) {
          ctx?.player?.setPreferredLevel(score);
        }
        setSelectValue(index);
      }
    };
    /**
     * Setting to low latency will set the driver to webRTC,
     * turning off low latency will grab the next driver in the available players array,
     * if the next driver is unavailable it grabs the driver before webrtc.
     */
    const setLowLatency: () => void = () => {
      if (!disableToggle) {
        // If using a manifest player, otherwise this feature doesn't exist //
        if (ctx?.player?.isImplements(player.Feature.PLAYER_SELECTOR)) {
          setSelectValue(null);
          const currentPlayer = ctx?.player?.availablePlayers;
          if (!latency && webRtcIndex != null) {
            setLatency(true);
            ctx?.player?.selectPlayer(webRtcIndex);
          } else if (latency && webRtcIndex != null && !onlyWebRtc) {
            setLatency(false);
            if (currentPlayer[webRtcIndex + 1] != null) {
              ctx?.player?.selectPlayer(webRtcIndex + 1);
            } else if (currentPlayer[webRtcIndex - 1] != null) {
              ctx?.player?.selectPlayer(webRtcIndex - 1);
            }
          }
        }
      }
    };

    const handleMouseEv: (ev: boolean) => void = (ev) => {
      if (ctx) {
        ctx.qualityMouseOver = ev;
      }
    };

    const mergedClasses = mergeStyles({ source: classes, target: styles }, { stylesNamespace: "select" });

    return (
      <div
        style={{
          display: props.active || isActive ? "block" : "none",
          opacity: isShowing ? 1 : 0,
          bottom: isShowing ? 50 : 0,
          transition: "opacity 0.3s ease-in-out, bottom 0.5s ease-in-out",
          pointerEvents: isActive && isShowing ? "all" : "none",
        }}
        onMouseLeave={() => handleMouseEv(false)}
        onMouseEnter={() => handleMouseEv(true)}
        className={mergedClasses.wrapper}
      >
        {ctx?.player?.isImplements(player.Feature.BITRATE_SWITCHING) && (
          <SelectNonNative {...props} className={mergedClasses.root}>
            {availableBitrates.map((layer, index) =>
              layer.score !== undefined && !disableSelect ? (
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => e.key === "Enter" && layer.score !== undefined && selectQuality(layer.score, index)}
                  key={layer.name}
                  onClick={() => layer.score !== undefined && selectQuality(layer.score, index)}
                  className={index === selectValue ? mergedClasses.activeOption : mergedClasses.option}
                >
                  {showBitrate
                    ? `${`${layer.name}`} ${
                        !Number.isNaN(layer.bitrate) && layer.bitrate !== undefined ? `: ${layer.bitrate} Mbps` : ""
                      } `
                    : layer.name}
                </div>
              ) : (
                <div key={layer.name} className={mergedClasses.disabledOption}>
                  {`${`${layer.name}`} ${
                    !Number.isNaN(layer.bitrate) && layer.bitrate !== undefined
                      ? `: ${layer.bitrate} Mbps`
                      : " Unavailable"
                  } `}
                </div>
              ),
            )}
          </SelectNonNative>
        )}
        {ctx?.player?.isImplements(player.Feature.PLAYER_SELECTOR) && (
          <PillToggle
            disabledOn={onlyWebRtc}
            disabledOff={webRtcIndex == null || disableToggle}
            isActive={latency}
            label="Low-latency:"
            handleClick={setLowLatency}
          />
        )}
      </div>
    );
  },
);

const QualitySettingsWithErrorBoundary: FC<Partial<QualitySettingsProps>> = ({ classes, ...props }) => {
  const mergedClasses = mergeStyles({ source: classes, target: styles }, { stylesNamespace: "select" });
  const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);
  const [availableBitrates] = useState<{ name: string; score: number | string | null; bitrate: number | undefined }[]>([
    {
      name: "Source",
      score: null,
      bitrate: undefined,
    },
    {
      name: "High",
      score: null,
      bitrate: undefined,
    },
    {
      name: "Medium",
      score: null,
      bitrate: undefined,
    },
    { name: "Low", score: null, bitrate: undefined },
  ]);
  /**
   * Instead of just displayed that the UI is unavailable this is taking the approach of displaying the UI but unselectable and grayed out.
   * If the properties do not exist the UI will not display
   */
  return (
    <ErrorBoundary
      render={() => (
        <>
          {ctx?.player?.isImplements(player.Feature.BITRATE_SWITCHING) && (
            <SelectNonNative {...props} className={mergedClasses.root}>
              {availableBitrates !== [] &&
                availableBitrates.map((layer) => (
                  <div role="button" key={layer.name} className={mergedClasses.disabledOption}>
                    {`${`${layer.name}:`} ${
                      !Number.isNaN(layer.bitrate) && layer.bitrate !== undefined
                        ? `${layer.bitrate} Mbps`
                        : " Unavailable"
                    } `}
                  </div>
                ))}
            </SelectNonNative>
          )}
          {ctx?.player?.isImplements(player.Feature.PLAYER_SELECTOR) && <PillToggle isActive={false} disabled />}
        </>
      )}
    >
      <ModularQualitySettings {...props} />
    </ErrorBoundary>
  );
};

export default QualitySettingsWithErrorBoundary;

import { Properties } from "csstype";
import React, { ReactElement, useState } from "react";
import { CameraButton, EncoderVideo, MicrophoneButton, ScreenCaptureButton, SettingsButton } from "../../../index";
import AudioDeviceSelect from "../../components/ModularComponents/Inputs/Encoder/AudioDeviceSelect";
import VideoDeviceSelect from "../../components/ModularComponents/Inputs/Encoder/VideoDeviceSelect";
import { mergeStyles, mergeStylesObjects } from "../../livelyStyles";
import styles from "./callControlStyles";

interface CallControlClasses {
  screenCaptureButton?: Properties;
  settingsButton?: Properties;
  cameraButton?: Properties;
  microphoneButton?: Properties;
}

export interface CallControlProps {
  classes?: CallControlClasses;
  renderCustomControls?: () => ReactElement;
  renderCallMetaDisplay?: () => ReactElement;
}

const CallControlUI = ({
  classes = {
    screenCaptureButton: {},
    settingsButton: {},
    cameraButton: {},
    microphoneButton: {},
  },
  renderCustomControls,
  renderCallMetaDisplay,
}: CallControlProps): ReactElement => {
  const [areControlsOpen, areControlsOpenSet] = useState(false);
  const [isSettingsOpen, isSettingsOpenSet] = useState(false);

  const mergedStyles = mergeStylesObjects(styles, classes);
  const mergedClasses = mergeStyles({ source: classes, target: styles }, { stylesNamespace: "callControlUi" });

  const renderSettings = (): ReactElement => (
    <div className={mergedClasses.settingsContainer}>
      <VideoDeviceSelect classes={mergedStyles.videoDeviceSelect} />
      <AudioDeviceSelect classes={mergedStyles.audioDeviceSelect} />
    </div>
  );

  return (
    <div
      className={mergedClasses.root}
      onBlur={() => {
        areControlsOpenSet(false);
        isSettingsOpenSet(false);
      }}
      onFocus={() => {
        areControlsOpenSet(true);
      }}
      onMouseOver={() => {
        areControlsOpenSet(true);
      }}
      onMouseLeave={() => {
        areControlsOpenSet(false);
        isSettingsOpenSet(false);
      }}
    >
      <div
        className={mergedClasses.videoContainer}
        style={{
          height: areControlsOpen ? "100%" : undefined,
        }}
      >
        <EncoderVideo classes={mergedStyles.videoWrapper} />
      </div>
      <div
        className={mergedClasses.controlsContainer}
        style={{
          display: areControlsOpen === true ? "block" : "none",
        }}
      >
        {renderCustomControls?.()}
        {!renderCustomControls && (
          <div className={mergedClasses.buttonsContainer}>
            <ScreenCaptureButton classes={mergedStyles.screenCaptureButton} />
            <SettingsButton
              classes={mergedStyles.settingsButton}
              onClick={() => {
                isSettingsOpenSet(!isSettingsOpen);
              }}
            />
            <CameraButton classes={mergedStyles.cameraButton} />
            <MicrophoneButton classes={mergedStyles.microphoneButton} />
          </div>
        )}
        {renderCallMetaDisplay?.()}
        {isSettingsOpen && renderSettings()}
      </div>
    </div>
  );
};

export default CallControlUI;

const styles = {
  wrapper: {
    position: "absolute",
    bottom: 50
  },
  root: {
    position: "relative",
    overflow: "auto",
    "@media only screen and (max-width: 1000px)": {
      maxHeight: 100,
    },
  },
  option: {
    width: 120,
    background: "#111111",
    fontSize: 12,
    color: "#eeeeee",
    fontFamily: "sans-serif",
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: "#111111",
      background: "#eeeeee",
    },
  },
  activeOption: {
    background: "#eeeeee",
    fontSize: 12,
    color: "#111111",
    fontFamily: "sans-serif",
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
    cursor: "pointer",
  },
  disabledOption: {
    cursor: "not-allowed",
    background: "#313131",
    fontSize: 12,
    color: "#a0a0a0",
    fontFamily: "sans-serif",
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    display: "flex",
  },
};

export default styles;

import { device, Feature } from "../api/adapter";
import { uuidv4 } from "../internal/utils";

export type BasicSerializable = string | number | boolean;

export const PACKAGE_NAME = "VDC-core";

const INSTANCE_ID_KEY = "vdc:instanceId";
const CONTEXT_ID_KEY = "vdc:contextId";

/**
 * Machine ID is global across all VDC instances in the current browser/platform
 */
export function instanceId(): string | null {
  if (device.isImplements(Feature.LOCAL_STORAGE)) {
    if (device.localStorage.getItem(INSTANCE_ID_KEY) == null) {
      device.localStorage.setItem(INSTANCE_ID_KEY, uuidv4());
    }
    return device.localStorage.getItem(INSTANCE_ID_KEY);
  }
  return null;
}

/**
 * Context ID is global for current session
 */
export function contextId(): string | null {
  if (!device.globals.has(CONTEXT_ID_KEY)) {
    device.globals.set(CONTEXT_ID_KEY, uuidv4());
  }

  const id = device.globals.get(CONTEXT_ID_KEY);
  return typeof id === "string" ? id : null;
}

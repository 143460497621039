import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect } from "react";
import { EncoderUiState } from "../../../../store/encoder";
import { LivelyEncoderUiContext } from "../../../context";
import PlayerOverlayButton from "../../../ui-lib/PlayerOverlayButton";
import VideoWrapper, { VideoWrapperProps } from "../../../ui-lib/VideoWrapper";
import { ErrorBoundary, useUndefinedStoreError } from "../../ErrorBoundary";

const ModularVideo = observer(({ classes, backgroundAudio, ...props }: Partial<VideoWrapperProps>) => {
  const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);
  const videoWrapperRef: React.Ref<HTMLDivElement> = React.createRef();
  const hasUndefinedStore: () => boolean = () => ctx?.videoElement === undefined;
  useUndefinedStoreError(hasUndefinedStore, "<EncoderVideo/>");

  useEffect(() => {
    if (ctx?.videoElement && videoWrapperRef.current && ctx?.videoElement instanceof HTMLVideoElement) {
      ctx.videoElement.setAttribute("playsinline", "true");
      videoWrapperRef.current.appendChild(ctx.videoElement);

      // resume it back after unmounting
      // see: https://html.spec.whatwg.org/multipage/media.html#playing-the-media-resource:media-element-83
      if (ctx.videoElement.paused && !ctx.videoElement.ended && ctx.videoElement.readyState > 2) {
        ctx.videoElement.play();
      }
    }
  }, [ctx?.videoElement, videoWrapperRef]);

  useEffect(() => {
    const removeAudio = (): void => {
      if (ctx && backgroundAudio === true) {
        ctx.silentAudio = false;
        ctx.removeBackgroundAudio();
      }
    };

    const addAudio = (): void => {
      if (ctx && backgroundAudio === true) {
        if (ctx?.isEncoderReady) {
          ctx.silentAudio = true;
          ctx.backgroundAudio();
        }
      }
    };

    if (ctx?.mediaStreamController && backgroundAudio === true) {
      ctx?.mediaStreamController.on("videoDeviceChanging", removeAudio);

      ctx?.mediaStreamController.on("videoDeviceChanged", addAudio);

      ctx?.mediaStreamController.on("audioDeviceChanging", removeAudio);

      ctx?.mediaStreamController.on("audioDeviceChanged", addAudio);
    }

    if (backgroundAudio === true && ctx?.isEncoderReady) {
      ctx.silentAudio = true;
      ctx.backgroundAudio();
    } else if (backgroundAudio === false && ctx?.silentAudio) {
      ctx.silentAudio = false;
      ctx.removeBackgroundAudio();
    }
    return () => {
      if (ctx?.mediaStreamController && backgroundAudio === true) {
        ctx?.mediaStreamController.off("videoDeviceChanging", removeAudio);

        ctx?.mediaStreamController.off("videoDeviceChanged", addAudio);

        ctx?.mediaStreamController.off("audioDeviceChanging", removeAudio);

        ctx?.mediaStreamController.off("audioDeviceChanged", addAudio);
      }
    };
  }, [ctx, ctx?.isEncoderReady, backgroundAudio]);

  return <VideoWrapper classes={classes} isFullScreen={ctx?.isFullscreen} ref={videoWrapperRef} {...props} />;
});

const EncoderVideoWithErrorBoundary: FC<Partial<VideoWrapperProps>> = ({ classes, ...props }) => {
  return (
    /**
     * @todo: Temporary fallback. Update for a real fallback.
     */
    <ErrorBoundary
      render={() => (
        <div style={{ height: "100%", width: "100%" }}>
          <p>Video is not working</p>
          <PlayerOverlayButton classes={classes?.playerOverlayButton} {...props} disabled />
        </div>
      )}
    >
      <ModularVideo classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default EncoderVideoWithErrorBoundary;

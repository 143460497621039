import React, { FC } from "react";
import { IconProps } from "../iconProps";

const Record: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="record"
      focusable="false"
      width="38"
      height="35"
      viewBox="0 0 30 32"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}
    >
      <circle cx="15" cy="12" r="10"></circle>
    </svg>
  );
};

export default Record;

import React, { FC } from "react";
import { IconProps } from "./iconProps";

const Camera: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="camera"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M112.209 50.7342H39.1599C32.473 50.7342 27.0526 56.225 27.0526 62.9987V136.996C27.0526 143.77 32.473 149.261 39.1599 149.261H112.209C118.895 149.261 124.316 143.77 124.316 136.996V62.9987C124.316 56.225 118.895 50.7342 112.209 50.7342ZM160.182 60.4072L132.421 79.8046V120.19L160.182 139.562C165.551 143.308 172.947 139.485 172.947 132.942V67.027C172.947 60.5099 165.577 56.6612 160.182 60.4072Z"
      />
    </svg>
  );
};

export default Camera;

import React, { FC } from "react";
import { IconProps } from "../iconProps";

const Settings: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="settings"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      {/* Generated by IcoMoon.io // Material Icons v3 */}
      <path
        fill="currentColor"
        d="M24 31.031q2.906 0 4.969-2.063t2.063-4.969-2.063-4.969-4.969-2.063-4.969 2.063-2.063 4.969 2.063 4.969 4.969 2.063zM38.906 25.969l4.219 3.281q0.656 0.469 0.188 1.313l-4.031 6.938q-0.375 0.656-1.219 0.375l-4.969-1.969q-1.969 1.406-3.375 1.969l-0.75 5.25q-0.188 0.844-0.938 0.844h-8.063q-0.75 0-0.938-0.844l-0.75-5.25q-1.781-0.75-3.375-1.969l-4.969 1.969q-0.844 0.281-1.219-0.375l-4.031-6.938q-0.469-0.844 0.188-1.313l4.219-3.281q-0.094-0.656-0.094-1.969t0.094-1.969l-4.219-3.281q-0.656-0.469-0.188-1.313l4.031-6.938q0.375-0.656 1.219-0.375l4.969 1.969q1.969-1.406 3.375-1.969l0.75-5.25q0.188-0.844 0.938-0.844h8.063q0.75 0 0.938 0.844l0.75 5.25q1.781 0.75 3.375 1.969l4.969-1.969q0.844-0.281 1.219 0.375l4.031 6.938q0.469 0.844-0.188 1.313l-4.219 3.281q0.094 0.656 0.094 1.969t-0.094 1.969z"
      />
    </svg>
  );
};

export default Settings;

// @todo: broken rule. it should be reconsidered for removing
import { IEventEmitter } from "@livelyvideo/events-typed";
import type { LoggerCore } from "@livelyvideo/log-client";
import { Json } from "@livelyvideo/log-node";
/* eslint-disable */
import {action, autorun, computed, makeObservable, observable, toJS} from 'mobx';
import { device } from "../../api/adapter";
import { Feature as AdapterFeature } from "../../api/adapter/features/feature";
import type { MediaStream } from "../../api/adapter/features/media-stream";
import {SourceProvider} from '../../api';
import type { VideoElement } from "../../api/typings/video-element";
import  { isMediaStream } from "../../api/typings/video-element";
import {PlayingIssueError, wrapNativeError} from '../errors';
import type { PlayerAPI, PlayerEvents } from '../../api';
import { BitrateLayer, Quality, TranscodeScoreLevel, SourceScoreLevel } from "../../api/player/features/bitrate-switching";
import { Feature as PlayerFeature, Features } from "../../api/player/features/feature";
import type { ManifestFormats } from '../../api';
import { onceCanceled } from "../utils/context/context";
import type { VcContext } from "../utils/context/vc-context";
import ProxyEventEmitter from "../utils/events/proxy-event-emitter";
import { findClosestQuality, playOncePossible } from "./helper";
import {dumpVideoElement } from "../utils/debug/play-logs"
import { TimeupdateWrapper } from "../utils/timeupdate-wrapper";
import { MediasoupSource } from '../mediasoup-source';

export interface Encoding {
  videoKbps: number;
  audioKbps: number;
  audioCodec: string;
  videoWidth: number;
  videoHeight: number;
  videoPts: number;
  audioPts: number;
  channels: string[];
  collected: number;
  fps: number;
  origin: boolean;
  location: string;
  streamNames: string[];
}

export interface ContractDetails {
  segMaxTime: number;
  segmentsSize: number;
  segmentsDuration: number;
  segments: number;
  volume: number;
  segMinTime: number;
  bufferUnderflowCount: number;
  segmentTotalDownloadTime: number;
  upshift: number;
  downshift: number;
  muted: boolean;
  bufferOverflowCount: number;
  errors: unknown;
}

type Counters = {
  fragCounts: number;
  fragSize: number;
  fragDuration: number;
  fragDownloadTime: number;
  fragMaxTime: number;
  fragMinTime: number;
  bufferOverflowCount: number;
  bufferUnderflowCount: number;
  upshift: number;
  downshift: number;
  lastProgress: number;
  currentErrorCount: number;
  recentErrorCount: number;
  restartCount: number;
  fragFetchTime: number;
};

const BACK_OFF = [0, 1000, 2000, 5000, 25000];

const emptyCounters: Counters = {
  bufferOverflowCount: 0,
  bufferUnderflowCount: 0,
  currentErrorCount: 0,
  downshift: 0,
  fragCounts: 0,
  fragDownloadTime: 0,
  fragDuration: 0,
  fragMaxTime: 0,
  fragMinTime: 0,
  fragFetchTime: 0,
  fragSize: 0,
  lastProgress: 0,
  recentErrorCount: 0,
  restartCount: 0,
  upshift: 0,
};

export const timeupdateWrapper = new TimeupdateWrapper();

export type CorePlayerOptions = {
  /**
   * attempts to find and use the bitrate nearest to this value
   */
  bitrate?: number | null;

  // whether or not the driver is muted
  muted?: boolean;

  // 0-1 volume
  volume?: number;

  autoPlay?: boolean;

  origin?: boolean;
  preset?: null;
  recoverErrorCount?: number;

  timeout?: number;
  driverFailover?: boolean;
};

const defaultOptions: CorePlayerOptions = {
  bitrate: null,
  muted: false,
  volume: 0.75,
  autoPlay: true,
  origin: false,
  preset: null,
  recoverErrorCount: 0,
  timeout: 30000,
  driverFailover: true
};

export abstract class CorePlayer<
    Options extends CorePlayerOptions = CorePlayerOptions,
    Source = unknown,
    Events extends PlayerEvents = PlayerEvents,
    HostElement extends VideoElement = VideoElement,
  >
  extends ProxyEventEmitter<Events>
  implements PlayerAPI
{
  protected readonly ctx: VcContext;

  protected counters: Counters = emptyCounters;

  private lockRestart = false;

  private clearRestartCount: any = 0;

  playingPromise: Promise<boolean> | null = null;

  abstract isSupported(): Promise<boolean>;

  abstract get format(): keyof ManifestFormats;

  consumerAudioMuted?: boolean = undefined;

  consumerVideoPaused?: boolean = undefined;

  internalPaused = false;

  qualityEqual = false;

  internalMuted = false;

  internalVolume = 1;

  private startedPlaying = false;

  autoPlay = true;

  driverFailover = true;

  forcedMute = false;

  protected readonly options: Options;

  hostEl: HostElement | null = null;

  protected readonly provider: IEventEmitter<{audioMuted: boolean; videoPaused: boolean;}> & SourceProvider<Source>;

  source: MediaStream | string | null = null;

  private readonly errors: Record<string, Error> = {};

  availableQualities: Quality[] = [];

  currentQuality: Quality | null = null;

  preferredLevel: TranscodeScoreLevel | SourceScoreLevel | null = null;

  private _poster: string | null = null;

  get poster(): string | null {
    return this._poster;
  }

  get logger(): LoggerCore {
    return this.ctx.logger;
  }

  set poster(val: string | null) {
    if (val != null && this.hostEl != null) {
      this.hostEl.setAttribute("poster", val);
    }
    this._poster = val;
  }

  /**
   * @deprecated Use `currentQuality` instead
   */
  get activeLayer(): BitrateLayer | null {
    return this.currentQuality?.layer ?? null;
  }

  /**
   * @deprecated Use `availableQualities` instead
   */
  get layers(): BitrateLayer[] {
    return this.availableQualities.map((q) => q.layer);
  }

  constructor(ctx: VcContext, provider: SourceProvider<Source>, options: Options) {
    super();

    makeObservable<CorePlayer, "_poster">(this, {
      // observables
      consumerAudioMuted: observable,
      consumerVideoPaused: observable,
      internalPaused: observable,
      internalMuted: observable,
      internalVolume: observable,
      forcedMute: observable,
      hostEl: observable.ref,
      source: observable.ref,
      availableQualities: observable.shallow,
      currentQuality: observable,
      _poster: observable,
      driverFailover: observable,

      // computed
      attached: computed,
      localVideoPaused: computed,
      localAudioMuted: computed,
      localAudioVolume: computed,
      poster: computed,

      // actions
      attachTo: action,
    });

    this.ctx = ctx;
    this.options = options ?? defaultOptions;
    if (this.options.autoPlay != null) {
      this.autoPlay = this.options.autoPlay;
    }
    this.provider = provider;
    this.internalPaused = !this.autoPlay;

    if (this.options?.muted != null) {
      this.internalMuted = this.options.muted;
    }

    if (this.options?.volume != null) {
      this.localAudioVolume = this.options.volume;
    }

    ctx.logger.attachObject(this);
    ctx.logger.trace("constructor()", { options: this.options });
    onceCanceled(ctx).then((reason) => this.dispose(`Core Player Context Cancelled: ${reason}`));

    // this.hasAudio = source.hasAudio;
    // this.hasVideo = source.hasVideo;

    this.provider.on("source", this.handleSource);
    this.provider.on("source", this.isSource)
    this.provider.on("audioMuted", this.handleAudioMuted);
    this.provider.on("videoPaused", this.handleVideoPaused);
    this.addInnerDisposer(() => {
      this.provider.off("source", this.handleSource);
      this.provider.off("source", this.isSource);
      this.provider.off("audioMuted", this.handleAudioMuted);
      this.provider.off("videoPaused", this.handleVideoPaused);
    });

    if (this.provider.source != null) {
      this.handleSource(this.provider.source);
    }

    this.addInnerDisposer(() => this.cleanVideoEl("inner disposer running"));
  }

  protected abstract get implementedFeatures(): PlayerFeature[];

  async ready(): Promise<void> {
    if (this.source != null) {
      return;
    }
    this.ctx.logger.debug("no source, not ready");

    // why linter triggers this error?
    return new Promise<void>((resolve, reject) => {

      let timeoutHandler: any;
      const dispose = autorun((reaction) => {
        if (this.source != null) {
          reaction.dispose();
          device.clearTimeout(timeoutHandler);
          resolve();
        }
      });

      if (this.options.timeout != null) {
        timeoutHandler = device.setTimeout(() => {
          dispose();
          const err = new PlayingIssueError(`timeout after ${this.options.timeout}ms`, {
            player: this,
          });

          this.emitError(err);
          if (this.options.driverFailover) {
            this.emit("driverFailover", true);
          }

          reject(err);
        }, this.options.timeout);
      }
    });
  }

  private handleAudioMuted(val: boolean): void {
    this.consumerAudioMuted = val;
  }

  private handleVideoPaused(val: boolean): void {
    this.consumerVideoPaused = val;
  }

  private isSource(): void{
    if (this.provider instanceof MediasoupSource) {
      this.consumerVideoPaused = false;
    }
  }

  protected abstract handleSource(value: Source): void;

  get attached(): boolean {
    return this.hostEl != null;
  }

  get localVideoPaused(): boolean {
    return this.internalPaused;
  }

  set localVideoPaused(val: boolean) {
    this.internalPaused = val;
    if (this.hostEl != null) {
      if (this.internalPaused) {
        this.hostEl.pause();
      } else {
        (async () => {
          try {
            await this.play();
          } catch (err: unknown) {
            this.emitError(
              new PlayingIssueError("VideoElement.play() error", {
                inner: err instanceof Error ? err : null,
                player: this,
              }),
            );
          }
        })();
      }
    }
  }

  get localAudioMuted(): boolean {
    return this.internalMuted;
  }

  set localAudioMuted(val: boolean) {
    this.internalMuted = val;
    if (this.hostEl != null) {
      this.hostEl.muted = val;
    }
  }

  get localAudioVolume(): number {
    return this.internalVolume;
  }

  set localAudioVolume(val: number) {
    if (val > 1 || val < 0) {
      console.warn(
        `Volume level not set. ${val} is not a valid volume value for an HTMLMediaElement. Volume levels must be between 0-1.`,
      );
      return;
    }
    this.internalVolume = val;
    if (this.hostEl != null) {
      this.hostEl.volume = val;
    }
  }

  private handleElPlay(): void {
    if (this.internalPaused) {
      this.ctx.logger.warn("A try to call .play() on HTMLVideoElement outside the Player");
      this.internalPaused = false;
    }
  }

  private handleElPause(): void {
    if (!this.internalPaused && this.hostEl != null) {
      if (!this.hostEl.paused) {
        return;
      }
      // no warnings because browser can do that
      this.play().catch((err) => {
        device.setTimeout(this.handleElPause, 50);
        this.ctx.logger.error("An error occured on the play() request", { err: wrapNativeError(err) });
      });
    }
  }

  private handleElVolumeChange(): void {
    if (this.hostEl == null) {
      return;
    }
    if (Math.abs(this.internalVolume - this.hostEl.volume) > 0.01) {
      this.ctx.logger.warn("A try to change volume on HTMLVideoElement outside the Player");
      this.hostEl.volume = this.internalVolume;
    }
    if (this.hostEl.muted !== this.internalMuted) {
      this.hostEl.muted = this.internalMuted;
    }
  }

  async attachTo(el: HostElement): Promise<void> {
    this.ctx.logger.trace("attachTo()", { el: dumpVideoElement(el) });

    if (el === this.hostEl) {
      return;
    }

    this.ctx.logger.debug("attach to host element", { element: dumpVideoElement(el), options: this.options });

  if (this.hostEl != null) {
      this.cleanVideoEl("cleaning hostEl", this.hostEl);
    }

    this.hostEl = el;
    this.initVideoEl(el).catch((err) => this.ctx.logger.error(`Unable to initialize VideoElement: ${err}`));

    if (this.source != null) {
      if (typeof this.source === "string") {
        this.hostEl.src = this.source;
      } else {
        this.hostEl.srcObject = this.source;
      }
      this.emit("hostElementAttached", { el: this.hostEl });
      this.ctx.logger.debug("hostElementAttached", { el: dumpVideoElement(this.hostEl) });}

  }

  private async initVideoEl(el: VideoElement): Promise<void> {
    el.muted = this.internalMuted;
    el.volume = this.internalVolume;
    if (this.autoPlay === true) {
      el.autoplay = true;
    }
    this.startedPlaying = false;

    if (this._poster != null) {
      el.setAttribute("poster", this._poster);
    }

    el.addEventListener("play", this.handleElPlay);
    el.addEventListener("pause", this.handleElPause);
    el.addEventListener("volumechange", this.handleElVolumeChange);
    el.addEventListener("progress", this.handleProgress);
    timeupdateWrapper.wrap(el, this.handleTimeupdate);
    el.setAttribute("playsinline", "true");
    el.setAttribute("webkit-playsinline", "true");

    this.ctx.logger.debug("host element is initialized", {
      autoPlay: this.options.autoPlay,
      internalPaused: this.internalPaused,
      internalMuted: this.internalMuted
    });

    if (this.autoPlay) {
      this.ctx.logger.trace("core: initVideoEl() -> await play()");
      await this.play();
    }
  }

  private cleanVideoEl(debugString: string, el: VideoElement | null = this.hostEl): void {
    if (el == null) {
      return;
    }

    el.removeEventListener("play", this.handleElPlay);
    el.removeEventListener("pause", this.handleElPause);
    el.removeEventListener("volumechange", this.handleElVolumeChange);
    el.removeEventListener("progress", this.handleProgress);
    timeupdateWrapper.unwrap(el);
    el.src = "";
    el.srcObject = null;
    el.setAttribute("poster", "");
  }

  private handleProgress(): void {
    if (device.isImplements(AdapterFeature.DEBUGGING)) {
      this.counters.lastProgress = device.performance.now();
    } else {
      this.counters.lastProgress = Date.now();
    }
    this.emit("progress");
  }

  private handleTimeupdate(): void {

    if (this.hostEl != null) {
      const isVideoPlaying =
        this.hostEl.currentTime > 0 && !this.hostEl.paused && !this.hostEl.ended && this.hostEl.readyState > 2;

      if (isVideoPlaying && !this.startedPlaying) {
        this.startedPlaying = true;
        this.emit("videoFirstPlay");
      }
    }

    this.counters.fragCounts += 1;
    this.emit("timeupdate");
  }

  private resetCounts(): void {
    this.counters = emptyCounters;
  }

  /**
   * Returns a list of buffer times
   */
  protected *bufferTimes(): Generator<[number, number]> {
    const range = this.hostEl?.buffered?.length ?? 0;
    for (let i = 0; this.hostEl != null && i < range; i++) {
      yield [this.hostEl.buffered.start(i), this.hostEl.buffered.end(i)];
    }
  }

  /**
   * gets the details
   * @return {object} Details contract
   */
  get details(): ContractDetails {
    return {
      bufferOverflowCount: this.counters.bufferOverflowCount,
      bufferUnderflowCount: this.counters.bufferUnderflowCount,
      downshift: this.counters.downshift,
      errors: this.errors ?? {},
      muted: this.localAudioMuted,
      segments: this.counters.fragCounts,
      segmentsSize: this.counters.fragSize,
      segmentsDuration: this.counters.fragDuration,
      segmentTotalDownloadTime: this.counters.fragDownloadTime,
      segMaxTime: this.counters.fragMaxTime,
      segMinTime: this.counters.fragMinTime,
      upshift: this.counters.upshift,
      volume: this.localAudioVolume,
    };
  }

  async restart(immediate: boolean): Promise<void> {
    this.ctx.logger.trace("restart()", { immediate });
    if (this.lockRestart) {
      return;
    }
    this.lockRestart = true;
    this.counters.currentErrorCount = 0;
    this.counters.recentErrorCount = 0;
    this.counters.restartCount += 1;

    if (!immediate) {
      device.clearTimeout(this.clearRestartCount ?? 0);
      this.clearRestartCount = device.setTimeout(() => {
        this.counters.restartCount = 0;
      }, BACK_OFF[BACK_OFF.length - 1] * 2);

      this.emit("restartDriver", {
        timeout: BACK_OFF[Math.min(this.counters.restartCount, BACK_OFF.length - 1)],
      });

      return this.restartTimeout();
    } else {
      return new Promise<void>((r) => {
        this.stop();
        try {
          this.play(immediate).then(() => {
            this.lockRestart = false;
            r();
          });
        } catch (err) {
          this.ctx.logger.error("An error occured on the play() request", { err: wrapNativeError(err) });
        }
      });
    }
  }

  protected async restartTimeout(): Promise<void> {
    return new Promise((r) => {
      device.setTimeout(() => {
        if (this.isDisposed) {
          return;
        }
        this.stop();
        try {
          this.play().then(() => {
            this.lockRestart = false;
            r();
          });
        } catch (err) {
          this.ctx.logger.error("An error occured on the play() request", { err: wrapNativeError(err) });
        }
      }, BACK_OFF[Math.min(this.counters.restartCount, BACK_OFF.length - 1)]);
    });
  }

  protected stop(): void {
    if (this.hostEl != null) {
      this.hostEl.src = "";
      this.hostEl.srcObject = null;
    }
  }

  async play(immediately = false): Promise<boolean> {
    this.ctx.logger.trace("play()", { videoEl: dumpVideoElement(this.hostEl as VideoElement), aggregates: { debug: "play"} });

    await this.ready();
    this.ctx.logger.debug("ready to play", { aggregates: { debug: "play"} });

    if (this.hostEl == null) {
      this.ctx.logger.warn("a try to play without host element attached", { aggregates: { debug: "play"} });
      return false;
    }
    if ((this.hostEl.src == null || this.hostEl.src === "") && this.hostEl.srcObject == null) {
      this.ctx.logger.debug("nothing to play", { aggregates: { debug: "play"} });
      return false;
    }

    if (this.playingPromise == null) {
      this.ctx.logger.setMessageAggregate("debug", "play");
      this.ctx.logger.setMessageAggregate("playImmediately", immediately);
      this.ctx.logger.setMessageAggregate("hasPlayingPromise", this.playingPromise != null);

      this.playingPromise = this.playInternal(this.hostEl, immediately).then(() => {
            this.ctx.logger.trace("playingPromise.then()");
            return !this.hostEl?.paused
          }
      ).catch((err) => {
        this.ctx.logger.trace("playingPromise.catch()", {err: wrapNativeError(err)});
        return err;
      }).finally(() => {
        this.ctx.logger.trace("playingPromise.finally()");
        this.playingPromise = null;

        this.ctx.logger.removeMessageAggregate("debug");
        this.ctx.logger.removeMessageAggregate("playImmediately");
        this.ctx.logger.removeMessageAggregate("hasPlayingPromise");
      });
    }

    return this.playingPromise;
  }

  private async playInternal(el: HostElement, immediately = false): Promise<void> {
    this.ctx.logger.trace("playInternal()")

    if(isMediaStream(el.srcObject) && el.srcObject?.getTracks().length <= 0) {
      return;
    }

    const playingDelegate = immediately
      ? el.play.bind(el)
      : playOncePossible.bind(null, el, this.ctx.logger);

    try {
      this.ctx.logger.trace("playInternal() trying playDelegate (first time)")
      await playingDelegate();
    } catch (err) {
      if (err instanceof Error) {
        const ctx = { inner: wrapNativeError(err), playImmediately: immediately, player: this };
        this.ctx.logger.warn(new PlayingIssueError("playDelegate first failure", ctx));
        // conditions to try to play again when the page has low user engagement

        if ((err.name === "AbortError" || err.name === "NotAllowedError") && !this.localAudioMuted && this.isImplements(PlayerFeature.MUTED_AUTOPLAY)) {
          // try one more time but muted
          this.localAudioMuted = true;
          try {
            await playingDelegate();
            this.ctx.logger.info("playDelegate playing muted due to low page engagement", ctx);
          } catch (internalErr) {
            this.localAudioMuted = false;
            this.throwError(new PlayingIssueError("playDelegate error after mute play", ctx));
          }
          this.forcedMute = true;
        } else {
          // try one more time just in case
          try {
            await playingDelegate();
            this.ctx.logger.info("playDelegate playing (one more time just in case)", ctx);
          } catch (internalErr) {
            this.throwError(new PlayingIssueError("playDelegate error after second try", ctx));
          }
        }
      } else {
        this.ctx.logger.info("playDelegate unknown error type", {
          errType: typeof err,
          player: this,
        });
        throw err;
      }
    } finally {
      this.ctx.logger.trace("playInternal() finally");
      this.internalPaused = el.paused;
    }
  }

  /**
   * @param {object} [inputData]
   * @return {void}
   */
  async checkRestart(inputData: { fatal: boolean }): Promise<void> {
    // In cases where a machine went to sleep and came back online,
    // the player is in a state where it constantly emits the buffer-stalled
    // error.	The only way I know how to reinstantiate the player is to
    // watch for errors within a timeframe
    const data = inputData;
    this.counters.recentErrorCount += 1;
    device.setTimeout(() => {
      this.counters.recentErrorCount -= 1;
      this.counters.recentErrorCount = Math.max(this.counters.recentErrorCount, 0);
    }, 10000);
    if (this.counters.recentErrorCount >= /* this.options.recoverErrorCount */ 10) {
      data.fatal = true;
    }

    if (this.counters.currentErrorCount >= /* this.options.recoverErrorCount */ 10) {
      data.fatal = true;
    }

    if (data.fatal /* && !this.mediaLoader.vod */) {
      return this.restart(false);
    }
  }


  setPreferredLevel(level: TranscodeScoreLevel | SourceScoreLevel): void {
    const qty = findClosestQuality(level, this.availableQualities);
    if (qty == null) {
      this.ctx.logger.debug("quality not found", { level, availableQualities: this.availableQualities });
      return;
    }

      this.ctx.logger.debug("preferred level selected", { level: level, quality: qty });

    this.currentQuality = qty;
  }

  isImplements<K extends keyof Features, T extends Features[K]>(feature: K): this is T {
    return this.implementedFeatures.includes(feature);
  }

  toJSON(): Json {
    return {
      availableQualities: toJS(this.availableQualities),
      currentQuality: toJS(this.currentQuality),
      attached: this.attached,
      counters: this.counters,

      aggregates: {
        support: this.ctx.support.hash,
        autoPlay: this.autoPlay,
        localAudioMuted: this.localAudioMuted,
        localVideoPaused: this.localVideoPaused,
        playingPromise: this.playingPromise == null,
        format: this.format,
        ...dumpVideoElement(this.hostEl as VideoElement),
      },
    };
  }
}

import React, { FC } from "react";
import { IconProps } from "./iconProps";

const Configuration: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="configuration"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M164.525 134.286H74.75V130C74.75 127.643 72.8262 125.714 70.475 125.714H61.925C59.5737 125.714 57.65 127.643 57.65 130V134.286H36.275C33.9237 134.286 32 136.214 32 138.571V147.143C32 149.5 33.9237 151.429 36.275 151.429H57.65V155.714C57.65 158.071 59.5737 160 61.925 160H70.475C72.8262 160 74.75 158.071 74.75 155.714V151.429H164.525C166.876 151.429 168.8 149.5 168.8 147.143V138.571C168.8 136.214 166.876 134.286 164.525 134.286ZM164.525 91.4286H143.15V87.1429C143.15 84.7857 141.226 82.8571 138.875 82.8571H130.325C127.974 82.8571 126.05 84.7857 126.05 87.1429V91.4286L36.275 91.4286C33.9237 91.4286 32 93.3571 32 95.7143V104.286C32 106.643 33.9237 108.571 36.275 108.571L126.05 108.571V112.857C126.05 115.214 127.974 117.143 130.325 117.143H138.875C141.226 117.143 143.15 115.214 143.15 112.857V108.571H164.525C166.876 108.571 168.8 106.643 168.8 104.286V95.7143C168.8 93.3571 166.876 91.4286 164.525 91.4286ZM164.525 48.5714L108.95 48.5714V44.2857C108.95 41.9286 107.026 40 104.675 40L96.125 40C93.7737 40 91.85 41.9286 91.85 44.2857V48.5714L36.275 48.5714C33.9237 48.5714 32 50.5 32 52.8571V61.4286C32 63.7857 33.9237 65.7143 36.275 65.7143L91.85 65.7143V70C91.85 72.3571 93.7737 74.2857 96.125 74.2857L104.675 74.2857C107.026 74.2857 108.95 72.3571 108.95 70V65.7143L164.525 65.7143C166.876 65.7143 168.8 63.7857 168.8 61.4286V52.8571C168.8 50.5 166.876 48.5714 164.525 48.5714Z"
      />
    </svg>
  );
};

export default Configuration;

import { isIosDevice, isSafari, RecorderCore } from "@livelyvideo/video-client-core";
// import { RecorderOptions } from "../../../../core/src/recorder"
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useContext, useEffect } from "react";
import { RecorderUiState } from "../../../../store/recorder";
import { LivelyRecorderUiContext } from "../../../context";
import { mergeStyles, mergeStylesObjects } from "../../../livelyStyles";
import { ErrorBoundary, useUndefinedStoreError } from "../../ErrorBoundary";
import RecordDeleteButton from "../Buttons/Recorder/Delete/Delete";
import RecordPauseButton from "../Buttons/Recorder/Pause/Pause";
import RecordButton from "../Buttons/Recorder/Record/Record";
import RecordSaveButton from "../Buttons/Recorder/Save/Save";
import RecordStartButton from "../Buttons/Recorder/Start/Start";
import styles from "./styles";

type ModularRecorderProps = {
  stream?: MediaStream;
  classes?: Record<any, any>;
  mimetype?: string;
  frameRate?: number;
  displaySurface?: "application" | "browser" | "monitor" | "window" | "all";
  cursor?: "always" | "motion" | "never";
  audioBitsPerSecond?: number;
  videoBitsPerSecond?: number;
  startRecordingWhenClicked?: boolean;
  height?: number;
  width?: number;
  recordButtonIcon?: ReactElement;
  startButtonIcon?: ReactElement;
  pauseButtonIcon?: ReactElement;
  deleteButtonIcon?: ReactElement;
  saveButtonIcon?: ReactElement;
};

const ModularRecorder = observer(
  ({
    stream,
    classes = {
      root: {},
      recordButton: {},
      pauseButton: {},
      startButton: {},
      deleteButton: {},
      saveButton: {},
    },
    recordButtonIcon,
    startButtonIcon,
    pauseButtonIcon,
    deleteButtonIcon,
    saveButtonIcon,
    startRecordingWhenClicked,
    frameRate,
    audioBitsPerSecond,
    videoBitsPerSecond,
    displaySurface,
    cursor,
    mimetype,
    height,
    width,
  }: ModularRecorderProps) => {
    const ctx = useContext<RecorderUiState | null>(LivelyRecorderUiContext);

    useUndefinedStoreError((): boolean => ctx === undefined, "<Recorder/>");

    const mergedClasses = mergeStyles({ source: classes, target: styles }, { stylesNamespace: "recorder" });
    const mergedStyles = mergeStylesObjects(classes, styles);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const recorderOptions = {
      startRecordingWhenClicked,
      audioBitsPerSecond,
      videoBitsPerSecond,
      frameRate,
      displaySurface,
      cursor,
      mimetype,
      height,
      width,
    };

    if (mimetype == null || mimetype === "") {
      // If browser is safari
      if (isSafari()) {
        recorderOptions.mimetype = "video/mp4;codecs=avc1";
      } else if (isIosDevice()) {
        recorderOptions.mimetype = "video/mp4";
      } else {
        recorderOptions.mimetype = "video/webm;codecs=VP8";
      }
    }

    useEffect(() => {
      autorun(() => {
        if (ctx?.recorder == null) {
          const recorder = new RecorderCore(stream ?? null, recorderOptions);
          recorder.on("browser-stop", () => {
            if (ctx?.recordingReady) {
              ctx?.saveRecording();
            }
            ctx?.browserStopEvent();
          });
          recorder.on("save-canceled", () => {
            ctx?.setRecordingReady();
          });
          ctx?.setRecorder(recorder);
        }
      });
    });

    useEffect(() => {
      autorun(() => {
        if (ctx?.recorder != null && stream != null) {
          ctx?.closeRecording();
          const recorder = new RecorderCore(stream, recorderOptions);
          recorder.on("browser-stop", () => {
            if (ctx?.recordingReady) {
              ctx?.saveRecording();
            }
            ctx?.browserStopEvent();
          });
          recorder.on("save-canceled", () => {
            ctx?.setRecordingReady();
          });
          ctx?.setRecorder(recorder);
        }
      });
    }, [
      startRecordingWhenClicked,
      audioBitsPerSecond,
      videoBitsPerSecond,
      displaySurface,
      cursor,
      ctx,
      stream,
      recorderOptions,
    ]);

    return (
      <div className={mergedClasses.root}>
        {((!ctx?.recording && !ctx?.recordingReady) || (ctx?.recording && !ctx?.recordingReady)) && (
          <RecordButton
            classes={mergedStyles.recordButton}
            icon={recordButtonIcon}
            onClick={() => ctx?.setupRecorder(startRecordingWhenClicked)}
          />
        )}
        {ctx?.recordingReady && (
          <RecordDeleteButton
            classes={mergedStyles.deleteButton}
            icon={deleteButtonIcon}
            onClick={ctx?.closeRecording}
          />
        )}
        {ctx?.recordingReady && !ctx?.recording && (
          <RecordStartButton
            icon={startButtonIcon}
            classes={mergedStyles.startButton}
            onClick={ctx?.record}
            disabled={ctx?.resumeDisabled}
          />
        )}
        {ctx?.recordingReady && ctx?.recording && (
          <RecordPauseButton classes={mergedStyles.pauseButton} icon={pauseButtonIcon} onClick={ctx?.pauseRecording} />
        )}
        {ctx?.recordingReady && (
          <RecordSaveButton classes={mergedStyles.saveButton} icon={saveButtonIcon} onClick={ctx?.saveRecording} />
        )}
      </div>
    );
  },
);

const RecorderWithErrorBoundary = (props: ModularRecorderProps): ReactElement => {
  return (
    <ErrorBoundary
      render={() => (
        <div style={{ height: "600px", width: "100%" }}>
          <p>Recorder is not working</p>
        </div>
      )}
    >
      <ModularRecorder {...props} />
    </ErrorBoundary>
  );
};

export default RecorderWithErrorBoundary;

import { mediaController } from "@livelyvideo/video-client-core";
import { observer } from "mobx-react-lite";
import React, { createRef, ReactElement, Ref, useContext } from "react";
import { EncoderUiState } from "../../../../../store/encoder";
import { LivelyEncoderUiContext } from "../../../../context";
import { mergeStyles, mergeStylesObjects } from "../../../../livelyStyles";
import Select, { SelectProps } from "../../../../ui-lib/Inputs/Select";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

const ModularVideoDeviceSelect = observer(({ classes = {}, ...props }: Partial<SelectProps>) => {
  const { label } = props;
  const componentName = "<VideoDeviceSelect/>";

  const mergedClasses = mergeStyles({ source: classes, target: {} }, { stylesNamespace: "select" });
  const mergedStyles = mergeStylesObjects(classes, {});

  const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

  const selectRef: Ref<HTMLSelectElement> = createRef();

  /**
   * On input change handler to (1) update API and (2) update component
   * <select> value with user selected video device.
   */
  const selectCamera = (): void => {
    if (selectRef?.current?.value != null && ctx?.mediaStreamController != null) {
      ctx.mediaStreamController.resolution = null;
      ctx.mediaStreamController.videoDeviceId = selectRef.current.value;
    }
  };

  // If store is undefined, throw an error
  const hasUndefinedStore: () => boolean = () =>
    !!(!ctx?.mediaStreamController || mediaController.videoDevices() == null);
  useUndefinedStoreError(hasUndefinedStore, componentName);
  // Use global Error handler (to trigger ErrorBoundary).
  const handleInputChange = useUIEventError(selectCamera, componentName);

  return (
    <Select
      classes={mergedStyles}
      ref={selectRef}
      disabled={!ctx?.mediaStreamController || mediaController.videoDevices() == null}
      onChange={handleInputChange}
      value={ctx?.mediaStreamController?.videoDeviceId ?? ""}
      label={label ?? "Camera"}
      {...props}
    >
      <option disabled value="">
        Select a camera
      </option>
      {mediaController.videoDevices()?.map((item: { deviceId: string; label: string }, key: number) => (
        <option key={item.deviceId} value={item.deviceId} className={mergedClasses.options}>
          {item.label || `Camera ${key + 1}`}
        </option>
      ))}
      ;
    </Select>
  );
});

const VideoDeviceSelectWithErrorBoundary = ({ classes = {}, ...props }: Partial<SelectProps>): ReactElement => {
  const mergedClasses = mergeStyles({ source: classes, target: {} }, { stylesNamespace: "select" });
  const mergedStyles = mergeStylesObjects(classes, {});

  return (
    <ErrorBoundary
      render={() => (
        <Select {...props} disabled classes={mergedStyles} label="Video device unavailable">
          <option value="" className={mergedClasses?.options}>
            {props.fallbackText ?? "Video Devices Unavailable"}
          </option>
        </Select>
      )}
    >
      <ModularVideoDeviceSelect classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default VideoDeviceSelectWithErrorBoundary;

import { mediaController } from "@livelyvideo/video-client-core";
import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { EncoderUiState } from "../../../../../store";
import { LivelyEncoderUiContext } from "../../../../context";
import { mergeStyles, mergeStylesObjects } from "../../../../livelyStyles";
import Select, { SelectProps } from "../../../../ui-lib/Inputs/Select";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

/**
 * @todo: Possibly refactor Select Components
 *
 */
const ModularAudioDeviceSelect = observer(({ classes = {}, ...props }: Partial<SelectProps>) => {
  const { label } = props;

  /**
   * Component Name (for error msg)
   */
  const componentName = "<AudioDeviceSelect/>";

  /**
   * Access LivelyEncoderUiContext & destructure API state
   */
  const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

  /**
   * Local component state and Ref for <select> value
   * */
  const selectRef: React.Ref<HTMLSelectElement> = React.createRef();

  /**
   * On input change handler to (1) update API and (2) update component
   * <select> value with user selected audio device.
   */
  const selectMicrophone: () => void = () => {
    if (selectRef?.current?.value != null && ctx?.mediaStreamController != null) {
      ctx.mediaStreamController.audioDeviceId = selectRef.current.value;
    }
  };

  const mergedClasses = mergeStyles({ source: classes, target: {} }, { stylesNamespace: "select" });
  const mergedStyles = mergeStylesObjects(classes, {});

  /**
   * Assess conditions for an undefined store.
   *
   * @returns {boolean}
   * */
  const hasUndefinedStore: () => boolean = (): boolean => {
    return !ctx?.mediaStreamController || mediaController.audioDevices() == null;
  };

  /**
   * Throw error (and trigger ErrorBoundary) if store is undefined.
   * */
  useUndefinedStoreError(hasUndefinedStore, componentName);

  /**
   * Wrap onChange function in global Error handler (to trigger ErrorBoundary).
   * */
  const handleInputChange = useUIEventError(selectMicrophone, componentName);

  return (
    <Select
      classes={mergedStyles}
      disabled={!ctx?.mediaStreamController || mediaController.audioDevices() == null}
      onChange={handleInputChange}
      ref={selectRef}
      value={ctx?.mediaStreamController?.audioDeviceId ?? ""}
      label={label ?? "Mic"}
      {...props}
    >
      <option disabled value="" className={mergedClasses.option}>
        Select a microphone
      </option>
      {mediaController.audioDevices()?.map((item: { deviceId: string; label: string }, key: number) => (
        <option key={item?.deviceId} value={item?.deviceId} className={mergedClasses?.option}>
          {item?.label || `Microphone ${key + 1}`}
        </option>
      ))}
    </Select>
  );
});

const AudioDeviceSelectWithErrorBoundary: FC<Partial<SelectProps>> = ({ classes = {}, ...props }) => {
  const mergedClasses = mergeStyles({ source: classes, target: {} }, { stylesNamespace: "select" });
  const mergedStyles = mergeStylesObjects(classes, {});

  return (
    <ErrorBoundary
      render={() => (
        <Select {...props} value="" classes={mergedStyles} disabled label="Audio Device: Unavailable">
          <option value="" className={mergedClasses?.options}>
            {props.fallbackText ?? "Audio Devices Unavailable"}
          </option>
        </Select>
      )}
    >
      <ModularAudioDeviceSelect classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default AudioDeviceSelectWithErrorBoundary;

import React, { FC } from "react";
import { IconProps } from "./iconProps";

const Microphone: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="microphone"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <title>{rest.title}</title>
      <path
        fill="currentColor"
        d="M100.004 124.659C113.738 124.659 124.872 113.619 124.872 100V58.9016C124.872 45.2826 113.738 34.2426 100.004 34.2426C86.2693 34.2426 75.1355 45.2826 75.1355 58.9016V100C75.1355 113.619 86.2693 124.659 100.004 124.659ZM141.451 83.5606H137.306C135.016 83.5606 133.161 85.3998 133.161 87.6705V100C133.161 119.214 116.456 134.631 96.6906 132.719C79.4642 131.052 66.8461 115.697 66.8461 98.5359V87.6705C66.8461 85.3998 64.9914 83.5606 62.7014 83.5606H58.5567C56.2667 83.5606 54.412 85.3998 54.412 87.6705V97.9862C54.412 121.012 70.9831 141.538 93.7867 144.656V153.428H79.2802C76.9903 153.428 75.1355 155.267 75.1355 157.538V161.648C75.1355 163.918 76.9903 165.757 79.2802 165.757H120.727C123.017 165.757 124.872 163.918 124.872 161.648V157.538C124.872 155.267 123.017 153.428 120.727 153.428H106.221V144.754C128.424 141.733 145.596 122.835 145.596 100V87.6705C145.596 85.3998 143.741 83.5606 141.451 83.5606Z"
      />
    </svg>
  );
};

export default Microphone;

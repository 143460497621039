import { RootStyles, StylesObject } from "../../typings/css";

export interface CloseButtonStyles extends RootStyles {
  svg?: StylesObject;
}

const styles: CloseButtonStyles = {
  root: {
    alignContent: "center",
    backgroundColor: "#f5f5f5",
    color: (iconColor: string): string => iconColor || "#525252",
    cursor: "pointer",
    justifyContent: "center",
    padding: "0",
    transitionDuration: ".15s",
    transitionProperty: "color, background-color, border",
    transitionTimingFunction: "linear",

    "&.close": {
      // Reset button styles to avoid rendering an actual button
      background: "none",
      border: "none",
      display: "inline-block",
      textDecoration: "none",
      "-webkit-appearance": "none",
    },

    "& svg": {
      width: "15px",
    },
  },
};

export default styles;

import { types, VideoClient } from "@livelyvideo/video-client-core";
import { createContext } from "react";
import { EncoderUiState } from "../../store/encoder";
import { PlayerUiState } from "../../store/player";
import { RecorderUiState } from "../../store/recorder";

const LivelyEncoderUiContext = createContext<EncoderUiState | null>(null);
const LivelyPlayerUiContext = createContext<PlayerUiState | null>(null);
const LivelyCallContext = createContext<types.BaseCall | null>(null);
const LivelyRecorderUiContext = createContext<RecorderUiState | null>(null);
const LivelyVideoClientContext = createContext<VideoClient | null>(null);

export {
  LivelyEncoderUiContext,
  LivelyCallContext,
  LivelyPlayerUiContext,
  LivelyRecorderUiContext,
  LivelyVideoClientContext,
};

import { types, VideoClient } from "@livelyvideo/video-client-core";
import { observer } from "mobx-react-lite";
import React, { FC, ReactElement, useContext, useState } from "react";
import { EncoderUiState } from "../../../../../../store/encoder";
import { LivelyEncoderUiContext, LivelyVideoClientContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon, { IconStyleProps } from "../../../../../ui-lib/Icons";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface JoinBroadcastButtonClasses extends RootStyles {
  icon?: IconStyleProps;
}

interface JoinBroadcastButtonProps extends Partial<ButtonIconProps> {
  broadcastOptions: types.BroadcastOptions;
  classes?: JoinBroadcastButtonClasses;
  setCallId?: (id: string | null) => void;
  callId?: string | null;
  onZeroBitrate?: (ev: types.BroadcastEvents["zeroBitrate"]) => void;
  setOwnerCall?: (call: types.BaseCall | null) => void;
  setCall?: (call: types.BaseCall | null) => void;
  clientReferrer?: string;
  sfuOptions?: types.SFUOptions;
}


const ModularJoinBroadcastButton = observer(
  ({
    icon: ProvidedIcon,
    onClick,
    screenReaderText,
    broadcastOptions,
    onZeroBitrate,
    active,
    classes = {
      root: {},
      icon: {},
    },
    label = "Stream Broadcast Toggle Button",
    setCallId,
    callId,
    setOwnerCall,
    setCall,
    clientReferrer,
    sfuOptions,
    ...props
  }: JoinBroadcastButtonProps): ReactElement => {
    const componentName = "<BroadcastButton/>";

    const { streamName } = broadcastOptions;
    /**
     * Access LivelyEncoderUiContext & destructure API state
     */
    const encoderCtx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);
    /**
     * Grab the videoClient supplied from the encoder.
     */
    const videoClient = useContext<VideoClient | null>(LivelyVideoClientContext);

    // @todo why it grab userId from stats options here?
    const videoUserId = (videoClient as any)?.options?.stats?.userId;
    /**
     * Stores the call for both the owner and call participants. For the owner it is stored on creation, for participants it is stored on join.
     */
    const [call, setCallState] = useState<types.BaseCall | undefined>();
    /**
     * Boolean watching if we are broadcasting or not, this is set from the onClick method.
     */
    const [broadcast, setBroadcast] = useState<boolean>(false);
    /**
     * Let's the broadcaster know if we are the owner call or dealing with a participant.
     */
    const [isOwner, setIsOwner] = useState<boolean>(false);

    /**
     * Assess conditions for an undefined store. Throw error if store is undefined.
     */
    const hasUndefinedStore = (): boolean => encoderCtx?.mediaStreamController === undefined;
    useUndefinedStoreError(hasUndefinedStore, componentName);

    /**
     *  Checks to see if we are actively streaming
     */
    const broadcasting: boolean =
      (call?.broadcasts?.has(streamName) && call?.broadcasts?.get(streamName)?.state === "active") ?? false;
    const activeState = active ?? broadcast;

    /**
     * When toggled on it will begin the broadcast, when toggled off it closes the broadcast.
     * Accepts two arguements, the current owner call and the broadcast boolean, this is required to avoid race conditions when setting local state.
     * If Call Owner sets broadcast to off then we close and dispose of the call.
     */
    const handleBroadcast: (isBroadcast: boolean, ownerCall: types.BaseCall) => void = (isBroadcast, ownerCall) => {
      if (isBroadcast && ownerCall != null && encoderCtx?.mediaStreamController != null && !broadcasting) {
        ownerCall.broadcast(encoderCtx.mediaStreamController, broadcastOptions);
        if (onZeroBitrate != null) {
          ownerCall.on("zeroBitrate", onZeroBitrate);
        }
      } else if (!isBroadcast && encoderCtx?.mediaStreamController != null && broadcasting) {
        if (ownerCall != null) {
          if (onZeroBitrate != null) {
            ownerCall.off("zeroBitrate", onZeroBitrate);
          }

          ownerCall?.broadcasts
            ?.get(streamName)
            ?.dispose(`stream [streamName: ${streamName}] disposed via JoinBroadcast Button`);
          if (isOwner) {
            call?.broadcasts.forEach((item) => {
              if (item.state === "active") {
                item.dispose("stream disposed via JoinBroadcast Button");
              }
            });
            call?.dispose("call disposed via JoinBroadcast Button");

            setCallState(undefined);
            if (setCallId) {
              setCallId(null);
            }
          }
        }
      } else if (ownerCall != null) {
        ownerCall?.broadcasts
          ?.get(streamName)
          ?.dispose(`stream [streamName: ${streamName}] disposed via JoinBroadcast Button`);
      }
    };

    /**
     * If we are the OWNER we create a call and set the call ID for the owner. If we are a pariticpant we join the owner call.
     * We are also setting the OWNER call ID here, which is also the prop callId, this prop is set for the owner at the parent and then passed to participants.
     */
    const handleJoinBroadcast: () => void = () => {
      if (call != null) {
        handleBroadcast(!broadcast, call);
        setBroadcast(!broadcast);
      }
      if (!broadcast && call == null && videoClient != null && callId == null) {
        (async () => {
          const newCall = await videoClient?.createCall({
            userId: videoUserId,
            clientReferrer,
            sfu: sfuOptions,
          });

          setCallState(newCall);
          if (setCallId && newCall != null) {
            setCallId(newCall.id);
          }
          if (setOwnerCall && newCall != null) {
            setOwnerCall(newCall);
          }
          if (setCall && newCall != null) {
            setCall(newCall);
          }
          setIsOwner(true);
          setBroadcast(!broadcast);
          handleBroadcast(!broadcast, newCall);
        })();
      } else if (!broadcast && call === undefined && videoClient != null && callId != null) {
        (async () => {
          const newCall = await videoClient.joinCall(callId, sfuOptions);
          newCall.on("viewerKicked", () => {
            handleBroadcast(false, newCall);
            setBroadcast(false);
          });
          if (setCall && newCall != null) {
            setCall(newCall);
          }
          setCallState(newCall);
          setBroadcast(!broadcast);
          handleBroadcast(!broadcast, newCall);
        })();
      }
    };

    const toggleBroadcast = (): void => {
      handleJoinBroadcast();
    };

    /**
     * handleClick function wrapped in global Error handler (to trigger ErrorBoundary).
     * */
    const handleClick = onClick ?? useUIEventError(toggleBroadcast, componentName);
    const icon = ProvidedIcon ?? <Icon iconName="broadcast" classes={classes?.icon} />;

    return (
      <ButtonIcon
        active={activeState}
        activeClass="lv-button--active"
        classes={classes}
        inactiveClass={null}
        data-selenium="broadcast-stream-button"
        icon={icon}
        onClick={handleClick}
        disabled={
          encoderCtx?.mediaStreamController == null ||
          (encoderCtx?.mediaStreamController?.videoDeviceId == null &&
            encoderCtx?.mediaStreamController?.audioDeviceId == null)
        }
        label={label}
        {...props}
      >
        {screenReaderText ?? `Click to ${activeState ? "Stop" : "Start"} Broadcast`}
      </ButtonIcon>
    );
  },
);

/**
 *
 * @visibleName Broadcast Button
 */
const JoinBroadcastButtonWithErrorBoundary: FC<JoinBroadcastButtonProps> = ({
  icon: ProvidedIcon,
  label = "Stream Broadcast Toggle Button",
  screenReaderText,
  broadcastOptions,
  classes,
  ...props
}) => {
  const icon = <Icon iconName="broadcast" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          data-selenium="broadcast-stream-button"
          disabled
          classes={classes}
          icon={ProvidedIcon ?? icon}
          label={label}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to start Broadcast"}
        </ButtonIcon>
      )}
    >
      <ModularJoinBroadcastButton
        icon={ProvidedIcon}
        classes={classes}
        label={label}
        screenReaderText={screenReaderText}
        broadcastOptions={broadcastOptions}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default JoinBroadcastButtonWithErrorBoundary;

import React, { FC } from "react";
import { IconProps } from "../iconProps";

const Pause: FC<IconProps> = ({ className }) => {
  // Generated by IcoMoon.io // Material Icons v3
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="pause"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      {/* Generated by IcoMoon.io // Material Icons v3 */}
      <path
        fill="currentColor"
        d="M28.031 10.031h7.969v27.938h-7.969v-27.938zM12 37.969v-27.938h7.969v27.938h-7.969z"
      />
    </svg>
  );
};

export default Pause;

import React, { FC, ReactChild } from "react";
import { mergeStyles } from "../../livelyStyles";
import Clock from "../Icons/Clock";
import { RootStyles } from "../typings/css";
import styles from "./styles";

export interface DurationLabelProps {
  children: ReactChild;
  classes?: RootStyles;
}

const DurationLabel: FC<DurationLabelProps> = ({ children, classes = {}, ...rest }) => {
  const mergedClasses = mergeStyles({ source: classes, target: styles }, { stylesNamespace: "durationLabel" });

  return (
    <div className={mergedClasses.root}>
      <span className={`${mergedClasses.icon} lv-icon`}>
        <Clock />
      </span>
      <span className={`${mergedClasses.time} lv-time`}>{children}</span>
    </div>
  );
};

export default DurationLabel;

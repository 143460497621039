const STATS_EVENTS = {
  AUTOPLAY_PROGRESS: "autoplay-first-progress",
  FIRST_LOAD: "first-load",
  MANIFEST_LOAD: "manifest-load",
  HLSJS_INIT: "hlsjs-init",
  M3U8_MANIFEST_LOAD: "m3u8-manifest-load",
  INDEX_MANIFEST_LOAD: "index-manifest-load",
  INITIAL_FRAGMENT_LOAD: "initial-fragment-load",
  JOIN_CALL_ENDPOINT: "join-call-endpoint",
  SFU_CONNECTION: "sfu-connection",
  VIDEO_CONSUMER: "video-consumer",
  FIRST_TIME_UPDATE: "first-time-update",
};

export default STATS_EVENTS;

import {
  Aggregates,
  ClientBody,
  ClientGlobalContext,
  ClientLog,
  ClientPackageContext,
  GlobalMetaKeys,
  LEVELS,
  PackageMeta,
  PackageMetaKeys,
} from "@livelyvideo/log-node";
import { EventEmitter } from "events";
import throttle from "lodash.throttle";
import nodeFetch from "node-fetch";
import type { LoggerCore } from "./logger-core";
import packageJson from './package-json';
import byteLength from "./utils/byteLength";
import { meetsLevel } from "./utils/supportedLevels";
import { uuidv4 } from "./utils/uuid";

const globalScope =
  (globalThis as any) ??
  (function fn(this: any): any {
    return this;
  })();

export interface WriterOptions {
  host?: string;
  level: typeof LEVELS[number];
  maxRequestSize: number;
  limit: number;
  interval: number;
}

const defaultWriterOptions: WriterOptions = {
  maxRequestSize: 1024 * 1024,
  level: "warn",
  limit: 500,
  interval: 30000,
};

type BrowserFetch = typeof window.fetch;

export class LoggerGlobal extends EventEmitter {
  private static fetch: BrowserFetch = typeof window !== "undefined" ? window.fetch.bind(window) : (nodeFetch as any);

  private readonly loggers: LoggerCore[];

  private options: WriterOptions = defaultWriterOptions;

  private online: boolean;

  private readonly storage: ClientLog[] = [];

  private readonly packageContextStorage: ClientPackageContext[] = [];

  private fetch: BrowserFetch = typeof window !== "undefined" ? window.fetch : (nodeFetch as any);

  private readonly globalContext: ClientGlobalContext = {
    meta: {
      userAgent: typeof navigator !== "undefined" ? navigator.userAgent : "unknown",
      referrer: typeof document !== "undefined" ? document.referrer : "unknown",
      source: "client",
      logId: uuidv4(),
      loggerVer: packageJson.version,
    },
  };

  private readonly throttleSend: () => void;

  private watchTimeout: any = null;

  constructor() {
    super();

    this.loggers = [];
    this.online = true;

    // Method
    this.throttleSend = throttle(this.send, 5000);

    if (globalScope.LivelyHttpWriterV4) {
      return globalScope.LivelyHttpWriterV4;
    }

    globalScope.LivelyHttpWriterV4 = this;

    if (globalScope.addEventListener != null) {
      globalScope.addEventListener("unload", () => this.send());
      globalScope.addEventListener("online", () => {
        this.online = true;
        this.send();
      });
      global.addEventListener("offline", () => {
        this.online = false;
      });
    }
  }

  static setOptions(options: Partial<WriterOptions>): void {
    const gl = new LoggerGlobal();
    gl.setOptions(options);
  }

  setOptions(options: Partial<WriterOptions>): void {
    if (options.host != null) {
      this.options.host = options.host;
    }
    if (options.level != null) {
      this.options.level = options.level;
    }
    if (options.maxRequestSize != null) {
      this.options.maxRequestSize = options.maxRequestSize;
    }
    if (options.limit != null) {
      this.options.limit = options.limit;
    }
    if (options.interval != null) {
      this.options.interval = options.interval;
    }
    this.resetTimeout();
  }

  static setGlobalAggregate(key: string, value: string | number | boolean): void {
    const gl = new LoggerGlobal();
    gl.setGlobalAggregate(key, value);
  }

  setGlobalAggregate(key: string, value: string | number | boolean): void {
    if (this.globalContext.aggregates == null) {
      this.globalContext.aggregates = { [key]: value };
    } else {
      this.globalContext.aggregates[key] = value;
    }
  }

  static removeGlobalAggregate(key: string): void {
    const gl = new LoggerGlobal();
    gl.removeGlobalAggregate(key);
  }

  removeGlobalAggregate(key: string): void {
    if (this.globalContext.aggregates != null) {
      if (this.globalContext.aggregates[key] != null) {
        delete this.globalContext.aggregates[key];
        if (Object.keys(this.globalContext.aggregates).length === 0) {
          delete this.globalContext.aggregates;
        }
      }
    }
  }

  static clearGlobalAggregates(): void {
    const gl = new LoggerGlobal();
    gl.clearGlobalAggregates();
  }

  clearGlobalAggregates(): void {
    this.globalContext.aggregates = {};
  }

  static setGlobalMeta(key: typeof GlobalMetaKeys[number], value: string): void {
    const gl = new LoggerGlobal();
    gl.setGlobalMeta(key, value);
  }

  setGlobalMeta(key: typeof GlobalMetaKeys[number], value: string): void {
    if (this.globalContext.meta == null) {
      this.globalContext.meta = {
        userAgent: typeof navigator !== "undefined" ? navigator.userAgent : "unknown",
        referrer: typeof document !== "undefined" ? document.referrer : "unknown",
        source: "client",
        logId: uuidv4(),
        loggerVer: packageJson.version,
        [key]: value,
      };
    } else {
      this.globalContext.meta[key] = value;
    }
  }

  static removeGlobalMeta(key: typeof GlobalMetaKeys[number]): void {
    const gl = new LoggerGlobal();
    gl.removeGlobalMeta(key);
  }

  removeGlobalMeta(key: typeof GlobalMetaKeys[number]): void {
    if (this.globalContext.meta != null) {
      if (this.globalContext.meta[key] != null) {
        delete this.globalContext.meta[key];
      }
    }
  }

  resetTimeout(): void {
    if (this.options.interval) {
      if (this.watchTimeout != null) {
        if (typeof window !== "undefined") {
          window.clearTimeout(this.watchTimeout);
        } else {
          clearTimeout(this.watchTimeout);
        }
      }

      if (typeof window !== "undefined") {
        this.watchTimeout = window.setTimeout(this.send.bind(this), this.options.interval);
      } else {
        this.watchTimeout = setTimeout(this.send.bind(this), this.options.interval);
      }
    }
  }

  registerPackage(logger: LoggerCore): void {
    this.loggers.push(logger);
  }

  removePackage(logger: LoggerCore): void {
    const index = this.loggers.indexOf(logger);
    if (index > -1) {
      this.loggers.splice(index, 1);
    }
    this.loggers.push(logger);
  }

  add(log: ClientLog, logger?: LoggerCore): void {
    if (!meetsLevel(log.level, this.options.level)) {
      return;
    }

    this.storage.push(log);

    if (this.options.host == null || !this.online) {
      this.checkStorageLimit();
      return;
    }

    if (logger != null) {
      const index = this.packageContextStorage.indexOf(logger.packageContext);
      if (index === -1) {
        this.packageContextStorage.push(logger.packageContext);
      }
    }

    if (meetsLevel(log.level, "error")) {
      this.throttleSend();
    }
  }

  send(): void {
    if (this.storage.length === 0) {
      this.resetTimeout();
      return;
    }

    if (this.options.host == null) {
      this.checkStorageLimit();
      this.resetTimeout();
      return;
    }

    const messages = this.storage.splice(0, this.options.limit);

    const aggregates: Aggregates = {};
    const meta: PackageMeta = {};

    this.packageContextStorage.forEach((context: ClientPackageContext) => {
      if (context.aggregates != null) {
        Object.keys(context.aggregates).forEach((k: string) => {
          const v = context.aggregates?.[k];
          if (v != null) {
            aggregates[k] = v;
          }
        });
      }
      if (context.meta != null) {
        PackageMetaKeys.forEach((k: typeof PackageMetaKeys[number]) => {
          if (context.meta?.[k] != null) {
            meta[k] = context.meta[k];
          }
        });
      }
    });

    const packageContext: ClientPackageContext = {
      aggregates,
      meta,
    };

    this.packageContextStorage.splice(0, -1 * this.packageContextStorage.length);

    const body: ClientBody = {
      logs: messages,
      sendTime: new Date().toISOString(),
      context: {
        packageContexts: packageContext,
        globalContext: this.globalContext,
      },
    };

    let bodyJson: string;
    try {
      bodyJson = JSON.stringify(body);
    } catch (error) {
      this.add({
        level: "error",
        message: "Failed to stringify in logger",
        error: `${error}`,
        time: new Date().toISOString(),
      });
      this.resetTimeout();
      return;
    }

    if (byteLength(bodyJson) > this.options.maxRequestSize) {
      this.add({
        level: "error",
        message: `Body exceeds maxRequestSize. Dropped ${messages.length} logs.`,
        time: new Date().toISOString(),
      });
      this.resetTimeout();
      return;
    }

    LoggerGlobal.fetch(`${this.options.host}/client-logs/v4`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: bodyJson,
    })
      .then((response) => {
        if (!response.ok) {
          throw new TypeError(response.status.toString());
        }
        this.resetTimeout();
        return response;
      })
      .catch((error) => {
        this.add({
          level: "network",
          message: "Network failure.",
          error: `${error}`,
          time: new Date().toISOString(),
        });
        this.resetTimeout();
      });
  }

  checkStorageLimit(): void {
    if (this.storage.length > this.options.limit) {
      this.storage.slice(-1 * this.options.limit);
    }
  }
}

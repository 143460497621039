import type { ApplyConstrainsFeature } from "./apply-constrains-feature";
import type { AudioContextFeature } from "./audio-context";
import type { CpuUsageFeature } from "./cpu-usage";
import type { CreateCanvasElementFeature } from "./create-canvas-element";
import type { CreateVideoElementFeature } from "./create-video-element";
import type { DebuggingFeature } from "./debugging";
import type { HlsJsFeature } from "./hlsjs";
import type { LocalStorageFeature } from "./local-storage";
import type { MediaDeviceFeature } from "./media-device";
import type { MediaRecorderFeature } from "./media-recorder";
import type { MediaSourceFeature } from "./media-source";
import type { MediaStreamFeature } from "./media-stream";
import type { MpegtsFeature } from "./mpegts";
import type { NetworkInformationFeature } from "./network-information";
import type { PermissionsFeature } from "./permissions";
import type { ScreenOrientationFeature } from "./screen-orientation";
import type { WebSocketFeature } from "./web-socket";

export enum Feature {
  APPLY_CONSTRAINTS,
  AUDIO_CONTEXT,
  CPU_USAGE,
  CREATE_CANVAS_ELEMENT,
  CREATE_VIDEO_ELEMENT,
  DEBUGGING,
  HLSJS,
  LOCAL_STORAGE,
  MEDIA_DEVICE,
  MEDIA_RECORDER,
  MEDIA_SOURCE,
  MEDIA_STREAM,
  NETWORK_INFORMATION,
  SCREEN_ORIENTATION,
  WEB_SOCKET,
  PERMISSIONS,
  MPEGTS,
}

export interface Features {
  [Feature.APPLY_CONSTRAINTS]: ApplyConstrainsFeature;
  [Feature.AUDIO_CONTEXT]: AudioContextFeature;
  [Feature.CPU_USAGE]: CpuUsageFeature;
  [Feature.CREATE_CANVAS_ELEMENT]: CreateCanvasElementFeature;
  [Feature.CREATE_VIDEO_ELEMENT]: CreateVideoElementFeature;
  [Feature.DEBUGGING]: DebuggingFeature;
  [Feature.HLSJS]: HlsJsFeature;
  [Feature.LOCAL_STORAGE]: LocalStorageFeature;
  [Feature.MEDIA_DEVICE]: MediaDeviceFeature;
  [Feature.MEDIA_RECORDER]: MediaRecorderFeature;
  [Feature.MEDIA_SOURCE]: MediaSourceFeature;
  [Feature.MEDIA_STREAM]: MediaStreamFeature;
  [Feature.NETWORK_INFORMATION]: NetworkInformationFeature;
  [Feature.SCREEN_ORIENTATION]: ScreenOrientationFeature;
  [Feature.WEB_SOCKET]: WebSocketFeature;
  [Feature.PERMISSIONS]: PermissionsFeature;
  [Feature.MPEGTS]: MpegtsFeature;
}

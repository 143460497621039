import { LoggerCore } from "@livelyvideo/log-client";
import Bowser from "bowser";
import { Device } from "mediasoup-client/lib/Device";
import type { RtpCodecCapability } from "mediasoup-client/src/RtpParameters";
import { device, Feature } from "../../../api/adapter";
import type { VideoElement } from "../../../api/typings/video-element";

export interface BrowserInfo {
  name?: string;
  version?: string;
  osname?: string;
}

export function parseBrowserInfo(): BrowserInfo {
  const browser = Bowser.getParser(device.userAgent);

  // @todo: get proper type here
  return {
    name: browser?.getBrowserName().toLowerCase(),
    version: browser?.getBrowserVersion(),
    osname: browser?.getOSName().toLowerCase(),
  };
}

interface SafariVideoElement {
  canPlayType: (codec: string) => "probably" | "maybe";
}

const canCheckPlayType = (el: VideoElement | SafariVideoElement): el is VideoElement & SafariVideoElement => {
  return "canPlayType" in el && typeof el.canPlayType === "function";
};

export const supportsHlsjs = (logger?: LoggerCore, browserInfo = parseBrowserInfo()): boolean => {
  if (!device.isImplements(Feature.HLSJS)) {
    logger?.warn("HlsJsPlayer is NOT supported");
    return false;
  }

  // win chrome 51 and below have a bug in mse causing black screens on bitrate switches
  if (
    browserInfo.osname === "windows" &&
    browserInfo.name === "chrome" &&
    parseInt(browserInfo.version ?? "0", 10) < 52
  ) {
    logger?.warn("HlsJsPlayer is NOT supported", {
      ...browserInfo,
    });
    return false;
  }

  // This is directly copied from the hls library so that the library can be dynamically loaded in
  if (!device.isImplements(Feature.MEDIA_SOURCE) || !device.MediaSource?.isTypeSupported('video/mp4; codecs="avc1.42E01E,mp4a.40.2"')) {
    logger?.warn("HlsJsPlayer is NOT supported", {
      ...browserInfo,
    });
    return false;
  }

  logger?.debug("HlsJsPlayer is supported", {
    ...browserInfo,
  });
  return true;
};

export const supportsMp4 = (logger?: LoggerCore, browserInfo = parseBrowserInfo()): boolean => {
  if (browserInfo.name === "safari" && parseInt(browserInfo.version ?? "0", 10) < 10) {
    logger?.warn("MP4Player is NOT supported", {
      ...browserInfo,
    });
    return false;
  }

  if (browserInfo.name === "ie") {
    logger?.warn("MP4Player is NOT supported", {
      ...browserInfo,
    });
    return false;
  }

  if (browserInfo.name === "chrome" && !(parseInt(browserInfo.version ?? "0", 10) > 49)) {
    logger?.warn("MP4Player is NOT supported", {
      ...browserInfo,
    });
    return false;
  }

  if (
    !(
      device.isImplements(Feature.WEB_SOCKET) &&
      device.isImplements(Feature.MEDIA_SOURCE) &&
      device.isImplements(Feature.DEBUGGING) &&
      device.MediaSource?.isTypeSupported('video/mp4; codecs="avc1.4d4028,mp4a.40.2"')
    )
  ) {
    logger?.warn("MP4Player is NOT supported", {
      ...browserInfo,
    });
    return false;
  }

  logger?.debug("MP4Player is supported", {
    ...browserInfo,
  });
  return true;
};

export const supportsNativeHls = (logger?: LoggerCore, browserInfo = parseBrowserInfo()): boolean => {
  if (!device.isImplements(Feature.CREATE_VIDEO_ELEMENT)) {
    logger?.warn("NativeHlsPlayer is NOT supported");
    return false;
  }

  const el = device.createVideoElement();

  if (
    !(
      canCheckPlayType(el) &&
      (el.canPlayType("application/vnd.apple.mpegURL") === "probably" ||
        (browserInfo.name !== "firefox" && el.canPlayType("application/vnd.apple.mpegURL") === "maybe"))
    )
  ) {
    logger?.warn("NativeHlsPlayer is NOT supported", {
      ...browserInfo,
    });

    return false;
  }

  logger?.debug("NativeHlsPlayer is supported", {
    ...browserInfo,
  });
  return true;
};

export const supportsMediasoupWebrtc = async (
  player: string,
  logger?: LoggerCore,
  browserInfo = parseBrowserInfo(),
): Promise<boolean> => {
  if (!device.isImplements(Feature.MEDIA_STREAM)) {
    logger?.warn(`${player} is NOT supported`);
    return false;
  }

  const codecs: Array<RtpCodecCapability> = [
    {
      kind: "audio",
      mimeType: "audio/opus",
      clockRate: 48000,
      channels: 2,
    },
    {
      kind: "video",
      mimeType: "video/h264",
      clockRate: 90000,
      parameters: {
        "packetization-mode": 1,
      },
    },
  ];

  try {
    const dev = new Device();
    await dev.load({ routerRtpCapabilities: { codecs } });

    const avSupport = {
      audio: dev.canProduce("audio"),
      video: dev.canProduce("video"),
    };

    if (!dev.canProduce("audio") || !dev.canProduce("video")) {
      logger?.warn(`${player} is NOT supported`, {
        ...{ browserInfo: browserInfo as any, avSupport },
      });
      return false;
    }

    logger?.debug(`${player} is supported`, {
      ...{ browserInfo: browserInfo as any, avSupport },
    });

    return true;
  } catch (err) {
    logger?.warn(`${player} is NOT supported`, {
      ...browserInfo,
    });
    return false;
  }
};

export const isFirefox = (): boolean => {
  const browserName = Bowser.getParser(device.userAgent).getBrowserName().toLowerCase();
  return browserName === "firefox";
};

export const isSafari = (): boolean => {
  const browserName = Bowser.getParser(device.userAgent).getBrowserName().toLowerCase();
  return browserName === "safari";
};

export const isIosDevice = (): boolean => {
  let ios = false;
  if (device.platform != null) {
    ios = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(device.platform);
  }
  return ios;
};

export const isAndroidDevice = (): boolean => {
  let android = false;
  if (device.userAgent != null) {
    android = /Android/.test(device.userAgent);
  }
  return android;
};

export const isMobileDevice = (): boolean => {
  let mobileDevice = false;
  if (device.userAgent != null) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(device.userAgent)) {
      mobileDevice = true;
    }
  }
  return mobileDevice;
};

export const iosVersion = (): number[] | null => {
  if (device.platform != null) {
    if (/iP(hone|od|ad)/.test(device.platform)) {
      if (device.platform != null && device.appVersion != null) {
        const version = device.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
        if (version) {
          return [parseInt(version[1], 10), parseInt(version[2], 10)];
        }
      }
    }
  }
  return null;
};

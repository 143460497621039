import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { PlayerUiState } from "../../../../store";
import { LivelyPlayerUiContext } from "../../../context";
import PlayerOverlayButton, { PlayerOverlayButtonProps } from "../../../ui-lib/PlayerOverlayButton";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../ErrorBoundary";

const ModularOverlayButton = observer(({ children, onClick, ...props }: Partial<PlayerOverlayButtonProps>) => {
  const componentName = "<PlayerOverlayButton/>";

  const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);

  const hasUndefinedStore: () => boolean = () =>
    ctx?.player === undefined || ctx?.player?.localVideoPaused === undefined;

  useUndefinedStoreError(hasUndefinedStore, componentName);

  const setPlaying = (): void => {
    if (ctx?.player != null) {
      ctx.player.localVideoPaused = false;
    }
  };
  const handleClick = useUIEventError(onClick ?? setPlaying, componentName);

  if (!ctx?.player?.localVideoPaused) {
    return null;
  }

  const handleMouseOver = (): void => {
    if (ctx !== undefined && !ctx.mobileDevice) {
      ctx.videoMouseOver = true;
    }
  };

  const handleMouseOut = (): void => {
    if (ctx !== undefined && !ctx.mobileDevice) {
      ctx.videoMouseOver = false;
    }
  };

  return (
    <>
    <PlayerOverlayButton
      onMouseEnter={() => handleMouseOver()}
      onMouseLeave={() => handleMouseOut()}
      {...props}
      onClick={handleClick}
    >
      {children}
    </PlayerOverlayButton>
    </>
  );
});

const OverlayButtonWithErrorBoundary: FC<Partial<PlayerOverlayButtonProps>> = ({ ...props }) => {
  return (
    <ErrorBoundary render={() => <PlayerOverlayButton {...props} disabled />}>
      <ModularOverlayButton {...props} />
    </ErrorBoundary>
  );
};

export default OverlayButtonWithErrorBoundary;

import { CSSProperties } from "react";

type videoWrapperStyles = {
  root: CSSProperties;
  videoElement: CSSProperties;
};

export const videoWrapperStyles = (
  pillarBox: boolean,
  fullScreen: boolean | undefined,
  mirror: boolean | undefined,
): videoWrapperStyles => {
  let root = {};
  let videoElement = {};
  // Styling for fullscreen //
  if (fullScreen) {
    root = {
      height: "inherit",
      width: "100%",
      overflow: "hidden",
      lineHeight: 0,
    };
    // Styling for fullscreen with pillarBox //
    if (pillarBox) {
      videoElement = {
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        position: "absolute",
        transform: mirror ? "rotateY(180deg)" : "rotateY(0deg)",
        "&:before": {
          display: "block",
          content: '""',
          paddingTop: "0",
          paddingRight: "0",
          paddingLeft: "0",
        },
        "&::-webkit-media-controls": {
          display: "none",
          "-webkit-appearance": "none",
        },
        "&::-webkit-media-controls-play-button": {
          display: "none",
          "-webkit-appearance": "none",
        },
        "&::-webkit-media-controls-start-playback-button": {
          display: "none",
          "-webkit-appearance": "none",
        },
        "&::-webkit-media-controls-container": {
          display: "none",
          "-webkit-appearance": "none",
        },
      };
    } else {
      // Styling for fullscreen not-pillarBox //
      videoElement = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        backgroundColor: "black",
        top: 0,
        left: "50%",
        position: "relative",
        transform: mirror ? "rotateY(180deg) translate(50%, 0)" : "rotateY(0deg) translate(-50%, 0)",
        transition: "all 0.3s ease-in-out",
      };
    }
  } else {
    // Styling for not-fullscreen //
    root = {
      width: "100%",
      height: "100%",
      lineHeight: 0,
      backgroundColor: "black",
      "&.preview": {
        position: "relative",
        zIndex: 130,
        "@media only screen and (min-width: 600px)": {
          position: "absolute",
          "&.right": {
            top: "1rem",
            right: "1rem",
            width: "25%",
          },
          "&.left": {
            top: "1rem",
            left: "1rem",
            width: "25%",
          },
        },
      },
    };
    // Styling for not-fullscreen with pillarBox //
    if (pillarBox) {
      videoElement = {
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        position: "absolute",
        transform: mirror ? "rotateY(180deg)" : "rotateY(0deg)",
        "&:before": {
          display: "block",
          content: '""',
          paddingTop: "0",
          paddingRight: "0",
          paddingLeft: "0",
        },
        "&::-webkit-media-controls": {
          display: "none",
          "-webkit-appearance": "none",
        },
        "&::-webkit-media-controls-play-button": {
          display: "none",
          "-webkit-appearance": "none",
        },
        "&::-webkit-media-controls-start-playback-button": {
          display: "none",
          "-webkit-appearance": "none",
        },
        "&::-webkit-media-controls-container": {
          display: "none",
          "-webkit-appearance": "none",
        },
      };
    } else {
      // Styling for not-fullscreen not-pillarBox
      videoElement = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        backgroundColor: "black",
        top: 0,
        left: "50%",
        position: "relative",
        transform: mirror ? "rotateY(180deg) translate(50%, 0)" : "translate(-50%, 0)",
      };
    }
  }
  const style = {
    root,
    videoElement,
  };
  return style;
};

const styles = videoWrapperStyles(false, false, false);

export default styles;

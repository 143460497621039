import { EventEmitter, IEventEmitter, Listener } from "@livelyvideo/events-typed";
import { autorun, IObservable, IReactionDisposer, isComputedProp, isObservableProp, reaction } from "mobx";
import type { Disposable, DisposeFunction, Merge } from "../../../api/common";
import type { IVideoClientError } from "../../../api/error";
import { makeBounded } from "../bind";
import { cancel } from "../context/context";
import { hasVcContext } from "../context/vc-context";
import { disposeObjects } from "../dispose";
import {device, Feature} from "../../../api/adapter";
import {wrapNativeError } from "../../errors"

function isReactive<T>(obj: T, prop: any): prop is keyof T {
  return isObservableProp(obj, prop) || isComputedProp(obj, prop);
}

function getter<T>(obj: T, prop: keyof T): () => IObservable {
  return () => obj[prop] as any;
}

export class ObservableEventEmitter<E = any> implements Merge<IEventEmitter<E>, Disposable> {
  #emitter = new EventEmitter();

  #observers = new Map<string, IReactionDisposer>();

  constructor() {
    // makeBounded(this, [], process.env.NODE_ENV === "development");
    makeBounded(this, [], false);
  }

  private startEmitting(prop: keyof this): void {
    if (this.#observers.has(prop as string)) {
      return;
    }

    const handler = this.#emitter.emit.bind(this.#emitter, prop);
    const disposer = reaction(getter(this, prop), handler, { name: `event:${this.constructor.name}.${prop}` });
    this.#observers.set(prop as string, disposer);
  }

  private stopEmitting(prop: string): void {
    this.#observers.get(prop)?.();
    this.#observers.delete(prop);
  }

  addListener<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this.#emitter.addListener(type, listener);
    if (isReactive(this, type)) {
      this.startEmitting(type);
    }
    return this;
  }

  emit<K extends keyof E | "disposed">(type: K, ev?: (E & { disposed: void })[K], ...args: any[]): boolean {
    return this.#emitter.emit(type, ev, ...args);
  }

  off<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this.#emitter.off(type, listener);
    if (EventEmitter.listenerCount(this.#emitter, type as string) === 0) {
      this.stopEmitting(type as string);
    }
    return this;
  }

  on<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this.#emitter.on(type, listener);
    if (isReactive(this, type)) {
      this.startEmitting(type);
    }
    return this;
  }

  once<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this.#emitter.once(type, listener);
    if (isReactive(this, type)) {
      this.startEmitting(type);
    }
    return this;
  }

  prependListener<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this.#emitter.prependListener(type, listener);
    if (isReactive(this, type)) {
      this.startEmitting(type);
    }
    return this;
  }

  prependOnceListener<K extends keyof E | "disposed">(type: K, listener: Listener<(E & { disposed: void })[K]>): this {
    this.#emitter.prependOnceListener(type, listener);
    if (isReactive(this, type)) {
      this.startEmitting(type);
    }
    return this;
  }

  removeAllListeners<K extends keyof E | "disposed">(type?: K): this {
    this.#emitter.removeAllListeners(type);
    for (const key of this.#observers.keys()) {
      this.stopEmitting(key);
    }
    return this;
  }

  removeListener<K extends keyof E | "disposed">(type: K, listener: (ev: (E & { disposed: void })[K]) => void): this {
    this.#emitter.removeListener(type, listener);
    if (EventEmitter.listenerCount(this.#emitter, type as string) === 0) {
      this.stopEmitting(type as string);
    }
    return this;
  }

  private readonly disposers: Array<DisposeFunction> = [];

  private disposed = false;

  protected disposing = false;

  public addInnerDisposer(...disposableObjects: Array<DisposeFunction | Disposable>): void {
    for (const obj of disposableObjects) {
      if (typeof obj === "function") {
        this.disposers.unshift(obj);
      } else {
        this.disposers.unshift(obj.dispose.bind(obj));
      }
    }
  }

  protected autorun(view: () => void): void {
    this.addInnerDisposer(autorun(view));
  }

  get isDisposed(): boolean {
    return this.disposed;
  }

  /**
   * Emmit the passed error, disposes the object and throws the error
   */
  protected throwError(err: IVideoClientError): never {
    this.emitError(err);
    throw err;
  }

  protected emitError(err: IVideoClientError): void {
    try {
      this.#emitter.emit("error", err);
      if (err.critical) {
        this.dispose(`due error: ${err.code}`);
      }
    } catch (handlerErr) {
      if (hasVcContext(this)) {
        this.ctx.logger.error("error handler throws another error", { err, handlerErr: wrapNativeError(handlerErr) });
      } else if (device.isImplements(Feature.DEBUGGING)) {
        device.console.error("error handler throws another error", { err, handlerErr });
      }
    }
  }

  dispose(reason = "not provided"): void {
    if (this.disposed || this.disposing) {
      return;
    }
    this.disposing = true;

    disposeObjects(this, this.disposers, reason);
    this.removeAllListeners();

    if (hasVcContext(this)) {
      // at this point the context should be canceled
      // but still call it explicitly to make sure
      // the context clear all refs to another objects
      cancel(this.ctx);
    }

    this.disposed = true;
    this.emit("disposed");
  }
}

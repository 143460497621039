import { LoggerCore } from "@livelyvideo/log-client";
import { mediaController, types } from "@livelyvideo/video-client-core";
import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { createVideoElement } from "../utils";
// @todo: replace all console.log calls with logger
import BaseUiState from "../utils/ui-state";

export interface SetupOptions {
  videoDevice?: string | null;
  audioDevice?: string | null;
  videoElement?: types.VideoElement;
  logger?: LoggerCore;
}
interface VideoWrapper extends HTMLDivElement {
  webkitRequestFullScreen: () => void;
  mozRequestFullScreen: () => void;
}

export type EncoderUiStateEvents = {
  // MobX Events
  /**
   * @arg MediaStreamController | null
   * @description Is emitted when the mediaStreamController has been set.
   * @example encoderUiState.on("mediaStreamController", (ctrl) => { if(ctrl) { // do something with mediaStreamController})
   */
  mediaStreamController: EncoderUiState["mediaStreamController"];
  /**
   * @arg boolean
   * @description Is emitted when the src/srcObject has been set on the videoEl.
   * @example encoderUiState.on("isEncoderReady", (bool) => { if(bool) { // do something with Encoder})
   */
  isEncoderReady: EncoderUiState["isEncoderReady"];
  /**
   * @arg boolean
   * @description Is emitted when the encoder muted state changes.
   * @example encoderUiState.on("muted", (bool) => { if(bool) { // show muted microphone button} })
   */
  muted: EncoderUiState["muted"];
  /**
   * @arg boolean
   * @description Is emitted when the silent audio track exists or not.
   * @example encoderUiState.on("silentAudio", (bool) => { if(bool) { // handle silent audio} })
   */
  silentAudio: EncoderUiState["silentAudio"];
  /**
   * @arg string | null
   * @description Is emitted when the previous videoDeviceId changes (e.g. when a user changes their video devices, this will keep track of the previous state).
   * @example encoderUiState.on("prevVideoDeviceId", (val) => { if(val === "screencapture") { // handle exiting screencapture mode} })
   */
  prevVideoDeviceId: EncoderUiState["prevVideoDeviceId"];
  /**
   * @arg boolean
   * @description Is emitted when the user tests/stops testing their microphone.
   * @example encoderUiState.on("testMic", (bool) => { if(bool) { // show correct "test mic" button state} })
   *
   */
  testMic: EncoderUiState["testMic"];
};

export default class EncoderUiState extends BaseUiState<EncoderUiStateEvents> {
  static readonly displayName = "EncoderUiState";

  constructor(ctrl: types.MediaStreamController, options?: SetupOptions) {
    super();

    makeObservable(this, {
      // observers
      mediaStreamController: observable.ref,
      isEncoderReady: observable,
      muted: observable,
      silentAudio: observable,
      prevVideoDeviceId: observable,
      testMic: observable,

      // computed
      aspectRatioPadding: computed,

      // actions
      toggleMute: action,
      handleScreenCapture: action,
      toggleVideoCallSlider: action,
      backgroundAudio: action,
      createConstantSource: action,
      removeBackgroundAudio: action,
      handleTestMic: action,
    });

    this.mediaStreamController = ctrl;

    this.logger = new LoggerCore("VDC-web")
      .extend(this.mediaStreamController.logger)
      .setMessageAggregate("chain", `MediaStreamController:${EncoderUiState.displayName}`);
    this.setUp(this.mediaStreamController, options);

    this.mediaStreamController.on("source", this.updateSource);
    if (this.mediaStreamController?.source != null) {
      this.updateSource(this.mediaStreamController.source);
    }

    this.mediaStreamController.once("disposed", () => {
      if (this.mediaStreamController != null) {
        this.mediaStreamController.off("source", this.updateSource);
        if (this.videoElement != null) this.videoElement.srcObject = null;
        if (this.silentMediaStream) {
          this.silentMediaStream.getTracks().forEach((track) => {
            track.stop();
          });
          this.silentMediaStream = null;
        }
        this.audioCtx.close();
      }
    });

    if (options?.logger != null) {
      this.logger.extend(options.logger);
    }

    window.addEventListener("online", (event) => {
      const eventJson = JSON.stringify(event);
      this.logger?.warn("user online", { eventJson });
    });

    window.addEventListener("offline", (event) => {
      const eventJson = JSON.stringify(event);
      this.logger?.warn("user offline", { eventJson });
    });
    this.addInnerDisposer(this.mediaStreamController);
  }

  /*
   *  AudioCtx for silent audio track.
   */
  private readonly audioCtx: AudioContext = new AudioContext();

  /**
   * Stores silent audio stream
   */
  private silentMediaStream: MediaStream | null = null;

  /**
   * Stores original audio stream on Encoder
   */
  private videoMediaStream: MediaStream | null = null;

  mediaStreamController: types.MediaStreamController | null = null;

  /**
   * Creating our logger core
   */
  logger: LoggerCore;

  isEncoderReady = false;

  /**
   * Video Container wrapper element.
   *
   */
  videoWrapperElement: VideoWrapper | null = null;

  /**
   * Indicates whether the video is muted or not.
   */
  muted: boolean | null = true;

  /**
   * Indicates whether the silent audio track exists or not.
   */
  silentAudio: boolean | null = null;

  // Keeps track of the chain for the logger.
  chain = "VDC-web";

  /**
   * Keeps track of previous state for videoDeviceId
   */
  prevVideoDeviceId: string | null = null;

  /**
   * Indicates whether we are testing the mic or not.
   */
  testMic = false;

  setUp(ctrl: types.MediaStreamController, options?: SetupOptions): void {
    this.videoElement = options?.videoElement != null ? options.videoElement : createVideoElement();

    // eslint-disable-next-line eqeqeq
    if (!ctrl.inVideoDeviceTransition && options?.videoDevice !== null && options?.videoDevice !== "") {
      if (options?.videoDevice != null) {
        ctrl.videoDeviceId = options.videoDevice;
      } else if (mediaController.videoDevices().length > 0) {
        const [first] = mediaController.videoDevices();
        ctrl.videoDeviceId = first.deviceId;
      }
    }

    // eslint-disable-next-line eqeqeq
    if (!ctrl.inAudioDeviceTransition && options?.audioDevice !== null && options?.audioDevice !== "") {
      if (options?.audioDevice != null) {
        ctrl.audioDeviceId = options.audioDevice;
      } else if (mediaController.audioDevices().length > 0) {
        const [first] = mediaController.audioDevices();
        ctrl.audioDeviceId = first.deviceId;
      }
    }
  }

  updateSource(src: types.MediaStream): void {
    if (this.videoElement != null) {
      this.videoElement.srcObject = src;
      runInAction(() => {
        this.isEncoderReady = true;
      });
    }
  }

  /**
   * Current aspect ratio. E.g. 16/9
   */
  get aspectRatioPadding(): string | null {
    // check that not dividing by zero
    let ratio: number;
    if (this.mediaStreamController?.aspectRatio != null) {
      if (typeof this.mediaStreamController?.aspectRatio === "number") {
        ratio = (1 / this.mediaStreamController?.aspectRatio) * 100;
      } else {
        ratio = (1 / (this.mediaStreamController?.aspectRatio[0] / this.mediaStreamController?.aspectRatio[1])) * 100;
      }
      return `${ratio.toFixed(2)}%`;
    }
    return null;
  }

  /**
   * Toggle mute.
   * @description Toggles the `muted` property on the &lt;video&gt;.
   * @example <caption>Example usage of toggleMute.</caption>
   * toggleMute();
   * // Sets &lt;video&gt; muted property to true|false and updates uiState.muted = true|false;
   */
  toggleMute(): void {
    if (this.videoElement != null) {
      this.videoElement.muted = !this.videoElement.muted;
      this.muted = this.videoElement.muted;
    }
  }

  /**
   * @todo: Add logging for mediaController in case of mediaStreamController being null.
   * Toggle `videoDeviceId`.
   * @description Toggles the encoder to/from screenCapture and sets uiState.videoDeviceId to
   * either the previous videoDeviceId or screencapture.
   * @example <caption>Example usage of handleScreenCapture.</caption>
   * handleScreenCapture();
   */
  handleScreenCapture(): void {
    if (this.mediaStreamController?.videoDeviceId != null) {
      if (this.mediaStreamController?.videoDeviceId !== "screencapture") {
        this.prevVideoDeviceId = this.mediaStreamController.videoDeviceId;
        this.mediaStreamController.videoDeviceId = "screencapture";
      } else {
        this.mediaStreamController.videoDeviceId = this.prevVideoDeviceId;
      }
    }
  }

  /**
   * Toggle `viewVideoCallSlider`.
   * @description Toggles the video call settings slider to/from visible and sets uiState.viewVideoCallSlider
   * to true/false.
   * @example <caption>Example usage of toggleVideoCallSlider.</caption>
   * toggleVideoCallSlider();
   * // Sets video call settings slider to/from visible and updates uiState.viewVideoCallSlider = true|false;
   */
  toggleVideoCallSlider(): void {
    this.viewVideoCallSlider = !this.viewVideoCallSlider;
  }

  /**
   * backgroundAudioHack.
   * @description Connects our audioSourceNode to the audioCTX and sets it's gain to be pretty much silent.
   */
  backgroundAudio(): void {
    if (!this.silentMediaStream) {
      if (this.mediaStreamController?.source?.getVideoTracks()[0]) {
        const source = this.createConstantSource();
        const gainNode = this.audioCtx.createGain();
        // Set the audio of the gain node to almost nothing
        gainNode.gain.value = 0.001;
        source.connect(gainNode);
        // Connect our gainNode to the final destination of all of the audio in the context.
        gainNode.connect(this.audioCtx.destination);
        source.start();

        const audioDestinationMediaStream = this.audioCtx?.createMediaStreamDestination();
        const audioTrack = audioDestinationMediaStream.stream.getAudioTracks()[0];
        const videoTrack = this.mediaStreamController?.source?.getVideoTracks()[0];
        // Check to ensure we have an audio track and the mediaStreamController has set it's videoTracks.\
        // Create our new MediaStream and set our newly created audioTrack and the mediaStreamController video track to it.
        const newStream = new MediaStream();

        newStream.addTrack(videoTrack as MediaStreamTrack);
        newStream.addTrack(audioTrack);
        // Keep track of our new silent audio track locally.
        this.silentMediaStream = newStream;
        // Set our videoElement srcObject to be the new silent audio mediaStream we created.
        if (this.videoElement) {
          this.videoMediaStream = this.videoElement.srcObject as MediaStream;
          this.videoElement.srcObject = newStream;
        }
      }
    } else if (this.videoElement) {
      this.videoElement.srcObject = this.silentMediaStream;
    }
  }

  /**
   * createConstantSource.
   * @description Creates our constant source of audio, returns the audioBufferSourceNode which is playing on repeat.
   */
  createConstantSource(): AudioBufferSourceNode {
    // Create our buffer source node
    const constantSourceNode = this.audioCtx.createBufferSource();
    const constantBuffer = this.audioCtx.createBuffer(1, 1, this.audioCtx.sampleRate);
    const bufferData = constantBuffer.getChannelData(0);
    // Then filling that channel with white noise
    bufferData[0] = 0 * 1200 + 10;
    constantSourceNode.buffer = constantBuffer;
    // Loop the audio so that it will keep replaying
    constantSourceNode.loop = true;

    return constantSourceNode;
  }

  /**
   * backgroundAudioHack.
   * @description Connects our audioSourceNode to the audioCTX and sets it's gain to be pretty much silent.
   */
  removeBackgroundAudio(): void {
    if (this.videoElement?.srcObject && this?.silentMediaStream) {
      this.videoElement.srcObject = this.videoMediaStream;
      this.silentMediaStream.getTracks().forEach((track) => {
        if (track.kind === "audio") {
          track.stop();
        }
      });
      this.silentMediaStream = null;
      this.videoMediaStream = null;
      this.silentAudio = false;
    }
  }

  /**
   * handleTestMic.
   * @description Handles our test mic button with the two audio streams.
   */
  handleTestMic(): void {
    if (this.silentAudio) {
      if (this.silentMediaStream && this.videoMediaStream) {
        // If testMic is true we are setting the srcObject to the videoMediaStream (original stream on the video) and setting the video element to not be muted.
        if (!this.testMic) {
          this.testMic = true;
          if (this.videoElement?.srcObject) {
            this.videoElement.srcObject = this.videoMediaStream;
            this.videoElement.muted = false;
          }
          // If not we are setting the srcObject back to the silent track and muting the videoElement.
        } else if (this.videoElement?.srcObject && this?.videoElement?.srcObject !== this.silentMediaStream) {
          this.testMic = false;
          this.videoElement.srcObject = this.silentMediaStream;
          this.videoElement.muted = true;
        }
      }
    } else if (this.videoElement) {
      this.videoElement.muted = this.testMic;
      this.testMic = !this.testMic;
    }
  }
}

import React, { FC } from "react";
import { IconProps } from "./iconProps";

const Collapse: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      className={`${className != null && className}`}
      data-icon="collapse"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M166.25 79.6875H127.5C123.344 79.6875 120 76.3646 120 72.2344V33.7266C120 31.677 121.688 30 123.75 30H136.25C138.312 30 140 31.677 140 33.7266V59.8125H166.25C168.312 59.8125 170 61.4895 170 63.5391V75.9609C170 78.0105 168.312 79.6875 166.25 79.6875ZM80 72.2344V33.7266C80 31.677 78.3125 30 76.25 30H63.75C61.6875 30 60 31.677 60 33.7266V59.8125H33.75C31.6875 59.8125 30 61.4895 30 63.5391V75.9609C30 78.0105 31.6875 79.6875 33.75 79.6875H72.5C76.6563 79.6875 80 76.3646 80 72.2344ZM80 165.398V126.891C80 122.76 76.6563 119.438 72.5 119.438H33.75C31.6875 119.438 30 121.114 30 123.164V135.586C30 137.636 31.6875 139.312 33.75 139.312H60V165.398C60 167.448 61.6875 169.125 63.75 169.125H76.25C78.3125 169.125 80 167.448 80 165.398ZM140 165.398V139.312H166.25C168.312 139.312 170 137.636 170 135.586V123.164C170 121.114 168.312 119.438 166.25 119.438H127.5C123.344 119.438 120 122.76 120 126.891V165.398C120 167.448 121.688 169.125 123.75 169.125H136.25C138.312 169.125 140 167.448 140 165.398Z"
      />
    </svg>
  );
};

export default Collapse;

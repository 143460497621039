import { Properties } from "csstype";
import { StyleProps as SelectStyleProps } from "../Inputs/selectStyles";
import { RootStyles } from "../typings/css";

type StyleProps = {
  root: Properties;
  controlsContainer: Properties;
  settingsButton: RootStyles;
  cameraButton: RootStyles;
  microphoneButton: RootStyles;
  screenCaptureButton: RootStyles;
  audioDeviceSelect: SelectStyleProps;
  videoDeviceSelect: SelectStyleProps;
  settingsContainer: RootStyles;
  buttonsContainer: Properties;
  videoContainer: Properties;
};

const buttonStyles = {
  root: {
    color: "white",
    height: "32px",
    width: "32px",
    display: "inline",
    marginRight: "0",
    background: "#357793",
    backgroundColor: "#357793",
    border: "none",
    borderRadius: "5px !important", // Override default style
  },
  svg: {
    height: "32px",
    width: "32px",
  },
};

// Needs different styles due to its icon having different sizing
const screenCaptureButtonStyles = {
  ...buttonStyles,
  svg: {
    height: "20px",
    width: "20px",
    verticalAlign: "sub",
  },
};

const styles: StyleProps = {
  root: {
    height: "25%",
    width: "25%",
    position: "absolute",
    top: 0,
    right: 0,
  },
  controlsContainer: {
    backdropFilter: "blur(10px)",
    WebkitBackdropFilter: "blur(10px)",
    // Apply maxWidth correctly
    boxSizing: "border-box",
    justifyContent: "space-between",
    background: "rgba(0, 0, 0, 0.73)",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    position: "absolute",
    top: "110px",
    left: "5px",
    maxWidth: "190px",
    padding: "25px 10px",
    width: "95%",
    zIndex: 0,
    transition: "all .225s",
  },
  settingsButton: buttonStyles,
  cameraButton: buttonStyles,
  microphoneButton: buttonStyles,
  screenCaptureButton: screenCaptureButtonStyles,
  audioDeviceSelect: {
    root: {
      color: "white",
      // Chrome needs this specified,
      // otherwise infers this differently from Safari and blows up spacing
      height: "60px",
    },
    select: {
      fontSize: "12px",
    },
    label: {
      fontFamily: "sans-serif",
    },
  },
  videoDeviceSelect: {
    root: {
      color: "white",
      // Chrome needs this specified,
      // otherwise infers this differently from Safari and blows up spacing
      height: "60px",
    },
    select: {
      fontSize: "12px",
    },
    label: {
      fontFamily: "sans-serif",
    },
  },
  settingsContainer: {
    root: { display: "flex", marginTop: "25%", flexDirection: "column", padding: "0 10px" },
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  videoContainer: {
    width: "100%",
    borderRadius: "20px",
    overflow: "hidden",
    position: "relative",
    zIndex: 2,
    // Position the active video container properly
    maxWidth: "190px",
    left: "5px",
    top: "3px",
    transition: "all .225s",
  },
};

export default styles;
import React, { FC } from "react";
import { IconProps } from "../iconProps";

const Sound: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="sound"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      {/* Generated by IcoMoon.io // Material Icons v3 */}
      <path
        fill="currentColor"
        d="M28.031 6.469q6.094 1.313 10.031 6.234t3.938 11.297-3.938 11.297-10.031 6.234v-4.125q4.406-1.313 7.172-4.969t2.766-8.438-2.766-8.438-7.172-4.969v-4.125zM33 24q0 5.625-4.969 8.063v-16.125q2.063 1.031 3.516 3.375t1.453 4.688zM6 18h7.969l10.031-10.031v32.063l-10.031-10.031h-7.969v-12z"
      />
    </svg>
  );
};

export default Sound;

import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useState } from "react";
import { EncoderUiState } from "../../../../../store";
import { LivelyEncoderUiContext } from "../../../../context";
import { mergeStyles, mergeStylesObjects } from "../../../../livelyStyles";
import Select, { SelectProps } from "../../../../ui-lib/Inputs/Select";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

/**
 * @todo: Possibly refactor Select Components
 *
 */
const ModularAspectRatioSelect = observer(({ classes = {}, ...props }: Partial<SelectProps>) => {
  /**
   * Component Name (for error msg)
   */
  const componentName = "<AspectRatioSelect/>";

  const availableAspectRatios: Array<number> = [3 / 2, 4 / 3, 16 / 9, 8 / 5];

  const mergedClasses = mergeStyles({ source: classes, target: {} }, { stylesNamespace: "select" });
  const mergedStyles = mergeStylesObjects(classes, {});

  /**
   * Access LivelyContext & destructure API state
   */
  const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

  /**
   * Local component state and Ref for <select> value
   * */
  const [selectValue, setSelectValue] = useState<string>("");
  const selectRef: React.Ref<HTMLSelectElement> = React.createRef();

  /**
   * On input change handler to (1) update API and (2) update component
   * <select> value with user selected audio device.
   */
  const selectAspectRatio: () => void = () => {
    if (selectRef?.current?.value != null && ctx?.mediaStreamController?.aspectRatio != null) {
      ctx.mediaStreamController.aspectRatio = parseFloat(selectRef.current.value);
      setSelectValue(selectRef.current.value);
    }
  };

  /**
   * Populate <select> value with pre-selected/default aspectRatio from API state.
   * */
  useEffect(() => {
    let value = "";
    if (ctx?.mediaStreamController?.aspectRatio != null) {
      if (typeof ctx?.mediaStreamController?.aspectRatio === "number") {
        value = ctx?.mediaStreamController?.aspectRatio.toString();
      }
      if (Array.isArray(ctx?.mediaStreamController?.aspectRatio)) {
        value = (ctx?.mediaStreamController?.aspectRatio[0] / ctx?.mediaStreamController?.aspectRatio[1]).toString();
      }
      setSelectValue(value);
    }
  }, [ctx?.mediaStreamController?.aspectRatio]);

  /**
   * Assess conditions for an undefined store.
   *
   * @returns {boolean}
   * */
  const hasUndefinedStore: () => boolean = (): boolean =>
    ctx?.mediaStreamController == null || ctx?.mediaStreamController?.aspectRatio === undefined;

  /**
   * Throw error (and trigger ErrorBoundary) if store is undefined.
   * */
  useUndefinedStoreError(hasUndefinedStore, componentName);

  /**
   * Wrap onChange function in global Error handler (to trigger ErrorBoundary).
   * */
  const handleInputChange = useUIEventError(selectAspectRatio, componentName);

  return (
    <Select
      classes={mergedStyles}
      label={`Aspect Ratio: ${ctx?.mediaStreamController?.aspectRatio}`}
      disabled={ctx?.mediaStreamController == null}
      onChange={handleInputChange}
      ref={selectRef}
      value={selectValue}
    >
      <option disabled value="">
        Select an aspect ratio
      </option>
      {availableAspectRatios?.map((ratio: number) => (
        <option key={ratio.toString()} value={ratio} className={mergedClasses.option}>
          {ratio}
        </option>
      ))}
    </Select>
  );
});

const AspectRatioSelectWithErrorBoundary: FC<Partial<SelectProps>> = ({ classes = {}, ...props }) => {
  const mergedClasses = mergeStyles({ source: classes, target: {} }, { stylesNamespace: "select" });
  const mergedStyles = mergeStylesObjects(classes, {});

  return (
    <ErrorBoundary
      render={() => (
        <Select {...props} value="" classes={mergedStyles} disabled>
          <option value="" className={mergedClasses?.options}>
            {props.fallbackText ?? "Aspect Ratio Unavailable"}
          </option>
        </Select>
      )}
    >
      <ModularAspectRatioSelect classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default AspectRatioSelectWithErrorBoundary;

import { Properties } from "csstype";
import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { PlayerUiState } from "../../../../../../store/player";
import { LivelyPlayerUiContext } from "../../../../../context";
import ButtonIcon from "../../../../../ui-lib/Buttons/Icon";
import ButtonIconProps from "../../../../../ui-lib/Buttons/Icon/propTypes";
import Icon from "../../../../../ui-lib/Icons/Player";
import { RootStyles } from "../../../../../ui-lib/typings/css";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface AudioButtonClasses extends RootStyles {
  icon?: Properties;
}

interface AudioButtonProps extends Partial<ButtonIconProps> {
  classes?: AudioButtonClasses;
}

const ModularAudioButton = observer(
  ({
    active = false,
    icon: ProvidedIcon,
    onClick,
    screenReaderText,
    classes = {
      root: {},
      icon: {},
    },
    label = "Toggle mute",
    ...props
  }: AudioButtonProps) => {
    const componentName = "<AudioButton/>";
    /**
     * Access LivelyPlayerUiContext.
     */
    const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);

    const hasUndefinedStore: () => boolean = () => ctx?.player === undefined;

    useUndefinedStoreError(hasUndefinedStore, componentName);

    const toggleAudio = (): void => {
      if (ctx?.player != null) {
        ctx.player.localAudioMuted = !ctx.player.localAudioMuted;
      }
    };

    const handleClick = onClick ?? useUIEventError(toggleAudio, componentName);

    const buttonActive: boolean = active || !ctx?.player?.localAudioMuted;

    const icon = buttonActive ? (
      <Icon title={label} iconName="sound" classes={classes?.icon} />
    ) : (
      <Icon title={label} iconName="soundoff" classes={classes?.icon} />
    );

    return (
      <ButtonIcon
        classes={classes}
        active={buttonActive}
        inactiveClass={null}
        label={label}
        data-selenium="audio-stream-button"
        // disabled={player && !player?.remoteAudioEnabled}
        icon={ProvidedIcon || icon}
        onClick={handleClick}
        {...props}
      >
        {screenReaderText ?? `Click to ${active ? "Stop" : "Start"} Audio Stream`}
      </ButtonIcon>
    );
  },
);

const AudioButtonWithErrorBoundary: FC<Partial<ButtonIconProps>> = ({
  icon: ProvidedIcon,
  classes,
  label = "Toggle mute",
  screenReaderText,
  style = {},
  ref,
  ...props
}: AudioButtonProps) => {
  const icon = ProvidedIcon || <Icon iconName="sound" classes={classes?.icon} />;

  return (
    <ErrorBoundary
      render={() => (
        <ButtonIcon
          classes={classes}
          data-selenium="audio-stream-button"
          disabled
          icon={icon}
          label={label}
          onClick={undefined}
          active={false}
          {...props}
        >
          {screenReaderText ?? "Click to Start Audio Stream"}
        </ButtonIcon>
      )}
    >
      <ModularAudioButton
        ref={ref}
        icon={ProvidedIcon}
        label={label}
        screenReaderText={screenReaderText}
        classes={classes}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default AudioButtonWithErrorBoundary;

import React, { FC } from "react";
import { IconProps } from "../iconProps";

const CamCall: FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="camcall"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
    >
      {/* Generated by IcoMoon.io // Material Icons v3 */}
      <path
        fill="currentColor"
        d="M34.031 21l7.969-7.969v21.938l-7.969-7.969v7.031q0 0.844-0.609 1.406t-1.453 0.563h-24q-0.844 0-1.406-0.563t-0.563-1.406v-20.063q0-0.844 0.563-1.406t1.406-0.563h24q0.844 0 1.453 0.563t0.609 1.406v7.031z"
      />
    </svg>
  );
};

export default CamCall;

import { LoggerCore } from "@livelyvideo/log-client";
import { adapter, contextId, instanceId, player, types } from "@livelyvideo/video-client-core";
import { MediasoupPlayer } from "@livelyvideo/video-client-core/lib/internal/player/mediasoup";
import { computed, makeObservable, observable } from "mobx";
import currentPackage from "../../package-json";
import BaseUiState, { BaseUiStateEvents } from "../utils/ui-state";

export interface LivelyPlayerUIOptions {
  autoPlay?: boolean;
  muted?: boolean;
  volume?: number;
  logger?: LoggerCore;
  mutedAutoplayFallback?: boolean;
}

export interface PlayerUiStateEvents extends BaseUiStateEvents {
  /**
   * @description Is emitted when the broadcaster has muted/unmuted their audio.
   * @example playerUiState.on("encoderMuted", (bool) => { if(bool) { // show a muted icon } })
   */
  encoderMuted: boolean;
  /**
   * @description Is emitted when the broadcaster has turned off/on their video.
   * @example playerUiState.on("encoderNoVideo", (bool) => { if(bool) { // show a video poster } })
   */
  encoderNoVideo: boolean;
  /**
   * @description Is emitted when the user hovers mouse over/off the video element.
   * @example playerUiState.on("videoMouseOver", (bool) => { if(bool) { // show controls} })
   */
  videoMouseOver: boolean;

  // MobX Events
  availableQualities: PlayerUiState["availableQualities"];
  availablePlayers: PlayerUiState["availablePlayers"];
  currentPlayer: PlayerUiState["currentPlayer"];
  viewGetSound: PlayerUiState["viewGetSound"];
  qualityMouseOver: PlayerUiState["qualityMouseOver"];
}
export default class PlayerUiState extends BaseUiState<PlayerUiStateEvents> {
  static readonly displayName = "PlayerUiState";

  readonly player: types.BasePlayer;

  constructor(basePlayer: types.BasePlayer, options?: LivelyPlayerUIOptions) {
    super();

    makeObservable(this, {
      // observers
      availableQualities: observable.ref,
      availablePlayers: observable.ref,
      viewGetSound: observable,
      qualityMouseOver: observable,

      // computed
      currentPlayer: computed,
      encoderMuted: computed,
      encoderNoVideo: computed,
    });

    this._options = options;

    this.player = basePlayer;

    if (this.player.logger != null) {
      this.logger = new LoggerCore("VDC-web")
        .extend(this.player.logger)
        .setMessageAggregate("chain", `Player:${PlayerUiState.displayName}`);
    } else {
      this.logger = new LoggerCore("VDC-web")
        .setLoggerMeta("client", "VDC")
        .setLoggerMeta("chain", "PlayerUiState")
        .setLoggerMeta("release", currentPackage.version)
        .setMessageAggregate("contextId", contextId() ?? undefined)
        .setMessageAggregate("instanceId", instanceId() ?? undefined);
    }

    if (this.player?.isImplements(player.Feature.PLAYER_SELECTOR)) {
      this.player.on("currentPlayer", () => {
        this.setPlayerOptions();
      });
    } else {
      this.setPlayerOptions();
    }

    if (this.player?.isImplements(player.Feature.CONSUMER)) {
      this.player.on("consumerVideoEnabled", (val) => {
        this.emit("encoderNoVideo", !val);
      });

      this.player.on("consumerAudioEnabled", (val) => {
        this.emit("encoderMuted", !val);
      });
    }

    if (adapter.device.isImplements(adapter.Feature.CREATE_VIDEO_ELEMENT)) {
      const videoEl = adapter.device.createVideoElement();
      this.player.attachTo(videoEl);
      this.videoElement = videoEl;
      this.videoElement.autoplay = false;

      this.autorun(() => {
        if (this.player?.isImplements(player.Feature.BITRATE_SWITCHING)) {
          this.availableQualities = this.player?.availableQualities.slice();
        }
        if (this.player?.isImplements(player.Feature.PLAYER_SELECTOR)) {
          this.availablePlayers = this.player?.availablePlayers;
        }
      });

      this.addInnerDisposer(this.player);

      this.videoElement = videoEl;

      if (options?.logger != null) {
        this.logger.extend(options.logger);
      }
      window.addEventListener("online", (event) => {
        const eventJson = JSON.stringify(event);
        this.logger?.warn("user online", { eventJson });
      });

      window.addEventListener("offline", (event) => {
        const eventJson = JSON.stringify(event);
        this.logger?.warn("user offline", { eventJson });
      });
    } else {
      // logger.warn("not a webdevice");
    }
  }

  readonly _options: LivelyPlayerUIOptions | undefined;

  private setPlayerOptions(): void {
    if (this._options?.muted != null) this.player.localAudioMuted = this._options.muted;
    if (this._options?.autoPlay != null) {
      this.player.autoPlay = this._options.autoPlay;
      this.player.localVideoPaused = !this._options.autoPlay;
    }
    if (this._options?.volume != null) {
      this.player.localAudioVolume = this._options.volume;
    } else {
      this.player.localAudioVolume = 0.5;
    }
    if (this._options?.mutedAutoplayFallback === true && this.player instanceof MediasoupPlayer) {
      this.player.isManifestPlayer = true;
    }
  }

  /**
   * Creating our logger core
   */
  logger: LoggerCore;

  /**
   * Allows the available bitrates to be observed when updated.
   */
  availableQualities: types.Quality[] = [];

  /**
   * Allows the available drivers to be observed when updated.
   */
  availablePlayers: types.PlayerSpecList = [];

  /**
   * Deprecated.
   */
  get currentPlayer(): types.BasePlayer | null | undefined {
    // eslint-disable-next-line no-console
    console.warn("PlayerUiState.currentPlayer is experimental");
    return this.player;
  }

  /**
   * Indicates whether the get sound element(s)  is/are visible.
   */
  viewGetSound = true;

  get encoderMuted(): boolean | null {
    return this.player?.isImplements(player.Feature.CONSUMER) ? !this.player.consumerAudioEnabled : null;
  }

  get encoderNoVideo(): boolean | null {
    return this.player?.isImplements(player.Feature.CONSUMER) ? !this.player.consumerVideoEnabled : null;
  }

  // Keeps track of the chain for the logger.
  chain = "VDC-web";

  /**
   * Watches for mouse over on the qualitySelect modal
   */
  qualityMouseOver = false;

  // @action.bound
  // setNewWindow(window: Window | null): void {
  //   this.uiState.newWindow = window;
  // }

  toggleNewWindow(): void {
    // this.launchNewWindow();
    // this.uiState.newWindow !== null ? this.destroyNewWindow() : this.launchNewWindow();
  }

  //   @action.bound
  //   launchNewWindow(): void {
  //     this.launchNewWindow = true;
  //   }
}

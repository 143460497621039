// import { RootStyles, StylesObject } from "../typings/css";
import { RootStyles, StylesObject } from "../typings/css";

export interface InputStyles extends RootStyles {
  label?: StylesObject;
  labelDisabled?: StylesObject;
  checkbox?: StylesObject;
  fields?: StylesObject;
  radio?: StylesObject;
  range?: StylesObject;
  input?: StylesObject;
}

const styles: InputStyles = {
  root: {
    background: "none",
    border: "none",
    color: "#2b2b2b",
    display: "inline-block",
    height: "2.5rem",
    padding: "0.5rem 1rem",
    verticalAlign: "middle",
    width: "100%",
  },
  label: {
    fontSize: "0.75rem",
    marginBottom: 0,
    ".dark-shelf &": {
      color: "white",
    },
  },
  labelDisabled: {
    cursor: "not-allowed",
  },
  checkbox: {
    height: "auto",
    marginRight: ".625rem",
    padding: 0,
    width: "auto",
    "& $label": {
      display: "flex",
      flex: "1 1 auto",
      alignItems: "center",
    },
  },
  // // Radio input class is applied next to the field wrapper for convenience.
  radio: {
    padding: 0,
    display: "inline-block",
    border: "1px solid #fff",
    appearance: "none",
    width: "0.5rem",
    height: "0.5rem",
    marginRight: ".625rem",
    borderRadius: "1rem",
    "&:checked": {
      border: `1px solid #f5f5f5`,
      background: "#f5f5f5",
    },
    // Labels on Radios and Checkboxes are wrapped like this.
    "& $label": {
      display: "flex",
      flex: "1 1 auto",
      alignItems: "center",
    },
  },
  range: {
    width: "auto",
    padding: "none",
    height: "auto",
  },
  // This is a generic wrapper for all data fields and input types.
  fields: {
    display: "block",
    marginBottom: "0.625rem",

    // These are the popper menu styles.
    ".lv-tippy-popper &": {
      padding: "0.5rem",
      marginBottom: 0,

      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.25)",
      },
    },
  },
  input: {
    "&:disabled": {
      color: "#6b6b6b",
      opacity: 0.5,
      cursor: "not-allowed",
    },
  },
};

export default styles;

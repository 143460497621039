import React, { forwardRef } from "react";
import { mergeStyles } from "../../livelyStyles";
import { EventFunction } from "../typings/clickevents";
// CSS
import styles, { InputStyles } from "./styles";

const rangeStyles = { ...styles, ...{ root: { height: "auto" } } };

/**
 * @todo: Add appropriate aria-label.
 */
export type RangeStyles = Omit<InputStyles, "radio" | "select">;
export interface RangeProps {
  classes?: RangeStyles;
  min?: number;
  max?: number;
  step?: number;
  label?: string;
  variant?: string;
  classNames?: string;
  tag?: string;
  disabled?: boolean;
  value?: number;
  onInput?: EventFunction; // FormEventHandler<HTMLInputElement>;
  onChange?: EventFunction; // ChangeEventHandler<HTMLInputElement>;
}

const Range = forwardRef<HTMLInputElement, RangeProps>(
  ({ classes = {}, min = 0, max = 1, step = 0.1, label, variant, classNames, value, ...rest }, ref) => {
    const mergedClasses = mergeStyles({ source: classes, target: rangeStyles }, { stylesNamespace: "range" });
    return (
      <div className={mergedClasses.root}>
        <input
          type="range"
          ref={ref}
          min={min}
          max={max}
          step={step}
          value={value}
          className={`${mergedClasses.range} ${classNames}`}
          //   aria-label={label || 'Default Button'}
          data-selenium={rest.tag ?? "range-input"}
          onInput={rest.onInput}
          onChange={rest.onChange}
          {...rest}
        />
      </div>
    );
  },
);

export default Range;



import React, { FC } from "react";
import { IconProps } from "../iconProps";

const LivelyPlayIcon: FC<IconProps> = ({ className, ...rest }) => {

  return (
    <svg    
      data-icon="lively-play"
      focusable="false"
      role="img" 
      className={`${className != null && className}`} 
      viewBox="0 0 650 750" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"     
      {...rest}>
      <path  fill="currentColor" opacity="0.5" d="M647.622 466.157L647.207 466.339L409.045 373.404L363.123 391.324L1.397 532.479V750L579.047 497.005L648.604 466.541L647.622 466.157Z" />
      <path  fill="currentColor" d="M0 0V213.514L0.035 213.528L408.44 372.895L408.09 373.031L409.045 373.404L409.485 373.232L647.622 466.157L650 465.117V353.733V284.683L0 0Z" />
    </svg>
  
  );
};

export default LivelyPlayIcon;
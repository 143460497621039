import React, { FC, ReactNode, useState } from "react";
import { mergeStyles, mergeStylesObjects } from "../../livelyStyles";
import styles, { AlertStyles } from "./styles";
import CloseButton from "../Buttons/Close";

export type AlertBaseProps = {
  children: ReactNode;
  classes?: AlertStyles;
  active: boolean;
  setActive: (active:boolean) => void;
};

const AlertBase: FC<AlertBaseProps> = ({ children, classes = {}, active, setActive, ...props }) => {

  const mergedClasses = mergeStyles({ source: classes, target: styles }, { stylesNamespace: "alertBase" });
  const mergedStyles = mergeStylesObjects(classes, styles);
  
  return (
    <div className={`${mergedClasses.root} ${active && 'open'}` } {...props}>
      <div className={mergedClasses.inner}>
        {children}
      </div>
      <CloseButton onClick={()=> setActive(false)} open={active} classes={{ root: mergedStyles.closeButton }} />
    </div>
  );
};

export default AlertBase;

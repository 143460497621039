import STATS_EVENTS from "./events";
import { Stats, TimingStat } from "./stats";

/**
 * singleton interface for use by package
 * @type {Stats}
 */
const stats = new Stats();

export { TimingStat, stats, STATS_EVENTS };

import { observer } from "mobx-react-lite";
import React, { FC, useContext } from "react";
import { LivelyEncoderUiContext } from "../../../../../context";
import ButtonText from "../../../../../ui-lib/Buttons/Text";
import ButtonTextProps from "../../../../../ui-lib/Buttons/Text/propTypes";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../../ErrorBoundary";

interface CallRequestButtonProps extends Partial<ButtonTextProps> {
  isFirstAttempt: boolean;
}

const ModularCallButton = observer(
  ({
    buttonRequestText,
    isFirstAttempt,
    classes,
    hideOnAcceptedCall = false,
    label = "Request call button",
    onRequestButtonClick,
    ...props
  }: CallRequestButtonProps) => {
    const componentName = "<SendCallRequestButton/>";
    const { store, sendCallRequest, cancelCallRequest } = useContext<any>(LivelyEncoderUiContext);

    const toggleCallRequest: () => void = () => {
      if (store.requestingCall) {
        cancelCallRequest();
      } else {
        sendCallRequest();
      }
    };

    const hasUndefinedStore: () => boolean = () =>
      store === undefined ||
      store.requestingCall === undefined ||
      store.callAttempts === undefined ||
      toggleCallRequest === undefined ||
      !(toggleCallRequest instanceof Function);
    useUndefinedStoreError(hasUndefinedStore, componentName);

    const handleClick = onRequestButtonClick || useUIEventError(toggleCallRequest, componentName);

    if (hideOnAcceptedCall && store?.callAccepted) {
      return null;
    }

    const active = store?.requestingCall;

    let buttonText;
    if (active) {
      buttonText = "Cancel";
    } else if (isFirstAttempt) {
      buttonText = buttonRequestText || "Send video call request";
    } else {
      buttonText = "Try again";
    }

    const disabled = !store || (store && !store.cameraOn && !store.micOn) || store?.disconnectingCall;

    const classNames = "lv-button--send-call-request";

    return (
      <ButtonText
        active={active}
        classNames={classNames}
        data-selenium="lv-button--send-call-request-button"
        disabled={disabled}
        label={label}
        onClick={handleClick}
        {...props}
      >
        {buttonText}
      </ButtonText>
    );
  },
);

const CallButtonWithErrorBoundary: FC<CallRequestButtonProps> = ({
  active,
  classes,
  buttonRequestText = "Send video call request",
  label = "Request call button",
  ...props
}: CallRequestButtonProps) => {
  const classNames = "lv-button--send-call-request";

  return (
    <ErrorBoundary
      render={() => (
        <ButtonText
          classNames={classNames}
          classes={classes}
          data-selenium="lv-button--send-call-request-button"
          disabled
          label={label}
          onClick={undefined}
          {...props}
        >
          {buttonRequestText}
        </ButtonText>
      )}
    >
      <ModularCallButton
        classes={classes}
        buttonRequestText={buttonRequestText}
        label={label}
        active={active}
        {...props}
      />
    </ErrorBoundary>
  );
};

export default CallButtonWithErrorBoundary;

import { RootStyles, StylesObject } from "../typings/css";

export interface StyleProps extends RootStyles {
  label?: StylesObject;
  select?: StylesObject;
  options?: StylesObject;
}

const styles: StyleProps = {
  // Key exists for targeting during style merge
  root: {},
  label: {
    fontFamily: "sans-serif",
    fontSize: "0.75rem",
    marginBottom: 0,
    ".dark-shelf &": {
      color: "white",
    },
  },
  select: {
    appearance: "none",
    backgroundImage:
      "url(data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%20200%20200%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23FCC444%22%20d%3D%22M58.2031%2083.2031H141.797L100%20125L58.2031%2083.2031Z%22%20%2F%3E%3C%2Fsvg%3E)",
    backgroundPosition: "center right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "1.25rem",
    borderBottom: "1px solid #2b2b2b",
    borderRadius: "0",
    color: "#2b2b2b",
    padding: "0.5rem 1rem",
    // Apply this so implementers may use containers and layouts to determine button size, as well as overrides
    width: "100%",

    ".dark-shelf &": {
      color: "#f5f5f5",
      borderBottom: "1px solid #f5f5f5 !important",
    },
  },
};

export default styles;

import { Properties } from "csstype";

type StyleProps = {
  buttonContainer: Properties;
  callRequestContainer: Properties;
  deviceSelectContainer: Properties;
  encoderContainer: Properties;
  headerContainer: Properties;
  settingsText: Properties;
  renderTitleSpan: Properties;
};

const styles: StyleProps = {
  buttonContainer: {},
  callRequestContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: "100%",
  },
  deviceSelectContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "12rem",
  },
  encoderContainer: {}, // Key exists for targeting with Styling API
  headerContainer: {
    alignItems: "center",
    display: "flex",
    height: "2rem",
    width: "100%",
  },
  renderTitleSpan: {
    color: "#000",
    fontFamily: "sans-serif",
    marginLeft: "0.2rem",
  },
  settingsText: {
    fontFamily: "sans-serif",
  },
};

export default styles;

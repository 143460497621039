import { observer } from "mobx-react-lite";
import {player} from "@livelyvideo/video-client-core";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { PlayerUiState } from "../../../../store";
import { LivelyPlayerUiContext } from "../../../context";
import PeerMutedBadge, { PeerMutedBadgeProps } from "../../../ui-lib/Badges/PeerMuted";
import { ErrorBoundary, useUndefinedStoreError } from "../../ErrorBoundary";

const ModularPeerMutedBadge = observer(
  ({ icon, muted, ...props }: Partial<PeerMutedBadgeProps>): React.ReactElement => {
    const [consumerFeature, setConsumerFeature] = useState<boolean>(false)

    /**
     * Component Name (for error msg)
     */
    const componentName = "<PeerMutedBadge/>";

    /**
     * Access LivelyPlayerUiContext.
     */
    const ctx = useContext<PlayerUiState | null>(LivelyPlayerUiContext);

    useEffect(()=> {
      if (ctx?.player?.isImplements(player.Feature.PLAYER_SELECTOR)) {
        ctx?.player?.on("currentPlayer", (ev) => {
          if(ev?.isImplements(player.Feature.CONSUMER)) {
            setConsumerFeature(true);
          } else {
            setConsumerFeature(false);
          }
        });
      } else {
        setConsumerFeature(true);
      }
    },[ctx])

    /**
     * Assess conditions for an undefined store.
     * @returns {boolean}
     * */
    const hasUndefinedStore: () => boolean = () => !!(ctx?.encoderMuted === undefined);

    /**
     * Throw error (and trigger ErrorBoundary) if store is undefined.
     * */
    useUndefinedStoreError(hasUndefinedStore, componentName);

    if (consumerFeature === true) {
      return <PeerMutedBadge muted={muted ?? ctx?.encoderMuted ?? false} icon={icon} {...props} />;
    }

    return <></>
    
  },
);

const PeerMutedBadgeWithErrorBoundary: ({ ...props }: Partial<PeerMutedBadgeProps>) => ReactElement = ({
  ...props
}) => {
  return (
    <ErrorBoundary render={() => <></>}>
      <ModularPeerMutedBadge {...props} />
    </ErrorBoundary>
  );
};

export default PeerMutedBadgeWithErrorBoundary;

import React, { FC } from "react";
import { IconProps } from "./iconProps";

const Pause: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="pause"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M155.769 147.143V52.8571C155.769 45.7589 150.083 40 143.075 40H128.124C121.116 40 115.43 45.7589 115.43 52.8571V147.143C115.43 154.241 121.116 160 128.124 160H143.075C150.083 160 155.769 154.241 155.769 147.143Z"
      />
      <path
        fill="currentColor"
        d="M85.3395 147.143V52.8571C85.3395 45.7589 79.6536 40 72.6453 40H57.6943C50.686 40 45 45.7589 45 52.8571V147.143C45 154.241 50.686 160 57.6943 160H72.6453C79.6536 160 85.3395 154.241 85.3395 147.143Z"
      />
    </svg>
  );
};

export default Pause;

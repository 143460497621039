import React, { FC } from "react";
import { IconProps } from "./iconProps";

const Sound: FC<IconProps> = ({ className, ...rest }) => {
  return (
    <svg
      className={`${className != null && className}`}
      data-icon="volume"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M77.1969 42.2038L49.3937 69.9993H17.5C13.3562 69.9993 10 73.3554 10 77.499V122.497C10 126.637 13.3562 129.997 17.5 129.997H49.3937L77.1969 157.792C81.8937 162.489 90 159.189 90 152.489V47.5066C90 40.8007 81.8875 37.5134 77.1969 42.2038ZM150.109 26.242C146.619 23.9515 141.928 24.9171 139.637 28.4138C137.344 31.9042 138.319 36.5947 141.809 38.8852C162.519 52.4752 174.878 75.321 174.878 100.001C174.878 124.681 162.519 147.527 141.809 161.117C138.319 163.404 137.344 168.098 139.637 171.585C141.837 174.932 146.491 176.135 150.109 173.757C175.084 157.364 190 129.787 190 99.9979C190 70.2087 175.084 42.635 150.109 26.242ZM160 99.9979C160 80.1457 149.981 61.8935 133.197 51.1752C129.7 48.9441 125.062 49.9815 122.847 53.5064C120.631 57.0312 121.666 61.6966 125.162 63.9309C137.584 71.8649 145 85.3455 145 99.9979C145 114.65 137.584 128.131 125.162 136.065C121.666 138.296 120.631 142.962 122.847 146.489C124.881 149.727 129.447 151.221 133.197 148.821C149.981 138.102 160 119.853 160 99.9979ZM115.697 75.9772C112.078 73.9991 107.512 75.3022 105.506 78.9302C103.509 82.5581 104.831 87.1173 108.459 89.1203C112.494 91.3358 115 95.5075 115 99.9979C115 104.491 112.494 108.66 108.462 110.876C104.834 112.879 103.513 117.438 105.509 121.066C107.519 124.709 112.087 126.003 115.7 124.019C124.522 119.16 130.003 109.957 130.003 99.9948C130.003 90.0328 124.522 80.8332 115.697 75.9772Z"
      />
    </svg>
  );
};

export default Sound;

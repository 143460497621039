import { RootStyles, StylesObject } from "../typings/css";

export interface IconStyleProps extends RootStyles {
  "svg:not(:root)"?: StylesObject;
  icon?: StylesObject;
}

const styles: IconStyleProps = {
  root: {    
  display: "flex",
  fill: "currentColor",
  transition: "fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  userSelect: "none",
  alignItems: "center",
  width: "100%",
  justifyContent: "center",  },
  // This is a generic wrapper for all data fields and input types.
  // SVGs and icon resets
  "svg:not(:root)": {
    overflow: "hidden",
  },
  icon: {
    // Inferred width calculations give this height on Chrome, but not Safari
    width: "28px",
  },
};

export default styles;

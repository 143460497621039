import { IEventEmitter } from "@livelyvideo/events-typed";
import { LoggerCore } from "@livelyvideo/log-client";
import { VideoClientError } from "../internal/errors";
import {
  MediaDeviceInfo,
  MediaStream,
  MediaStreamConstraints,
  MediaTrackConstraints,
  MediaTrackSettings,
} from "./adapter";
import { DeepReadonly, Disposable, Json, Merge, Serializable, SourceProvider } from "./common";

export enum ExistsStreamPolicy {
  stale,
  wait,
  // may not work in certain browsers
  ignore,
  error,
}

export type Capturable = {
  element: CapturableElement;
  framerate?: number;
};

export interface CapturableElement {
  captureStream(framerate?: number): MediaStream;

  captureStream(): MediaStream;
}

export type FacingMode = "back" | "front" | "environment" | "user";

export type MediaStreamControllerOptions = {
  defaultConstraints: {
    audio: DeepReadonly<MediaTrackConstraints>;
    video: DeepReadonly<MediaTrackConstraints>;
    screencapture: DeepReadonly<MediaTrackConstraints>;
  };
  fallbackConstraints: {
    audio: DeepReadonly<MediaTrackConstraints>;
    video: DeepReadonly<MediaTrackConstraints>;
    screencapture: DeepReadonly<MediaTrackConstraints>;
  } | null;
  replaceTracks: boolean;
  waitingDelay: number;
  defaultLockPolicy: ExistsStreamPolicy;
  capturable?: Capturable;
  displayName?: string;
};

/**
 * Events map
 */
export type MediaStreamControllerEvents = {
  // mobx events
  /**
   * @arg MediaDeviceInfo | null
   * @description Is emitted when the audio device is changing.
   * @example mediaController.on("audioDeviceChanging", (info) => { if(info) { // do something with MediaDeviceInfo} })
   */
  audioDeviceChanging: Readonly<MediaDeviceInfo> | null;

  /**
   * @arg MediaDeviceInfo | null
   * @description Is emitted when the video device is changing.
   * @example mediaController.on("videoDeviceChanging", (info) => { if(info) { // do something with MediaDeviceInfo} })
   */
  videoDeviceChanging: Readonly<MediaDeviceInfo> | null;

  /**
   * @arg MediaDeviceInfo | null
   * @description Is emitted when the audio device has changed.
   * @example mediaController.on("audioDeviceChanged", (info) => { if(info) { // do something with MediaDeviceInfo} })
   */
  audioDeviceChanged: Readonly<MediaDeviceInfo> | null;

  /**
   * @arg MediaDeviceInfo | null
   * @description Is emitted when the video device has changed.
   * @example mediaController.on("videoDeviceChanged", (info) => { if(info) { // do something with MediaDeviceInfo} })
   */
  videoDeviceChanged: Readonly<MediaDeviceInfo> | null;

  /**
   * @arg boolean
   * @description Is emitted when the audio stream is muted/unmuted.
   * @example
   *
   * mediaController.on("audioMuted", (bool) => {  if(bool) { // do something when audio stream is muted }  })
   *
   */
  audioMuted: MediaStreamControllerAPI["audioMuted"];

  /**
   * @arg number[]
   * @description Lists the available resolutions.
   */
  availableResolutions: MediaStreamControllerAPI["availableResolutions"];

  /**
   * @arg number | null
   */
  maxWidth: MediaStreamControllerAPI["maxWidth"];

  /**
   * @arg number | null
   */
  maxHeight: MediaStreamControllerAPI["maxHeight"];

  /**
   * @arg boolean
   * @description Is emitted when the video stream is paused/unpaused.
   * @example mediaController.on("videoPaused", (bool) => {  if(bool) { // do something when video stream is paused }  })
   */
  videoPaused: MediaStreamControllerAPI["videoPaused"];

  videoDisabled: MediaStreamControllerAPI["videoDisabled"];

  /**
   * @arg 'back' | 'front' | 'environment' | 'user' | null
   * @description Current facing mode, for mobile devices.
   * @example mediaController.on("facingMode", (val) => {  if(val === "back") { // do something when phone is facing back}  })
   */
  facingMode: MediaStreamControllerAPI["facingMode"];

  /**
   * @arg number
   * @description Current volume level.
   * @example mediaController.on("gain", (val) => {  // do something when volume level changes  })
   */
  gain: MediaStreamControllerAPI["gain"];

  /**
   * @arg number | [number, number] | null
   * @description Current video resolution.
   * @example mediaController.on("resolution", (val) => {  // do something when video resolution changes  })
   */
  resolution: MediaStreamControllerAPI["resolution"];

  /**
   * @arg number | [number, number]
   * @description Current framerate.
   * @example mediaController.on("framerate", (val) => {  // do something when video framerate changes  })
   */
  frameRate: MediaStreamControllerAPI["frameRate"];

  /**
   * @description Is emitted when the aspectRatio changes.
   * @example mediaController.on("aspectRatio", (val) => {  if(val){ // do something with aspectRatio }})
   */
  aspectRatio: MediaStreamControllerAPI["aspectRatio"];

  /**c
   * @arg boolean
   * @description Indicates whether echo cancellation is enabled or not.
   * @example mediaController.on("echoCancellation", (val) => {  if(val) {// do something when video echoCancellation is enabled } })
   */
  echoCancellation: MediaStreamControllerAPI["echoCancellation"];

  /**
   * @arg boolean
   * @description Indicates whether noise suppression is enabled or not.
   */
  noiseSuppression: MediaStreamControllerAPI["noiseSuppression"];

  autoGainControl: MediaStreamControllerAPI["autoGainControl"];

  // custom events

  /**
   * @description Is emitted when there is an error during device changing.
   * @example mediaController.on("changeDevicesError", ({audio, video, error}) => {  // do something with info/error  })
   */
  changeDevicesError: {
    audio: {
      old: Readonly<MediaDeviceInfo> | null;
      new: Readonly<MediaDeviceInfo> | null;
    };
    video: {
      old: Readonly<MediaDeviceInfo> | null;
      new: Readonly<MediaDeviceInfo> | null;
    };
    err: Error;
  };

  /**
   * @description Is emitted when there is an error with the audio constraints.
   * @example mediaController.on("audioConstraintsError", ({constraints, error}) => {  // do something with constraints/error  })
   */
  audioConstraintsError: {
    diff: Json;
    constraints: MediaTrackConstraints;
    isFallback: boolean;
    err: VideoClientError | null;
  };
  /**
   * @description Is emitted when there is an error with the video constraints.
   * @example mediaController.on("videoConstraintsError", ({constraints, error}) => {  // do something with constraints/error  })
   */
  videoConstraintsError: {
    diff: Json;
    constraints: MediaTrackConstraints;
    isFallback: boolean;
    err: VideoClientError | null;
  };

  /**
   * @description Is emitted when there is a mediacontroller error.
   * @example mediaController.on("error", (err) => {   // handle error })
   */
  error: VideoClientError;

  source: MediaStream;
};

export interface MediaStreamControllerAPI
  extends Merge<SourceProvider<MediaStream>, IEventEmitter<MediaStreamControllerEvents>, Disposable>,
    Serializable {
  audioMuted: boolean;
  videoPaused: boolean;
  videoDisabled: boolean;
  availableResolutions: number[];
  maxWidth: number | null;
  maxHeight: number | null;
  facingMode: FacingMode | null;
  resolution: number | { min: number; ideal: number; max: number } | [number, number] | null;
  frameRate: number | [number, number] | null;
  aspectRatio: number | [number, number] | null;
  echoCancellation: boolean | null;
  noiseSuppression: boolean | null;
  autoGainControl: boolean | null;
  gain: number;

  audioDeviceId: string | null;
  videoDeviceId: string | null;

  readonly settings: { audio?: MediaTrackSettings; video?: MediaTrackSettings; audioCtx?: unknown };

  supportsEchoCancellation(): boolean;
  supportsNoiseSuppression(): boolean;

  readonly inVideoDeviceTransition: boolean;
  readonly inAudioDeviceTransition: boolean;
  readonly isScreenCaptured: boolean;

  readonly logger: LoggerCore;

  setOptions(options: Partial<MediaStreamControllerOptions>): void;

  constraints(withAudio?: boolean, withVideo?: boolean): MediaStreamConstraints;
  activeConstraints(): { audio?: MediaTrackConstraints; video?: MediaTrackConstraints };

  /**
   * Returns whether this exists an active stream on the controller
   */
  hasActiveStream(): boolean;

  /**
   * Returns whether this exists an active video track on the controller
   */
  hasActiveVideoTrack(): boolean;

  /**
   * Returns whether this exists an active audio track on the controller
   */
  hasActiveAudioTrack(): boolean;

  /**
   * Toggle the video paused attribute
   */
  toggleCamera(): void;

  /**
   * Toggle the audio muted attribute
   */
  toggleMic(): void;

  /**
   * Release audio and video devices
   */
  close(reason: string): void;
}

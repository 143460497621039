import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useState } from "react";
import { EncoderUiState } from "../../../../../store/encoder";
import { LivelyEncoderUiContext } from "../../../../context";
import { mergeStyles, mergeStylesObjects } from "../../../../livelyStyles";
import Select, { SelectProps } from "../../../../ui-lib/Inputs/Select";
import { ErrorBoundary, useUIEventError, useUndefinedStoreError } from "../../../ErrorBoundary";

/**
 * @todo: Possibly refactor Select Components
 *
 */
const ModularQualitySelect = observer(({ classes = {}, maxResolutionHeight, ...props }: Partial<SelectProps>) => {
  /**
   * Component Name (for error msg)
   */
  const componentName = "<ResolutionSelect/>";

  /**
   * Access LivelyEncoderUiContext & destructure API state
   */
  const ctx = useContext<EncoderUiState | null>(LivelyEncoderUiContext);

  /**
   * Local component state and Ref for <select> value
   * */
  const [selectValue, setSelectValue] = useState<string>("");
  const selectRef: React.Ref<HTMLSelectElement> = React.createRef();
  const [availableResolutions, setAvailableResolutions] = useState<number[]>([]);

  useEffect(() => {
    if (
      ctx?.mediaStreamController?.availableResolutions &&
      ctx?.mediaStreamController?.availableResolutions.length > 0
    ) {
      let resolutions = ctx?.mediaStreamController?.availableResolutions;
      if (maxResolutionHeight) {
        resolutions = resolutions.filter((resolution) => resolution <= maxResolutionHeight);
      }
      setAvailableResolutions(resolutions);
    }
  }, [ctx?.mediaStreamController?.availableResolutions, maxResolutionHeight]);
  /**
   * On input change handler to (1) update API and (2) update component
   * <select> value with user selected resolution.
   */
  const selectResolution: () => void = () => {
    if (
      ctx?.mediaStreamController &&
      ctx?.mediaStreamController.availableResolutions.indexOf(Number(selectRef?.current?.value)) !== -1
    ) {
      if (selectRef.current?.value != null) {
        ctx.mediaStreamController.resolution = Number(selectRef.current.value);
        if (selectRef.current.value != null) {
          setSelectValue(selectRef.current.value);
        } else {
          setSelectValue("");
        }
      }
    } else if (ctx?.mediaStreamController) {
      ctx.mediaStreamController.resolution = ctx?.mediaStreamController?.availableResolutions[0];
      setSelectValue(String(ctx?.mediaStreamController?.availableResolutions[0]));
    }
  };

  const mergedClasses = mergeStyles({ source: classes, target: {} }, { stylesNamespace: "select" });
  const mergedStyles = mergeStylesObjects(classes, {});

  /**
   * Populate <select> value with pre-selected/default resolution from API state.
   * */
  useEffect(() => {
    if (ctx?.mediaStreamController?.resolution != null) {
      let value = "";
      if (typeof ctx.mediaStreamController.resolution === "number") {
        value = ctx.mediaStreamController.resolution.toString();
      }
      if (Array.isArray(ctx.mediaStreamController.resolution)) {
        value = (ctx.mediaStreamController.resolution[0] / ctx.mediaStreamController.resolution[1]).toString();
      }
      setSelectValue(value);
    }
  }, [ctx?.mediaStreamController?.resolution]);

  /**
   * Assess conditions for an undefined store.
   * @returns {boolean}
   * */
  const hasUndefinedStore: () => boolean = (): boolean =>
    !ctx?.mediaStreamController || ctx?.mediaStreamController?.resolution === undefined;

  /**
   * Throw error (and trigger ErrorBoundary) if store is undefined.
   * */
  useUndefinedStoreError(hasUndefinedStore, componentName);

  /**
   * Wrap onChange function in global Error handler (to trigger ErrorBoundary).
   * */
  const handleInputChange = useUIEventError(selectResolution, componentName);

  return (
    <Select
      {...props}
      classes={mergedStyles}
      label="Quality"
      disabled={!ctx?.mediaStreamController}
      onChange={handleInputChange}
      ref={selectRef}
      value={selectValue ?? ""}
    >
      <option disabled value="" className={mergedClasses.option}>
        Select a Resolution
      </option>
      {availableResolutions.map((item: number) => (
        <option key={item} value={item} className={mergedClasses.options}>
          {`${item}p`}
        </option>
      ))}
    </Select>
  );
});

const QualitySelectWithErrorBoundary: FC<Partial<SelectProps>> = ({ classes = {}, maxResolutionHeight, ...props }) => {
  const mergedClasses = mergeStyles({ source: classes, target: {} }, { stylesNamespace: "select" });
  const mergedStyles = mergeStylesObjects(classes, {});

  return (
    <ErrorBoundary
      render={() => (
        <Select {...props} value="" disabled classes={mergedStyles} label="Resolution: Unavailable">
          <option value="" className={mergedClasses?.options}>
            {props.fallbackText ?? " Resolution Unavailable"}
          </option>
        </Select>
      )}
    >
      <ModularQualitySelect maxResolutionHeight={maxResolutionHeight} classes={classes} {...props} />
    </ErrorBoundary>
  );
};

export default QualitySelectWithErrorBoundary;

/* eslint-disable dot-notation,no-continue */
import {
  ClientLog,
  ClientMessageContext,
  ClientPackageContext,
  Disposable,
  isSerializableObject,
  Json,
  Level,
  LEVELS,
  LogData,
  LogFunction,
  PackageMetaKeys,
  PrintFunction,
  RawLogData,
  Serializable,
} from "@livelyvideo/log-node";
import debug from "debug";
import { EventEmitter } from "events";
import { LoggerGlobal } from "./logger-http-writer";
import { browserStyles } from "./utils/colors";

const nop: LogFunction = (msg: unknown) => {
  // do nothing
};

const instanceCounter = new WeakMap<NamedClass, number>();

interface NamedClass {
  readonly displayName: string;
}

function flatData(result: RawLogData, ...args: Array<Json>): void {
  for (const data of args) {
    if (typeof data !== "object" || Array.isArray(data)) {
      // skip invalid data
      console.error("Invalid logger data:", data);
      continue;
    }

    if (isSerializableObject(data)) {
      // unpack objects with toJSON() methods
      flatData(result, data.toJSON());
      continue;
    }

    const { aggregates, ...context } = data;

    if (typeof aggregates === "object") {
      for (const [key, val] of Object.entries(aggregates)) {
        result.aggregates[key] = val;
      }
    }

    for (const [key, val] of Object.entries(context)) {
      result[key] = val == null ? val : JSON.parse(JSON.stringify(val));
    }
  }
}

export class LoggerCore implements Record<Level, LogFunction> {
  debug = nop;

  deprecated = nop;

  error = nop;

  fatal = nop;

  info = nop;

  local = nop;

  network = nop;

  notice = nop;

  timing = nop;

  trace = nop;

  warn = nop;

  name: string;

  packageContext: ClientPackageContext = {
    meta: {},
  };

  messageContext: ClientMessageContext = {};

  private readonly attachedObjects: Set<Disposable & Serializable> = new Set();

  httpWriter: LoggerGlobal;

  emitter: EventEmitter = new EventEmitter();

  private proxyingToEmitter: boolean;

  private readonly debugLogger: debug.IDebugger;

  private printer: Record<string, PrintFunction> = {};

  private readonly lastLogged: Record<string, number> = {};

  constructor(name: string) {
    this.name = name;
    this.packageContext.meta = { package: this.name };

    this.httpWriter = new LoggerGlobal();
    this.httpWriter.registerPackage(this);

    this.proxyingToEmitter = false;

    this.debugLogger = debug(this.name);

    LEVELS.forEach((l: Level) => {
      this[l] = this.log.bind(this, l);
      this.printer[l] = this.printLog.bind(this, l);
    });
  }

  flush(): void {
    this.httpWriter.send();
  }

  attachObject(obj: Disposable & Serializable): this {
    obj.on("disposed", () => {
      this.attachedObjects.delete(obj);
    });
    this.attachedObjects.add(obj);
    return this;
  }

  setMessageAggregate(key: string, value?: string | number | boolean): this {
    if (value === undefined) {
      return this.removeMessageAggregate(key);
    }

    if (this.messageContext.aggregates == null) {
      this.messageContext.aggregates = { [key]: value };
    } else {
      this.messageContext.aggregates[key] = value;
    }
    return this;
  }

  appendChain(klass: NamedClass): this {
    let index = 0;
    if (instanceCounter.has(klass)) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      index = instanceCounter.get(klass) + 1;
    }

    const current = this.messageContext.aggregates?.["chain"] ?? "";
    const chain = current === "" ? `${klass.displayName}#${index}` : `${current}:${klass.displayName}#${index}`;
    this.setMessageAggregate("chain", chain);
    instanceCounter.set(klass, index);
    return this;
  }

  removeMessageAggregate(key: string | null): this {
    if (this.messageContext.aggregates != null) {
      if (this.messageContext.aggregates[key] != null) {
        delete this.messageContext.aggregates[key];
        if (Object.keys(this.messageContext.aggregates).length === 0) {
          delete this.messageContext.aggregates;
        }
      }
    }
    return this;
  }

  clearMessageAggregates(): this {
    this.messageContext = {};
    return this;
  }

  setLoggerAggregate(key: string, value: string | number | boolean): this {
    if (this.packageContext.aggregates == null) {
      this.packageContext.aggregates = { [key]: value };
    } else {
      this.packageContext.aggregates[key] = value;
    }
    return this;
  }

  setLoggerMeta(key: typeof PackageMetaKeys[number], value: string): this {
    if (this.packageContext.meta == null) {
      this.packageContext.meta = { [key]: value, package: this.name };
    } else {
      this.packageContext.meta[key] = value;
    }
    return this;
  }

  getLoggerMeta(key: typeof PackageMetaKeys[number]): string | undefined {
    if (this.packageContext?.meta && this.packageContext.meta[key] != null) {
      return this.packageContext.meta[key];
    }
    return undefined;
  }

  log(level: Level, message: string, data?: LogData): void {
    this.printer?.[level]?.(message, data, this.messageContext.aggregates);

    const [msg, logData] = this.handleLogData(message, data, true);

    const log: ClientLog = {
      message: `${msg}`,
      level: level === "trace" ? "debug" : level,
      time: new Date().toISOString(),
      ...logData,
    };

    this.httpWriter.add(log, this);

    if (this.proxyingToEmitter) {
      this.emitter.emit(level, message, logData);
    }
  }

  throttledLog(level: Level, ms: number, message: string, data: LogData): void {
    if (this.lastLogged[message] == null || Date.now() - (this.lastLogged[message] ?? 0) > ms) {
      this.lastLogged[message] = Date.now();
      this.log(level, message, data);
    }
  }

  private handleLogData(message: string | Error, data?: LogData, logError = false): [string, LogData] {
    let msg = "";
    data ??= {};
    if (message instanceof Error) {
      msg = message.message;
      if (isSerializableObject(message)) {
        data["err"] = message;
      } else {
        data["err"] = {
          message: message.message,
          name: message.name,
          stack: message.stack,
        };
      }
    } else {
      msg = message;
    }

    let result: RawLogData = { aggregates: {} };
    try {
      flatData(
        result,
        { aggregates: this.packageContext.aggregates },
        { aggregates: this.messageContext.aggregates },
        data,
        ...this.attachedObjects,
      );
    } catch (err) {
      const errMsg = `logging data were dropped because it's not serializable; log message: ${message}; error: ${err};`;
      if (logError) {
        this.printLog("error", errMsg);
      }

      result = {
        aggregates: {
          chain: result.aggregates?.chain,
          contextId: result.aggregates?.contextId,
          instanceId: result.aggregates?.instanceId,
        },
        __logging_error__: errMsg,
      };
    }

    return [msg, result];
  }

  private printLog(level: Level, message: string | Error, data?: LogData): void {
    const [msg, logData] = this.handleLogData(message, data);

    switch (level) {
      // case "trace":
      case "warn":
      case "error":
        if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
          console[level](`${logData["aggregates"]?.chain ?? ""} ${msg}`, logData);
        } else {
          if (typeof window !== "undefined" && typeof window.console?.error === "function") {
            window.console[level](`%c ${logData["aggregates"]?.chain ?? ""} ${msg}`, browserStyles[level], [logData]);
          } else {
            this.debugLogger(`%c ${logData["aggregates"]?.chain ?? ""} ${msg}`, browserStyles[level], [logData]);
          }
        }
        break;
      default:
        if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
          console.log(`${logData["aggregates"]?.chain ?? ""} ${msg}`, logData);
        } else {
          this.debugLogger(`%c ${logData["aggregates"]?.chain ?? ""} ${msg}`, browserStyles[level], [logData]);
        }
        break;
    }
  }

  proxyToEmitter(emitter: EventEmitter, events: typeof LEVELS[number]): void {
    for (const event of events) {
      this.emitter.on(event, (message: string, data: LogData) => {
        emitter.emit(event, message, data);
      });
    }
    this.proxyingToEmitter = true;
  }

  extend(logger: LoggerCore): this {
    if (logger?.messageContext?.aggregates != null) {
      for (const [key, val] of Object.entries(logger.messageContext.aggregates)) {
        this.setMessageAggregate(key, val);
      }
    }

    if (logger?.packageContext?.aggregates != null) {
      for (const [key, val] of Object.entries(logger.packageContext.aggregates)) {
        this.setLoggerAggregate(key, val);
      }
    }

    if (logger?.packageContext?.meta != null) {
      for (const [key, val] of Object.entries(logger.packageContext.meta)) {
        this.setLoggerMeta(key as any, val);
      }
    }
    return this;
  }

  destroy(): void {
    this.flush();
    this.httpWriter.removePackage(this);
  }
}

import type { LoggerCore } from "@livelyvideo/log-client";
import { Device } from "mediasoup-client/lib/Device";
import type { RtpCapabilities } from "mediasoup-client/lib/RtpParameters";
import type { RtpCodecCapability } from "mediasoup-client/src/RtpParameters";
import { device, Feature } from "../../../api/adapter";
import { parseBrowserInfo } from "../browser-support";

async function checkCapabilitiesForRoom(capabilities: RtpCapabilities): Promise<{ audio: boolean; video: boolean }> {
  try {
    const dev = new Device();
    await dev.load({ routerRtpCapabilities: capabilities });

    return {
      audio: dev.canProduce("audio"),
      video: dev.canProduce("video"),
    };
  } catch (err) {
    throw new Error(`current browser/device not supported: ${err}`);
  }
}

const VP8_CAPABILITY: RtpCodecCapability = {
  clockRate: 90000,
  kind: "video",
  mimeType: "video/VP8",
};

const OPUS_CAPABILITY: RtpCodecCapability = {
  channels: 2,
  clockRate: 48000,
  kind: "audio",
  mimeType: "audio/opus",
};

const H264_CAPABILITY: RtpCodecCapability = {
  clockRate: 90000,
  kind: "video",
  mimeType: "video/H264",
  parameters: {
    "packetization-mode": 1,
  },
};

export const hasOpusCapability = async (log?: LoggerCore, browserInfo = parseBrowserInfo()): Promise<boolean> => {
  let capabilities;
  try {
    capabilities = await checkCapabilitiesForRoom({
      codecs: [OPUS_CAPABILITY],
    });
  } catch (err) {
    const msg = err instanceof Error ? err.message : "unknown error";
    log?.warn("support: no opus capabilities support", {
      ...browserInfo,
      err: msg,
    });
    return false;
  }
  if (!capabilities.audio) {
    log?.warn("support: no opus capabilities support", {
      ...browserInfo,
    });
    return false;
  }
  log?.debug("support: have opus capabilities support", {
    ...browserInfo,
  });
  return true;
};

export const hasVP8Capability = async (log?: LoggerCore, browserInfo = parseBrowserInfo()): Promise<boolean> => {
  let capabilities;
  try {
    capabilities = await checkCapabilitiesForRoom({
      codecs: [VP8_CAPABILITY],
    });
  } catch (err) {
    const msg = err instanceof Error ? err.message : "unknown error";
    log?.warn("support: no vp8 capabilities support", {
      ...browserInfo,
      err: msg,
    });
    return false;
  }
  if (!capabilities.video) {
    log?.warn("support: no vp8 capabilities support", {
      ...browserInfo,
    });
    return false;
  }
  log?.debug("support: have vp8 capabilities support", {
    ...browserInfo,
  });
  return true;
};

export const hasH264Capability = async (log?: LoggerCore, browserInfo = parseBrowserInfo()): Promise<boolean> => {
  let capabilities;
  try {
    capabilities = await checkCapabilitiesForRoom({
      codecs: [H264_CAPABILITY],
    });
  } catch (err) {
    const msg = err instanceof Error ? err.message : "unknown error";
    log?.warn("support: no h264 capabilities support", {
      ...browserInfo,
      err: msg,
    });
    return false;
  }
  if (!capabilities.video) {
    log?.warn("support: no h264 capabilities support", {
      ...browserInfo,
    });
    return false;
  }
  log?.debug("support: have h264 capabilities support", {
    ...browserInfo,
  });
  return true;
};

export const hasOpus = (): true => true;

export const hasVP8 = (browserInfo = parseBrowserInfo()): boolean => {
  try {
    if ((globalThis as any).document == null) {
      // if we don't have document, we're polyfilling code support anyway
      return true;
    }
  } catch (ex) {
    return true;
  }

  return browserInfo.osname !== "ios" && browserInfo.name !== "safari" && browserInfo.name !== "edge";
};

export const hasH264 = (log?: LoggerCore, browserInfo = parseBrowserInfo()): boolean => {
  try {
    if ((globalThis as any).document == null) {
      // if we don't have document, we're polyfilling code support anyway
      return true;
    }
  } catch (ex) {
    return true;
  }

  if (browserInfo.name === "chrome") {
    if (device.isImplements(Feature.MEDIA_RECORDER)) {
      if (device.MediaRecorder.isTypeSupported("video/webm; codecs=h264")) {
        log?.debug("support: h264 support: chrome");
        return true;
      }
      log?.warn("support: no h264 support", {
        reason: "chrome without h264",
        ...browserInfo,
      });
    } else {
      log?.flush();
      return false;
    }
  }

  return device.isImplements(Feature.MEDIA_DEVICE) && device.isCodecSupported("h264");
};
